import { useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import { WorkspaceName } from "modules/Settings/WorkspaceName";
import humps from "humps";
import { updateApp } from "core/models/app";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { TIME_ZONES } from "core/constants/features";
import axios from "axios";
import { WorkspaceLogo } from "./WorkspaceLogo";
import { ITimezone, SelectTimezone } from "./SelectTimezone";
import { useEditAudience } from "core/hooks/useEditAudience";
import { Button, Divider, Tooltip } from "@chakra-ui/react";
import { AudienceFilters } from "core/modules/audience/Filters/AudienceFilters";
import { useAppObjects } from "core/hooks/useAppObjects";

export const WorkspaceSettings = () => {
  const currentApp = useCurrentApp();
  const { userAppObject } = useAppObjects();
  const appId = currentApp.id;
  const dispatch = useDispatch();
  const [timezones, setTimezones] = useState<ITimezone[]>([]);
  const {
    audienceFilters,
    setAudienceFilters,
    isDirty,
    onUpdateAudience,
    isLoading,
    isSaving,
  } = useEditAudience({
    appId: Number(appId),
    audienceId: Number(currentApp.audience.id),
  });

  useEffect(() => {
    const fetchTimezones = async () => {
      const response = await axios.get(
        `${import.meta.env.VITE_API_HOST}/apps/${appId}/list_timezones`,
        {
          withCredentials: true,
        },
      );

      if (response?.data?.timezones) {
        setTimezones(
          response.data.timezones.map((element: any) =>
            humps.camelizeKeys(element),
          ),
        );
      }
    };

    fetchTimezones();
  }, [appId]);

  const DEFAULT_TIMEZONE = {
    value: currentApp.timeZone,
    label: currentApp.timeZone.replace("_", " "),
    utcOffset: currentApp.utcOffset,
  };

  const updateTimeZone = (timeZone: string) => {
    dispatch(updateApp({ appId: currentApp.id, timeZone }));
  };

  return (
    <>
      <h2 className="text-base font-semibold text-gray-900">
        Workspace settings
      </h2>
      <div className="pb-4">
        <div className="flex flex-col gap-5">
          <WorkspaceName />
          <WorkspaceLogo />
          {currentApp.features.includes(TIME_ZONES) && (
            <div className="flex w-full justify-between">
              <div className="flex flex-col">
                <div className="mb-1 text-sm font-semibold">Time zone</div>
                <div className="text-xs">
                  Time zone will only be applied to the date and time on the
                  event feed
                </div>
              </div>
              <SelectTimezone
                options={timezones}
                defaultOption={DEFAULT_TIMEZONE}
                onChange={updateTimeZone}
              />
            </div>
          )}
          <Divider />
          {audienceFilters && (
            <div className="flex flex-col gap-4">
              <div>
                <p className="mb-2 text-sm font-semibold">
                  Filter out internal and test users
                </p>
                <p className="text-sm">
                  These filters apply only to company profiles and computed
                  traits when the toggle is enabled. Adding filters here does
                  not prevent data being ingested.
                </p>
              </div>

              <div>
                <p className="text-sm font-normal">
                  <b>Example:</b> Add "email does not contain{" "}
                  <b>yourcompany.com</b>" to exclude your team
                </p>
              </div>
              <div className="flex w-full justify-between">
                <AudienceFilters
                  key={JSON.stringify(audienceFilters)}
                  audienceFilters={audienceFilters}
                  setAudienceFilters={setAudienceFilters}
                  view={userAppObject?.slug}
                  showClearFilters={false}
                />
                <Tooltip
                  label={
                    isDirty ? "Update the audience" : "Audience has not changed"
                  }
                  hasArrow
                >
                  <Button
                    colorScheme="purple"
                    variant="ghost"
                    isDisabled={!isDirty}
                    onClick={onUpdateAudience}
                    isLoading={isLoading || isSaving}
                  >
                    Save
                  </Button>
                </Tooltip>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
