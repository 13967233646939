import { useParams } from "react-router-dom";
import React, { useState } from "react";
import { Plan } from "core/types/App";
import { usePaywall } from "core/hooks/usePaywall";
import { baseUrl } from "core/constants/paywall";
import {
  IPaywallModalChildren,
  PaywallModal,
} from "core/components/Paywall/Modal";
import { LockClosedIcon } from "@heroicons/react/20/solid";
import {
  Badge,
  Box,
  Button,
  ComponentDefaultProps,
  Flex,
  MenuButton,
  MenuItem,
  PlacementWithLogical,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Switch,
  Text,
} from "@chakra-ui/react";

interface IPaywallPopover extends ComponentDefaultProps {
  feature: string;
  children: React.ReactNode;
  plan?: Plan;
  redirect?: string;
  HeaderTextComponent?: React.FC;
  SubheaderTextComponent?: React.FC;
  CallToActionComponent?: React.FC;
  overridePaywall?: boolean;
  placement?: string;
}

const PLANS = {
  [Plan.Free]: 0,
  [Plan.Growth]: 1,
  [Plan.Pro]: 2,
};

const planCTA = (plan: Plan) => {
  if (plan === Plan.Growth) {
    return "Start free trial";
  }

  if (plan === Plan.Pro) {
    return "Talk to sales";
  }

  return "";
};

export const PaywallPopover: React.FC<IPaywallPopover> = ({
  plan = Plan.Growth,
  feature,
  children,
  HeaderTextComponent,
  SubheaderTextComponent,
  CallToActionComponent,
  redirect = "home",
  overridePaywall = false,
  placement,
  showLockIcon = true,
}) => {
  const { appId } = useParams();
  const { shouldBePaywalled, plan: currentPlan } = usePaywall();
  const [showPaywallModal, setShowPaywallModal] = useState(false);

  if (overridePaywall) return <>{children}</>;
  if (!shouldBePaywalled && PLANS[currentPlan] >= PLANS[plan])
    return <>{children}</>;

  const redirectUrl = `${baseUrl}/a/${appId}/${redirect}`;

  const clonedChildren = React.Children.toArray(children).map((child) => {
    if (
      React.isValidElement(child) &&
      (child.type === Button || child.type === MenuButton)
    ) {
      return React.cloneElement(child, {
        leftIcon: plan === Plan.Pro && <LockClosedIcon className="h-4" />,
        rightIcon:
          plan === Plan.Growth ? (
            <LockClosedIcon className="h-4" />
          ) : (
            <Badge py="3px" px="5px" color="purple.500" borderRadius="md">
              Pro
            </Badge>
          ),
        onClick: undefined,
        onChange: undefined,
        isChecked: false,
        isDisabled: true,
        to: undefined,
      });
    }

    if (React.isValidElement(child) && child.type === Switch) {
      return React.cloneElement(child, {
        onClick: undefined,
        onChange: undefined,
        isChecked: false,
        isDisabled: true,
      });
    }

    if (React.isValidElement(child) && child.type === MenuItem) {
      return React.cloneElement(child, {
        onClick: undefined,
        onChange: undefined,
        isChecked: false,
        isDisabled: true,
      });
    }
    return child;
  });

  return (
    <>
      <Popover
        trigger="hover"
        placement={(placement || "top") as PlacementWithLogical}
        closeDelay={5000}
      >
        <PopoverTrigger>
          <Box as="span" p={0}>
            {clonedChildren}
          </Box>
        </PopoverTrigger>
        <PopoverContent
          borderRadius="lg"
          bg="white"
          boxShadow="sm"
          border="1px"
          borderColor="gray.200"
          maxW="200px"
        >
          <PopoverArrow bg="white" border="none" />
          <PopoverBody py={3} px={4} maxW="full">
            <Flex w="full" direction="column" gap={4}>
              <Flex direction="column" gap={2} maxW="full">
                <Text fontWeight="semibold" fontSize="lg" maxW="full">
                  Upgrade to{" "}
                  <Text color="purple.500" as="span" textTransform="capitalize">
                    {plan}
                  </Text>
                </Text>
                <Text fontSize="sm" maxW="full">
                  Upgrade to start using {feature}
                </Text>
              </Flex>
              <Flex w="full">
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    setShowPaywallModal(true);
                  }}
                  colorScheme="purple"
                  w="full"
                >
                  {planCTA(plan)}
                </Button>
              </Flex>
            </Flex>
          </PopoverBody>
        </PopoverContent>
      </Popover>
      {showPaywallModal && (
        <PaywallModal
          redirectUrl={redirectUrl}
          HeaderTextComponent={HeaderTextComponent}
          SubheaderTextComponent={SubheaderTextComponent}
          CallToActionComponent={CallToActionComponent}
        >
          {(paywallModal: IPaywallModalChildren) => (
            <paywallModal.Modal
              isOpen
              onClose={() => setShowPaywallModal(false)}
            />
          )}
        </PaywallModal>
      )}
    </>
  );
};
