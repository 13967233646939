import { FilterType } from "core/constants/report-setup";
import { PlusIcon } from "@heroicons/react/20/solid";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { Button } from "@chakra-ui/react";
import { TraitMenu } from "./TraitMenu";
import { DataTypeString } from "core/models/traits";

export interface IFilterMenuProps {
  onAddEventFilter: (filterType: FilterType) => void;
  onAddTraitFilter: (
    filterType: FilterType,
    trait: string,
    dataType: DataTypeString,
  ) => void;
  isEventFilterDisabled?: boolean;
}

export const AddFilterGroupButton: React.FC<IFilterMenuProps> = ({
  onAddEventFilter,
  onAddTraitFilter,
  isEventFilterDisabled,
}) => {
  return (
    <Popover className="relative">
      <PopoverButton>
        <Button
          data-testid="audience-add-filter-button"
          variant="ghost"
          colorScheme="purple"
          leftIcon={<PlusIcon className="h-4" />}
        >
          Add filter
        </Button>
      </PopoverButton>
      <PopoverPanel>
        {({ close }) => (
          <TraitMenu
            onAddTraitFilter={(filterType, trait, dataType) => {
              onAddTraitFilter && onAddTraitFilter(filterType, trait, dataType);
              close();
            }}
            onAddEventFilter={(filterType) => {
              onAddEventFilter && onAddEventFilter(filterType);
              close();
            }}
            isEventFilterDisabled={isEventFilterDisabled}
          />
        )}
      </PopoverPanel>
    </Popover>
  );
};
