import { JoinOperatorValue } from "core/types/Filters.d";

import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";

export const OperatorSelect: React.FC<{
  isDisabled?: boolean;
  availableOperators?: JoinOperatorValue[];
  operator?: JoinOperatorValue;
  onOperatorSelect?: (operator: JoinOperatorValue) => void;
}> = ({
  isDisabled = false,
  availableOperators = [JoinOperatorValue.AND, JoinOperatorValue.OR],
  operator,
  onOperatorSelect,
}) => {
  if (isDisabled)
    return (
      <Box>
        <Text fontWeight="medium" fontSize="xs" color="gray.900">
          {JoinOperatorValue[operator || 0]}
        </Text>
      </Box>
    );

  return (
    <Menu>
      <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
        <Text fontSize="xs">{JoinOperatorValue[operator || 0]}</Text>
      </MenuButton>
      <MenuList minWidth="240px">
        {onOperatorSelect &&
          availableOperators.map((operator, idx) => (
            <MenuItem key={idx} onClick={() => onOperatorSelect(operator)}>
              {JoinOperatorValue[operator]}
            </MenuItem>
          ))}
      </MenuList>
    </Menu>
  );
};
