import humps from "humps";
import { IPagy } from "core/types/Pagy";
import { baseQuery } from "core/initializers/rtk";
import { FilterType } from "core/constants/report-setup";
import { createApi } from "@reduxjs/toolkit/query/react";
import { ComputationType } from "../constants/traits";
import { ITraitWithConfig } from "../types/Trait";

export interface ITraitKeyResponse {
  trait: string;
  valueCount: number;
  dataType: DataType;
  audienceType?: string;
  isComputed?: boolean;
  isPinned?: boolean;
  filterType?: FilterType;
  partOfAudience?: boolean;
  computationType?: ComputationType;
}

export enum TraitLevel {
  USER_LEVEL = 0,
  GROUP_LEVEL = 1,
  COMPANY_LEVEL = 2,
}

export enum DataType {
  UNASSIGNED = 0,
  NUMBER = 1,
  DATE = 2,
  BOOLEAN = 3,
  STRING = 4,
}

export enum DataTypeString {
  UNASSIGNED = "unassigned",
  NUMBER = "number",
  DATE = "date",
  BOOLEAN = "boolean",
  STRING = "string",
}

export const DataTypeToString = {
  [DataType.UNASSIGNED]: "Unassigned",
  [DataType.NUMBER]: "Number",
  [DataType.DATE]: "Date",
  [DataType.BOOLEAN]: "Boolean",
  [DataType.STRING]: "String",
} as { [key in DataType]: string };

export interface IUpdateTraitArgs {
  appId: number;
  level: number;
  trait: string;
}

interface IGetTraitsArgs {
  appId: number;
  filterType: number;
  includeValues?: boolean;
  limit?: number;
  isActive?: boolean;
}

export interface ITraitValue {
  value: string;
  count: number;
}

export interface ITraitsValueResponse {
  values: ITraitValue[];
  pagy: IPagy;
}

interface IGetTraitsValuesArgs {
  appId: number;
  filterType: number;
  page: number;
  query: string;
  trait?: string;
}

export const traitsApi = createApi({
  baseQuery,
  reducerPath: "traitsApi",
  tagTypes: ["Trait", "ComputedTrait"],
  endpoints: (builder) => ({
    getTrait: builder.query<
      ITraitWithConfig,
      { appId: number; trait: string; level: number }
    >({
      query: ({ appId, trait, level }) => ({
        method: "GET",
        url: `traits/show`,
        params: {
          app_id: appId,
          trait,
          level,
        },
      }),
      providesTags: ["Trait"],
      transformResponse: (response: ITraitWithConfig) =>
        humps.camelizeKeys(response) as ITraitWithConfig,
    }),
    updateTrait: builder.mutation<
      void,
      { appId: number; id: number; dataType: DataTypeString }
    >({
      query: ({ appId, id, dataType }) => ({
        method: "PUT",
        url: `traits/${id}`,
        params: {
          app_id: appId,
          data_type: dataType,
        },
      }),
      invalidatesTags: ["Trait"],
    }),
    getTraits: builder.query<ITraitKeyResponse[], IGetTraitsArgs>({
      query: ({ appId, filterType, limit, isActive }) => ({
        method: "GET",
        url: `traits`,
        params: {
          app_id: appId,
          filter_type: filterType,
          limit,
          is_active: isActive,
        },
      }),
      providesTags: ["Trait"],
      transformResponse: (response: ITraitKeyResponse[]) =>
        humps.camelizeKeys(response) as ITraitKeyResponse[],
    }),
    getTraitValues: builder.query<ITraitsValueResponse, IGetTraitsValuesArgs>({
      query: ({ appId, trait, filterType, page, query }) => ({
        method: "GET",
        url: `traits/values`,
        params: humps.decamelizeKeys({
          trait,
          appId,
          filterType,
          page,
          query,
        }),
      }),
      transformResponse: (response: ITraitsValueResponse) =>
        humps.camelizeKeys(response) as ITraitsValueResponse,
    }),
    archiveTrait: builder.mutation<void, IUpdateTraitArgs>({
      query: ({ appId, level, trait }) => ({
        method: "PUT",
        url: `traits/archive`,
        params: {
          app_id: appId,
          level,
          trait,
        },
      }),
      invalidatesTags: ["Trait"],
    }),
  }),
});

export const {
  useGetTraitQuery,
  useGetTraitsQuery,
  useLazyGetTraitsQuery,
  useGetTraitValuesQuery,
  useLazyGetTraitValuesQuery,
  useArchiveTraitMutation,
  useUpdateTraitMutation,
} = traitsApi;
