import { GraphType, Measure } from "core/types/ViewInsight";
import { useGetViewInsightPreviewQuery } from "core/models/viewInsights";
import { useParams } from "react-router-dom";
import { useAppObjects } from "core/hooks/useAppObjects";
import { InsightBuilderContainer } from "./InsightBuilderContainer";

export const AddInsightBuilder: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  viewId: number;
  setShowChooseExistingReportInsights: (show: boolean) => void;
  uuid: string;
}> = ({
  isOpen,
  onClose,
  viewId,
  setShowChooseExistingReportInsights,
  uuid,
}) => {
  const { appId } = useParams();
  const { userAppObject } = useAppObjects();
  const { data: previewInsight, isLoading } = useGetViewInsightPreviewQuery({
    appId: Number(appId),
    viewId: viewId,
    measure: Measure.ActiveUsers,
    graphType: GraphType.Area,
    objectType: userAppObject?.objectType,
    uuid,
  });

  if (isLoading || !previewInsight || !isOpen) return null;

  return (
    <InsightBuilderContainer
      isOpen={isOpen}
      onClose={onClose}
      viewId={viewId}
      viewInsight={previewInsight}
      setShowChooseExistingReportInsights={setShowChooseExistingReportInsights}
      isAddingNewInsight={true}
    />
  );
};
