import { useEffect, useState } from "react";
import GroupNameCell from "modules/Groups/List/GroupNameCell";
import ContactNameCell, {
  IContactProps,
} from "modules/Contacts/ContactNameCell";
import CompanyNameCell from "modules/Companies/List/CompanyNameCell";
import { ITrait } from "core/types/Trait";
import { ITableTraitConfig } from "core/types/TableTraitConfig";
import { IGroup } from "core/types/Group";
import { IContact } from "core/types/Contact";
import { AppObjectType, IAppObject } from "core/models/appObjects";
import { ColumnHeader } from "core/components/Table/People/Column/Header";
import { CellWithTimezone } from "core/components/Table/People/Column/CellWithTimezone";
import { Cell } from "core/components/Table/People/Column/Cell";
import { createColumnHelper } from "@tanstack/react-table";

export function bgColor(
  tableTraitConfig: ITableTraitConfig,
  isSortedBy: boolean,
) {
  if (isSortedBy) {
    return "bg-[#f6f6fd]";
  }
  if (
    tableTraitConfig?.computedTrait &&
    !tableTraitConfig.computedTrait.isLive &&
    !isSortedBy
  ) {
    return "bg-gray-50";
  }

  return "bg-white";
}

export function usePeopleTableColumns(
  appObjectType: IAppObject,
  tableTraitConfigs: ITableTraitConfig[],
  deleteTraitConfig: ({ id }: { id: string }) => void,
  sortBy: string,
  sortOrder: string,
  setSortBy: (sortBy: string) => void,
  setSortOrder: (sortOrder: string) => void,
  isScreenshotMode?: boolean,
) {
  const columnHelper = createColumnHelper<IContact | IGroup>();
  const columns = [
    columnHelper.accessor("june_entity", {
      id: "june_entity",
      cell: (info) => {
        if (appObjectType.objectType === AppObjectType.User) {
          return (
            <div className="p-1 hover:bg-gray-50">
              <ContactNameCell
                props={{ ...info.row.original } as IContactProps}
                showName={false}
                width={info.column.getSize() - 50}
              />
            </div>
          );
        }
        if (appObjectType.objectType === AppObjectType.Company) {
          return (
            <CompanyNameCell
              props={{
                appId: info.row.original.appId,
                id: info.row.original.id,
                name: info.row.original.name,
                traits: info.row.original.traits as ITrait,
              }}
            />
          );
        }
        if (appObjectType.objectType === AppObjectType.Group) {
          return (
            <GroupNameCell
              props={{
                id: info.row.original.id,
                appId: info.row.original.appId,
                name: info.row.original.name,
                traits: info.row.original.traits as ITrait,
              }}
            />
          );
        }
        return "-";
      },
      header: () => (
        <div className="w-full">
          <p className="h-full min-w-full py-1.5 pl-4 selection:bg-transparent">
            {appObjectType.singularName}
          </p>
        </div>
      ),
      minSize: 225,
      maxSize: 400,
      size: 250,
    }),
    ...tableTraitConfigs
      .sort((a, b) => a.order - b.order)
      .map((traitConfig) => {
        return columnHelper.accessor(
          (row) => {
            return (row?.traits as ITrait)?.[traitConfig.trait] as string;
          },
          {
            id: String(traitConfig.trait),
            minSize: 80,
            maxSize: 400,
            size: traitConfig.size,
            header: (info) => {
              if (isScreenshotMode) {
                return (
                  <p className="w-full max-w-full truncate pl-2">
                    {traitConfig.trait}
                  </p>
                );
              }
              return (
                <div className="w-full min-w-full max-w-full">
                  <ColumnHeader
                    key={`${info.column.id}-${traitConfig?.computedTrait?.updatedAt}`}
                    info={info}
                    traitConfig={traitConfig}
                    deleteTraitConfig={deleteTraitConfig}
                    moveColumnLeft={moveColumnLeft}
                    moveColumnRight={moveColumnRight}
                    isFirst={traitConfig.order === 0}
                    isLast={traitConfig.order === tableTraitConfigs.length - 1}
                    sortBy={sortBy}
                    sortOrder={sortOrder}
                    setSortBy={setSortBy}
                    setSortOrder={setSortOrder}
                  />
                </div>
              );
            },
            cell: (info) =>
              isScreenshotMode ? (
                <Cell info={info} traitConfig={traitConfig} />
              ) : (
                <CellWithTimezone info={info} traitConfig={traitConfig} />
              ),
          },
        );
      }),
  ];

  const [columnOrder, setColumnOrder] = useState<string[]>(() =>
    columns.map((c) => c.id!),
  );

  useEffect(() => {
    setColumnOrder(columns.map((c) => c.id!));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableTraitConfigs.length]);

  function moveColumn(columnId: string, direction: "left" | "right") {
    setColumnOrder((columnOrder) => {
      const index = columnOrder.indexOf(columnId);
      const increment = direction === "left" ? -1 : 1;
      const newOrder = [...columnOrder];
      [newOrder[index + increment], newOrder[index]] = [
        newOrder[index],
        newOrder[index + increment],
      ];
      return newOrder;
    });
  }

  function moveColumnLeft(columnId: string) {
    moveColumn(columnId, "left");
  }
  function moveColumnRight(columnId: string) {
    moveColumn(columnId, "right");
  }

  return {
    columns,
    columnOrder,
    setColumnOrder,
  };
}
