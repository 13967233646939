import {
  Button,
  Divider,
  Link,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  UnorderedList,
} from "@chakra-ui/react";

interface AiOptInModalProps {
  isOpen: boolean;
  onClose: () => void;
  onAgree: () => void;
}

export const AiOptInModal: React.FC<AiOptInModalProps> = ({
  isOpen,
  onClose,
  onAgree,
}) => (
  <Modal size="2xl" isCentered isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader fontSize="2xl">June AI terms</ModalHeader>
      <ModalCloseButton />
      <ModalBody fontSize="sm" color="gray.700">
        <Text>
          As a reminder, this service is subject to{" "}
          <Link
            href="https://help.june.so/en/articles/7983148-june-additional-product-terms"
            isExternal
          >
            additional product terms.
          </Link>
        </Text>
        <Text mt={2}>By using June AI:</Text>
        <UnorderedList mt={2}>
          <ListItem>
            Your company's use of June AI is not covered by any business
            associate agreement.
          </ListItem>
          <ListItem>
            OpenAI acts as a sub-processor of any personal data submitted to
            June AI, subject to the Data Processing Agreement you have in place
            with June.
          </ListItem>
          <ListItem>
            OpenAI services are currently hosted in the U.S. only (see our
            sub-processors page for more info).
          </ListItem>
        </UnorderedList>
      </ModalBody>
      <ModalFooter>
        <div className="flex w-full items-center justify-between">
          <div className="flex gap-2">
            <Link
              fontSize="sm"
              href="https://help.june.so/en/articles/6823521-privacy-policy"
              isExternal
            >
              Privacy Policy
            </Link>
            <Divider h="20px" orientation="vertical" />
            <Link
              fontSize="sm"
              href="https://help.june.so/en/articles/6823511-terms-of-service"
              isExternal
            >
              Terms of Service
            </Link>
            <Divider h="20px" orientation="vertical" />
          </div>
          <div>
            <Button colorScheme="purple" onClick={onAgree}>
              I agree
            </Button>
          </div>
        </div>
      </ModalFooter>
    </ModalContent>
  </Modal>
);
