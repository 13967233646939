import { IPercentageTraitConfig, ITraitWithConfig } from "core/types/Trait";
import { Divider } from "@chakra-ui/react";
import { SelectedTrait } from "./SelectedTrait";
import { PercentageCalculationToggle } from "./PercentageCalculationToggle";
import { AddTraitButton } from "./AddTraitButton";

export const Percentage: React.FC<{
  trait: ITraitWithConfig;
  onChange: (config: IPercentageTraitConfig) => void;
}> = ({ trait, onChange }) => {
  function onChangeTrait(
    newTraitValue: string | null,
    type: "numerator" | "denominator",
  ) {
    if (type === "numerator") {
      onChange({
        numeratorTrait: newTraitValue || null,
        denominatorTrait: trait.config.denominatorTrait || null,
      });
    } else {
      onChange({
        numeratorTrait: trait.config.numeratorTrait || null,
        denominatorTrait: newTraitValue || null,
      });
    }
  }

  return (
    <div className="flex flex-col gap-4">
      {trait.config.numeratorTrait ? (
        <SelectedTrait
          trait={trait.config.numeratorTrait}
          level={trait.level}
          onRemove={() => onChangeTrait(null, "numerator")}
        />
      ) : (
        <AddTraitButton
          level={trait.level}
          onSelectTrait={(t) => onChangeTrait(t, "numerator")}
        />
      )}
      <p className="flex flex-row items-center gap-2 text-xs font-medium uppercase text-gray-500">
        Over
        <Divider my={2} />
      </p>
      {trait.config.denominatorTrait ? (
        <SelectedTrait
          trait={trait.config.denominatorTrait}
          level={trait.level}
          onRemove={() => onChangeTrait(null, "denominator")}
        />
      ) : (
        <AddTraitButton
          level={trait.level}
          onSelectTrait={(t) => onChangeTrait(t, "denominator")}
        />
      )}
      <Divider my={2} />
      <PercentageCalculationToggle
        isChecked={Boolean(trait.config.isPercentage)}
        onChange={(isChecked) => {
          onChange({
            ...trait.config,
            isPercentage: isChecked,
          });
        }}
      />
    </div>
  );
};
