import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { AudienceStatus } from "core/models/audienceLogs";
import {
  useCreateAlertMutation,
  useGetAlertQuery,
  useUpdateAlertMutation,
} from "core/models/alerts";
import { useToast } from "core/hooks/useToast";
import { useAudience } from "./useAudience";
import { IAlert, ICreateAlert, IUpdateAlert } from "../types/Alert";
import { IAlertDeliveryChannelEnum, IAlertStateEnum } from "../constants/alert";

export enum AlertButtonStateEnum {
  Save,
  Enable,
  Update,
  Pause,
}

export const useAudienceAlert = ({ audienceId }: { audienceId: number }) => {
  const toast = useToast();
  const { appId } = useParams<{
    appId: string;
  }>() as {
    appId: string;
  };
  const { audience } = useAudience({ audienceId });
  const {
    data: existingAlert,
    isFetching,
    isLoading,
  } = useGetAlertQuery({
    appId: Number(appId),
    audienceId: Number(audienceId),
    objectType: audience?.appObject?.ObjectType,
  });

  const [createAlert, { isLoading: isCreateLoading }] =
    useCreateAlertMutation();
  const [updateAlert, { isLoading: isUpdateLoading }] =
    useUpdateAlertMutation();

  const [state, setState] = useState(AlertButtonStateEnum.Save);
  const [alert, setAlert] = useState<IAlert>({
    id: 0,
    appId: Number(appId),
    groupId: Number(audienceId),
    appObject: audience?.appObject,
    objectType: audience?.appObject?.objectType,
    setup: {
      audienceStatus: AudienceStatus.Both,
      messageBody: "{{contact.email}} just entered {{audience.name}}",
    },
    state: IAlertStateEnum.Disabled,
    created: false,
  } as unknown as IAlert);

  const [hasChanges, setHasChanges] = useState(false);
  const isValidSlackAlert = Boolean(
    alert?.deliveryChannel === IAlertDeliveryChannelEnum.Slack &&
      alert?.slackChannelId,
  );
  const isValidAlert = Boolean(
    typeof alert?.deliveryChannel !== "undefined" &&
      typeof alert?.frequency !== "undefined" &&
      isValidSlackAlert,
  );

  useEffect(() => {
    if (existingAlert?.created) setAlert(existingAlert);
  }, [existingAlert]);

  useEffect(() => {
    setAlert((alert) => ({
      ...alert,
      audienceId: audience?.id,
      appObject: audience?.appObject,
      objectType: audience?.appObject?.objectType,
    }));
  }, [audience]);

  useEffect(() => {
    if (!existingAlert || !alert) {
      setHasChanges(false);
      return;
    }

    const hasAttrChanges =
      JSON.stringify(existingAlert.setup) !== JSON.stringify(alert.setup) ||
      existingAlert.deliveryChannel !== alert.deliveryChannel ||
      existingAlert.slackChannelId !== alert.slackChannelId ||
      existingAlert.frequency !== alert.frequency ||
      existingAlert.setup !== alert.setup ||
      existingAlert.state !== alert.state ||
      existingAlert.appObject?.objectType !== alert.appObject?.objectType;
    setHasChanges(hasAttrChanges);
  }, [existingAlert, alert]);

  useEffect(() => {
    switch (true) {
      case alert?.created === false:
        return setState(AlertButtonStateEnum.Save); // Alert does not exist
      case alert?.state === IAlertStateEnum.Disabled:
        return setState(AlertButtonStateEnum.Enable); // Alert is paused
      case alert?.created === true && hasChanges:
        return setState(AlertButtonStateEnum.Update); // Alert is created and some values have changed
      case alert?.created === true &&
        alert?.state === IAlertStateEnum.Enabled &&
        !hasChanges:
        return setState(AlertButtonStateEnum.Pause); // Alert is created, enabled, and no values have changed
      default:
        return setState(AlertButtonStateEnum.Save);
    }
  }, [alert, hasChanges]);

  const onCreate = () => {
    if (!isValidAlert) {
      return toast({
        title: "Configure alert details",
        description: "You need to configure every step to enable the alert.",
        status: "error",
      });
    }

    createAlert({
      ...alert,
      state: IAlertStateEnum.Enabled,
    } as unknown as ICreateAlert)
      .unwrap()
      .then(() => {
        toast({
          title: "Alert created",
          description: "You will now receive this alert.",
        });
      });
  };

  const onUpdate = () => {
    updateAlert({
      id: Number(alert?.id),
      alert: alert as unknown as IUpdateAlert,
    })
      .unwrap()
      .then(() => {
        toast({
          title: "Saved successfully",
          description: "Your alert was updated successfully.",
        });
      });
  };

  const onPause = () => {
    updateAlert({
      id: Number(alert?.id),
      alert: {
        ...alert,
        state: IAlertStateEnum.Disabled,
      } as unknown as IUpdateAlert,
    })
      .unwrap()
      .then(() => {
        toast({
          title: "Saved successfully",
          description: "Your alert was updated successfully.",
        });
      });
  };

  const onEnable = () => {
    updateAlert({
      id: Number(alert?.id),
      alert: {
        ...alert,
        state: IAlertStateEnum.Enabled,
      } as unknown as IUpdateAlert,
    })
      .unwrap()
      .then(() => {
        toast({
          title: "Saved successfully",
          description: "Your alert was updated successfully.",
        });
      });
  };

  return {
    alert,
    setAlert,
    hasChanges,
    state,
    isValidAlert,
    onCreate,
    onUpdate,
    onPause,
    onEnable,
    isEnabled: alert?.state === IAlertStateEnum.Enabled,
    isLoading: isFetching || isLoading,
    isCreateLoading: isCreateLoading,
    isUpdateLoading: isUpdateLoading,
  };
};
