import { useParams } from "react-router-dom";
import { TraitLevel } from "core/modules/audience/Filters/TraitMenu";
import { useGetTraitsQuery } from "core/models/traits";
import { FilterType } from "core/constants/report-setup";
import { PlusIcon } from "@heroicons/react/24/outline";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { Spinner } from "@chakra-ui/react";
import { TraitList } from "./TraitList";

interface AddTraitButtonProps {
  level: TraitLevel;
  onSelectTrait: (trait: string) => void;
}

export const AddTraitButton: React.FC<AddTraitButtonProps> = ({
  level,
  onSelectTrait,
}) => {
  const { appId } = useParams();

  const filterType =
    level === TraitLevel.GROUP_LEVEL
      ? FilterType.GroupFilter
      : level === TraitLevel.COMPANY_LEVEL
        ? FilterType.CompanyFilter
        : FilterType.UserTrait;

  const { data: traits = [], isLoading } = useGetTraitsQuery({
    appId: Number(appId),
    filterType,
  });

  return (
    <Popover className="relative">
      <PopoverButton>
        <p className="flex items-center gap-2 text-sm font-medium text-purple-500">
          <PlusIcon className="h-4 w-4" />
          Add trait
        </p>
      </PopoverButton>
      <PopoverPanel>
        {isLoading && <Spinner />}
        <TraitList
          traits={traits}
          level={level}
          onSelectTrait={(trait) => onSelectTrait(trait.trait)}
        />
      </PopoverPanel>
    </Popover>
  );
};
