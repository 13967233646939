import { TimerangeType } from "core/constants/report-setup";
import { DAY, MONTH, WEEK } from "core/constants/timerange";
import { IEventProperty } from "core/types/EventProperty";

export type IntervalType = typeof DAY | typeof WEEK | typeof MONTH;

export enum ComparisonType {
  IS = 0,
  IS_NOT = 1,
  STARTS_WITH = 2,
  ENDS_WITH = 3,
  CONTAINS = 4,
  DOES_NOT_CONTAIN = 5,
  IS_UNKNOWN = 6,
  HAS_ANY_VALUE = 7,
  GREATER_THAN = 8,
  LESS_THAN = 9,
  BEFORE = 10,
  AFTER = 11,
  ON = 12,
  LESS_THAN_DAYS_AGO = 13,
  MORE_THAN_DAYS_AGO = 14,
}

export enum JoinOperatorValue {
  AND = 0,
  OR = 1,
}

export interface IFilterBody {
  id?: number;
  trait?: string;
  value: string | string[];
  /**
   * Based on an enum.
   *
   * Valid values: \
   * IS = 0
   * IS_NOT = 1
   * STARTS_WITH = 2
   * ENDS_WITH = 3
   * CONTAINS = 4
   * DOES_NOT_CONTAIN = 5
   * IS_UNKNOWN = 6
   * HAS_ANY_VALUE = 7
   * GREATER_THAN = 8
   * LESS_THAN = 9
   */
  comparison_type?: ComparisonType;

  // As used in client/src/core/modules/reports/setup/Audience/UserTraitFilter.tsx
  comparisonType?: ComparisonType;

  // As used in event name filters
  eventId?: number | string;
  eventName?: string;
  times?: number;
  type?: number;
  timerangeType?: TimerangeType;
  interval?: number;
  intervalType?: IntervalType;
  startDate?: string;
  endDate?: string;
  properties?: IEventProperty[];
  isUserEvent?: boolean;
}

export interface IFilter {
  id?: number | string;
  adding?: boolean;
  /**
   * Filter type.
   *
   * Example: \
   * 'user_trait_filter'
   */
  filterType?:
    | "user_trait_filter"
    | "group_trait_filter"
    | "company_trait_filter"
    | string;
  /**
   * Filter type.
   * Based on an enum.
   *
   * Example: \
   * 0
   */
  type: number;
  /**
   * Filter value.
   *
   * Example: \
   * 'user_trait_filter'
   */
  body: IFilterBody;
  default?: boolean;
  isBreakdown?: boolean;
  isRecommended?: boolean;
  properties?: IEventProperty[];
}

export interface IFilterGroup {
  filters: IFilter[];
  /**
   * Index of the JOIN_OPERATORS array
   *
   * Valid values: \
   * 0 = AND \
   * 1 = OR
   *
   * @link client/src/core/components/JoinOperatorMenu/constants.js
   */
  joinOperator: 0 | 1;
}

export interface IAudienceFilter extends IFilterGroup {}
