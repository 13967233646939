import { ITemplateConfig } from "core/types/TemplateConfig";
import { IEvent } from "core/types/Event";
import GroupBarChartGraph from "core/modules/reports/report-types/GroupFunnel/BarChartGraph";
import LineChartContainer from "core/modules/reports/report-types/Funnel/LineChartContainer";
import BarChartGraph from "core/modules/reports/report-types/Funnel/BarChartGraph";
import { DEFAULT_GROUPED_OPTIONS } from "core/constants/timerange";
import { Template } from "core/constants/templates";
import {
  AUDIENCE_TYPE,
  BREAKDOWN_TYPE,
  CONVERSION_WINDOW_TYPE,
  MULTI_EVENT_TYPE,
  PAGE,
  THEN,
  TRACK,
} from "core/constants/report-setup";
import { SimpleAreaChart } from "core/components/ViewInsight/Graphs/SimpleAreaChart";
import { FunnelOverviewGraph } from "core/components/ViewInsight/Graphs/FunnelOverviewGraph";
import { withInView } from "core/components/InsightCard/withInView";
import defaultTemplateConfig from "./defaultTemplateConfig";

const LazyLineChartContainer = withInView(LineChartContainer);

const funnel: ITemplateConfig = {
  ...defaultTemplateConfig,
  isPopular: true,
  reportType: 3,
  route: "funnel",
  subtitle: "A flexible template to measure any funnel in your product",
  aboutTemplate:
    "Measure drop-offs in your product: sign up, onboarding, purchasing or invite flows. Click the charts to get a user breakdown.",
  title: "Funnel",
  template: Template.Funnel,
  sections: {
    user: [BarChartGraph, LazyLineChartContainer],
    company: [GroupBarChartGraph, LazyLineChartContainer],
  },
  insights: [
    {
      typeId: 0,
      slug: "funnel",
      title: "Funnel",
      section: BarChartGraph,
      slackAlertAvailable: true,
      description: {
        title: "Learn more about how we calculate the above funnel",
        content:
          "Only users that triggered the first event chosen in the setup picked and that fulfil the audience filters will be part of the first step of the funnel. Each step of the funnel depends on the users that performed the events in the prior step. Dropped users are the ones who did not perform the events for a given step.",
      },
      view: {
        level: "user",
        Component: FunnelOverviewGraph,
        transform: (data) => data?.eventBars,
        highlight: (data) => {
          if (!data) return { value: 0, unit: "%" };
          return { value: data?.overallConversionPercentage, unit: "%" };
        },
        hasTimerangePicker: true,
        dateRangeOptions: DEFAULT_GROUPED_OPTIONS,
      },
    },
    {
      typeId: 2,
      slug: "conversion-over-time",
      title: "Conversion over time",
      section: LineChartContainer,
      slackAlertAvailable: true,
      description: {
        title: "Learn more about conversion over time",
        content:
          "Conversion is calculated based on the overall conversion rate of the funnel split by calendar weeks.",
      },
      hasInProgress: true,
      hasYAxis: true,
      view: {
        level: "user",
        Component: SimpleAreaChart,
        transform: (data) => {
          if (!data || !Array.isArray(data) || data?.length === 0) return [];
          return data?.map((d: any) => {
            return {
              x: d.humanizedDate,
              y: parseFloat(d.conversionPercentage.toFixed(2)),
            };
          });
        },
        hasTimerangePicker: true,
        dateRangeOptions: DEFAULT_GROUPED_OPTIONS,
      },
    },
    {
      typeId: 3,
      slug: "company-funnel",
      title: "Funnel",
      section: GroupBarChartGraph,
      slackAlertAvailable: true,
      description: {
        title: "Learn more about how we calculate the above funnel",
        content:
          "Only companies with users that triggered the first event picked in your setup and also fulfil the audience filters are part of the funnel's first step. Each step of the funnel depends on the companies that performed the events in the prior step. Dropped companies are the ones who did not perform the events for a given step. Company funnel is not taking anonymous users into account.",
      },
      view: {
        level: "group",
        Component: FunnelOverviewGraph,
        transform: (data) => data?.eventBars?.slice(0, 5),
        highlight: (data) => {
          if (!data) return { value: 0, unit: "%" };
          return { value: data?.overallConversionPercentage, unit: "%" };
        },
        hasTimerangePicker: true,
        dateRangeOptions: DEFAULT_GROUPED_OPTIONS,
      },
    },
    {
      typeId: 5,
      slug: "company-conversion-over-time",
      title: "Conversion over time",
      section: LineChartContainer,
      slackAlertAvailable: true,
      description: {
        title: "Learn more about conversion over time",
        content:
          "Conversion is calculated based on the overall conversion rate of the funnel split by calendar weeks.",
      },
      hasInProgress: true,
      hasYAxis: true,
      view: {
        level: "group",
        Component: SimpleAreaChart,
        transform: (data) =>
          data?.map((d: any) => {
            return {
              x: d.humanizedDate,
              y: parseFloat(d.conversionPercentage.toFixed(2)),
            };
          }),
        hasTimerangePicker: true,
        dateRangeOptions: DEFAULT_GROUPED_OPTIONS,
      },
    },
  ],
  setup: {
    requiredSections: [0],
    eventOperator: [THEN],
    setupSections: [
      {
        id: "default_funnel_step",
        configKey: "step",
        title: "Steps",
        description:
          "Select the events you want to measure in this step of your funnel",
        type: MULTI_EVENT_TYPE,
        supportedEventTypes: [TRACK, PAGE],
        required: true,
        ordering: true,
        isRenameable: true,
        isDynamic: true,
        isRemovable: true,
        limit: 100,
        validate: (events) =>
          events &&
          events.length > 0 &&
          events.length ===
            events.map((event) => event.id).filter((x) => x).length,
      },
      {
        id: "audience",
        configKey: "audience",
        title: "Audience",
        description: "Select an audience for your report",
        type: AUDIENCE_TYPE,
        supportedUserTypes: ["user"],
      },
      {
        id: "conversion_window",
        configKey: "conversionWindow",
        title: "Conversion Window",
        description:
          "The time window a user has to finish each step after the previous one",
        type: CONVERSION_WINDOW_TYPE,
      },
      {
        id: "breakdown",
        configKey: "breakdown",
        title: "Breakdown",
        description: "Select an audience trait for your report breakdown",
        type: BREAKDOWN_TYPE,
      },
    ],
  },
  hasDateRangeSection: true,
  dateRangeOptions: DEFAULT_GROUPED_OPTIONS,
  validateSetup: (config) =>
    Object.keys(config).some(
      (key) =>
        key.includes("step") &&
        Array.isArray(config[key]) &&
        config[key].length > 0 &&
        config[key].some((e: IEvent) => e.name),
    ),
};

export default funnel;
