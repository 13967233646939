import { cx } from "helpers/cx";
import { IHightlight } from "core/types/TemplateConfig";
import { IViewInsight } from "core/models/viewInsights";
import { useInsightTitle } from "core/hooks/useInsightTitle";
import { COLOR_NAME_TO_PALETTE } from "core/hooks/useEditViewInsight";
import { HighlightCard } from "core/components/ViewInsight";
import { MoonIcon } from "@heroicons/react/24/solid";
import {
  CalendarIcon,
  CodeBracketSquareIcon,
} from "@heroicons/react/24/outline";
import { Center, ComponentDefaultProps, Text, Tooltip } from "@chakra-ui/react";

interface IPublicViewInsightCard extends ComponentDefaultProps {
  viewInsight: IViewInsight;
  title: string;
  description: string;
  highlight: IHightlight;
  timerange?: string;
  isLoading: boolean;
  isEmpty: boolean;
  isAI?: boolean;
}

export const PublicViewInsightCard: React.FC<IPublicViewInsightCard> = ({
  viewInsight,
  title,
  description,
  highlight,
  timerange,
  isLoading,
  isEmpty,
  children,
  isAI = false,
}) => {
  const pluralName = viewInsight?.appObject?.pluralName;
  const insightTitle = useInsightTitle({ title, name: pluralName });
  const insightDescription = useInsightTitle({
    title: description,
    name: pluralName,
  });
  const color = COLOR_NAME_TO_PALETTE[viewInsight?.color as string];

  return (
    <HighlightCard>
      {(hc) => (
        <hc.Container
          id="loaded"
          onMouseEnter={() => {}}
          onMouseLeave={() => {}}
          onClick={() => {}}
          data-testid="view-insight-card"
          hoverProps={{ cursor: "default" }}
        >
          <hc.Header>
            <div className="relative flex w-full justify-between">
              <div className="w-full">
                <div className="flex w-full min-w-[180px]">
                  {
                    <Tooltip
                      label={
                        viewInsight.useViewAudience
                          ? "Uses dashboard's audience filters"
                          : "Uses insight's audience filters"
                      }
                      placement="top"
                      hasArrow
                    >
                      <div
                        className={cx(
                          "relative right-[25px] top-[3px] h-[15px] w-1 rounded-full",
                          viewInsight.useViewAudience
                            ? "bg-purple-500"
                            : "bg-gray-400",
                        )}
                      />
                    </Tooltip>
                  }
                  <hc.Title
                    title={insightTitle}
                    description={isAI ? "" : insightDescription}
                    highlight={highlight}
                    shouldBePaywalled={false}
                    showHighlightDescription={false}
                    isLoading={isLoading}
                    icon={
                      isAI ? (
                        <Tooltip hasArrow label="SQL insight">
                          <CodeBracketSquareIcon className="h-4 text-gray-500" />
                        </Tooltip>
                      ) : null
                    }
                    color={color}
                  >
                    <div className="relative flex w-full items-center justify-between">
                      {timerange && (
                        <div className="flex items-center gap-1 text-gray-500">
                          <CalendarIcon className="h-4" />
                          <Text fontWeight="medium" fontSize="xs">
                            {timerange}
                          </Text>
                        </div>
                      )}
                    </div>
                  </hc.Title>
                </div>
              </div>
            </div>
          </hc.Header>
          <hc.Body isLoading={isLoading} maxW="full" h="full" overflowY="auto">
            {!isEmpty && children}
            {isEmpty && (
              <Center ml={6} w="full" h="full">
                <div className="flex flex-col items-center gap-4">
                  <div className="rounded  bg-purple-50 p-2">
                    <MoonIcon className="h-4 text-purple-500" />
                  </div>
                  <div className="flex flex-col items-center">
                    <p className="text-xs font-medium">Nothing to see here</p>
                  </div>
                </div>
              </Center>
            )}
          </hc.Body>
        </hc.Container>
      )}
    </HighlightCard>
  );
};
