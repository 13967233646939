import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { IEvent } from "core/types/Event";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { useContact } from "core/hooks/useContact";
import { uuid } from "core/helpers/uuid";
import { IntervalTypeNames } from "core/constants/timerange";
import { ANY, EventType } from "core/constants/report-setup";
import { ActivityLineChart } from "core/components/ActivityLineChart";
import { useRadioGroup, UseRadioGroupReturn } from "@chakra-ui/react";
import { JoinOperatorValue } from "core/types/Filters.d";

export interface IFilterEvents {
  events: IEvent[];
  joinOperator: JoinOperatorValue;
}

export const ContactActivity: React.FC = () => {
  const { id: appId } = useCurrentApp();
  const { contactId: encodedContactId } = useParams<{
    contactId: string;
  }>() as {
    contactId: string;
  };
  const id = decodeURIComponent(encodedContactId);

  const anyEvent = {
    uuid: uuid(),
    type: EventType.ANY,
    properties: [],
    id: ANY,
    name: "Any event",
  };
  const [filterEvents, setFilterEvents] = useState<IFilterEvents>({
    events: [anyEvent],
    joinOperator: JoinOperatorValue.OR,
  });

  const { value: intervalType, getRadioProps } = useRadioGroup({
    defaultValue: IntervalTypeNames.WEEK,
  }) as {
    value: IntervalTypeNames;
    getRadioProps: UseRadioGroupReturn["getRadioProps"];
  };

  const {
    fetchContactActivity,
    contactActivity,
    isLoadingContactActivity,
    contactActivityErrors,
  } = useContact({
    appId: `${appId}`,
    id,
    intervalType,
    filterEvents,
  });

  useEffect(() => {
    fetchContactActivity();
  }, [fetchContactActivity]);

  const data =
    contactActivity?.allContactEvents?.map((d) => ({
      x: d.humanizedDate,
      y: d.count,
      date: d.date,
    })) || [];

  const totalEvents = contactActivity?.totalEvents || 0;

  if (contactActivityErrors) {
    return null;
  }

  return (
    <ActivityLineChart
      id={id}
      isLoading={isLoadingContactActivity}
      totalEvents={totalEvents}
      data={data}
      getRadioProps={getRadioProps}
      filterEvents={filterEvents}
      setFilterEvents={setFilterEvents}
      intervalType={intervalType as IntervalTypeNames}
    />
  );
};
