import { useParams } from "react-router-dom";
import RenderedMessagePreview from "modules/Events/List/RenderedMessagePreview";
import { EventAlertEditorTextArea } from "modules/Events/List/EventAlertEditorTextArea";
import { EventAlertEditorHeading } from "modules/Events/List/EventAlertEditorHeading";
import Header from "core/modules/reports/setup/Setup/Header";
import { AudienceStatus } from "core/models/audienceLogs";
import { useSlack } from "core/hooks/useSlack";
import {
  AlertButtonStateEnum,
  useAudienceAlert,
} from "core/hooks/useAudienceAlert";
import { useAudience } from "core/hooks/useAudience";
import Icon from "core/design-system/components/Icon";
import { SLACK_REDIRECT_URL_KEY } from "core/constants/integrations";
import {
  DELIVERY_CHANNELS,
  IAlertDeliveryChannelEnum,
  IAlertFrequencyEnum,
  IDeliveryChannelOption,
  IFrequencyOption,
} from "core/constants/alert";
import { TooltipBody } from "core/components/TooltipBody";
import SlackChannels from "core/components/Slack/SlackChannels";
import Message from "core/components/Slack/Message";
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Input,
  Link,
  Select,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";

interface IAudienceStatusOption {
  value: AudienceStatus;
  label: string;
  description: string;
}

export const FREQUENCIES: IFrequencyOption[] = [
  {
    value: IAlertFrequencyEnum.Instant,
    label: "Instant",
    description: "As soon as the audience changes",
  },
  {
    value: IAlertFrequencyEnum.Daily,
    label: "Daily",
    description: "Once a day, first thing in the morning.",
  },
  {
    value: IAlertFrequencyEnum.Weekly,
    label: "Weekly",
    description: "Every Monday morning",
  },
  {
    value: IAlertFrequencyEnum.Monthly,
    label: "Monthly",
    description: "The first day of every month",
  },
];

export const AUDIENCE_STATUS_OPTIONS: IAudienceStatusOption[] = [
  {
    value: AudienceStatus.Entered,
    label: "Entered",
    description: "When someone enters the audience",
  },
  {
    value: AudienceStatus.Exited,
    label: "Exited",
    description: "When someone exits the audience",
  },
  {
    value: AudienceStatus.Both,
    label: "Both",
    description: "When someone enters or exits the audience",
  },
];

interface IEditorDrawerProps {
  isOpen: boolean;
  onClose: () => void;
}

export const EditorDrawer: React.FC<IEditorDrawerProps> = ({
  isOpen,
  onClose,
}) => {
  const { appId, audienceId } = useParams<{
    appId: string;
    audienceId: string;
  }>() as {
    appId: string;
    audienceId: string;
  };
  const { audience } = useAudience({ audienceId: Number(audienceId) });
  const { hasSlackAppInstalled } = useSlack();

  const {
    alert,
    setAlert,
    state,
    isValidAlert,
    onCreate,
    onEnable,
    onUpdate,
    onPause,
    isCreateLoading,
    isUpdateLoading,
  } = useAudienceAlert({ audienceId: Number(audienceId) });

  const deliveryChannel = DELIVERY_CHANNELS.find(
    (c) => c.value === alert.deliveryChannel,
  );
  const frequency = FREQUENCIES.find((f) => f.value === alert.frequency);
  const messageBody = alert.setup.messageBody;
  const audienceStatus = AUDIENCE_STATUS_OPTIONS.find(
    (f) => f.value === alert.setup["audienceStatus"],
  );

  return (
    <Drawer
      trapFocus={false}
      size="xl"
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
    >
      <DrawerOverlay />
      <DrawerContent>
        <div className="flex h-full flex-col justify-between overflow-y-scroll">
          <div className="flex flex-col justify-start">
            <Header title={<Text>Set up alert for {audience?.name}</Text>} />
            <Box mx={8} bg="white">
              <div className="mb-4 flex w-full justify-end">
                {state === AlertButtonStateEnum.Save && (
                  <Button
                    onClick={onCreate}
                    isLoading={isCreateLoading}
                    isDisabled={!isValidAlert}
                    colorScheme="green"
                  >
                    Save and set live
                  </Button>
                )}
                {state === AlertButtonStateEnum.Enable && (
                  <Button
                    onClick={onEnable}
                    isLoading={isUpdateLoading}
                    isDisabled={!isValidAlert}
                    colorScheme="green"
                  >
                    Set live
                  </Button>
                )}
                {state === AlertButtonStateEnum.Update && (
                  <Button
                    onClick={onUpdate}
                    isLoading={isUpdateLoading}
                    isDisabled={!isValidAlert}
                    colorScheme="green"
                  >
                    Update
                  </Button>
                )}
                {state === AlertButtonStateEnum.Pause && (
                  <Button onClick={onPause} isLoading={isUpdateLoading}>
                    Pause
                  </Button>
                )}
              </div>
              <Accordion allowToggle allowMultiple>
                <AccordionItem pb={4}>
                  {({ isExpanded }) => (
                    <>
                      <AccordionButton>
                        <VStack align={"flex-start"} my={0}>
                          <EventAlertEditorHeading
                            title="How to receive the alert"
                            stepNumber={1}
                          />
                          {!isExpanded && (
                            <Box
                              bg={
                                deliveryChannel?.label ? "gray.100" : "red.50"
                              }
                              color={
                                deliveryChannel?.label ? "gray.900" : "red.500"
                              }
                              fontSize="sm"
                              fontWeight="medium"
                              px={3}
                              py={1}
                              borderRadius={4}
                            >
                              {deliveryChannel?.label ||
                                "Select a delivery channel"}
                            </Box>
                          )}
                        </VStack>
                      </AccordionButton>
                      <AccordionPanel>
                        <div className="flex flex-col pb-3">
                          <Text mb={0} fontWeight="medium" fontSize="sm">
                            Delivery channel
                          </Text>
                          <Text mb={2} fontSize="sm" color="gray.600">
                            The method you wish to receive the alert with
                          </Text>
                          <Select
                            size="sm"
                            width="200px"
                            value={deliveryChannel?.value}
                            placeholder="Select a delivery channel"
                            onChange={(e) => {
                              const newValue = Number(
                                e.target.value,
                              ) as IAlertDeliveryChannelEnum;
                              setAlert({
                                ...alert,
                                deliveryChannel: newValue,
                              });
                            }}
                          >
                            {DELIVERY_CHANNELS.map(
                              (
                                option: IDeliveryChannelOption,
                                index: number,
                              ) => (
                                <option
                                  key={index}
                                  value={option.value}
                                  disabled={
                                    option.value ===
                                    IAlertDeliveryChannelEnum.Email
                                  }
                                >
                                  {option.label}
                                </option>
                              ),
                            )}
                          </Select>
                        </div>
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
                <AccordionItem pb={4}>
                  {({ isExpanded }) => (
                    <>
                      <AccordionButton>
                        <VStack align={"flex-start"} my={0}>
                          <EventAlertEditorHeading
                            title="Where to receive the alert"
                            stepNumber={2}
                          />
                          {!isExpanded && (
                            <>
                              {typeof deliveryChannel?.value === "undefined" ? (
                                <Box
                                  bg="red.50"
                                  color="red.500"
                                  fontSize="sm"
                                  fontWeight="medium"
                                  px={3}
                                  py={1}
                                  borderRadius={4}
                                >
                                  Select a delivery channel
                                </Box>
                              ) : (
                                <>
                                  {deliveryChannel?.value ===
                                    IAlertDeliveryChannelEnum.Email && (
                                    <Box
                                      bg={
                                        alert?.setup?.emails
                                          ? "gray.100"
                                          : "red.50"
                                      }
                                      color={
                                        alert?.setup?.emails
                                          ? "gray.900"
                                          : "red.500"
                                      }
                                      fontSize="sm"
                                      fontWeight="medium"
                                      px={3}
                                      py={1}
                                      borderRadius={4}
                                    >
                                      {alert?.setup?.emails ||
                                        "Enter email addresses"}
                                    </Box>
                                  )}
                                  {deliveryChannel?.value ===
                                    IAlertDeliveryChannelEnum.Slack && (
                                    <Box
                                      bg={
                                        alert?.slackChannelId &&
                                        hasSlackAppInstalled
                                          ? "gray.100"
                                          : "red.50"
                                      }
                                      color={
                                        alert?.slackChannelId &&
                                        hasSlackAppInstalled
                                          ? "gray.900"
                                          : "red.500"
                                      }
                                      fontSize="sm"
                                      fontWeight="medium"
                                      px={3}
                                      py={1}
                                      borderRadius={4}
                                    >
                                      {!hasSlackAppInstalled &&
                                        "Connect your Slack account"}
                                      {hasSlackAppInstalled &&
                                        alert?.slackChannelId &&
                                        "Channel selected"}
                                      {hasSlackAppInstalled &&
                                        !alert?.slackChannelId &&
                                        "Select a channel"}
                                    </Box>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </VStack>
                      </AccordionButton>
                      <AccordionPanel>
                        <div className="flex flex-col pb-3">
                          {typeof deliveryChannel?.value === "undefined" ? (
                            <Box
                              px={3}
                              py={1}
                              w="192px"
                              bg="red.50"
                              color="red.500"
                              fontSize="sm"
                              fontWeight="medium"
                              borderRadius={4}
                            >
                              Select a delivery channel
                            </Box>
                          ) : (
                            <>
                              {deliveryChannel?.value ===
                                IAlertDeliveryChannelEnum.Email && (
                                <>
                                  <Text
                                    mb={0}
                                    fontWeight="medium"
                                    fontSize="sm"
                                  >
                                    Email addresses
                                  </Text>
                                  <Text mb={2} fontSize="sm" color="gray.600">
                                    Enter comma-separated email addresses to
                                    receive the alerts
                                  </Text>
                                  <Input
                                    size="sm"
                                    width="400px"
                                    placeholder="e.g. user@example.com, another@example.com"
                                    value={alert?.setup?.emails}
                                    onChange={(e) =>
                                      setAlert({
                                        ...alert,
                                        setup: {
                                          ...alert?.setup,
                                          emails: e.target.value,
                                        },
                                      })
                                    }
                                  />
                                </>
                              )}
                              {deliveryChannel?.value ===
                                IAlertDeliveryChannelEnum.Slack && (
                                <>
                                  <Text
                                    mb={0}
                                    fontWeight="medium"
                                    fontSize="sm"
                                  >
                                    Slack channel
                                  </Text>
                                  <Text mb={2} fontSize="sm" color="gray.600">
                                    The channel you wish to receive the alert in
                                  </Text>
                                  {!hasSlackAppInstalled ? (
                                    <Box>
                                      <Button
                                        colorScheme="purple"
                                        variant="ghost"
                                        onClick={() => {
                                          localStorage.setItem(
                                            SLACK_REDIRECT_URL_KEY,
                                            `/a/${appId}/audience/${audienceId}?showSlack=true`,
                                          );
                                          window.location.replace(
                                            `${import.meta.env.VITE_API_HOST}/auth/slack`,
                                          );
                                        }}
                                      >
                                        Connect Slack to select a channel
                                      </Button>
                                    </Box>
                                  ) : (
                                    <SlackChannels
                                      onChange={(channelId) =>
                                        setAlert({
                                          ...alert,
                                          slackChannelId: channelId,
                                        })
                                      }
                                      defaultValue={alert?.slackChannelId || ""}
                                    />
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
                <AccordionItem pb={4}>
                  {({ isExpanded }) => (
                    <>
                      <AccordionButton>
                        <VStack align={"flex-start"} my={0}>
                          <EventAlertEditorHeading
                            title="When to receive the alert"
                            stepNumber={3}
                          />
                          {!isExpanded && (
                            <Box
                              bg={frequency?.label ? "gray.100" : "red.50"}
                              color={frequency?.label ? "gray.900" : "red.500"}
                              fontSize="sm"
                              fontWeight="medium"
                              px={3}
                              py={1}
                              borderRadius={4}
                            >
                              {frequency?.label || "Select a frequency"}
                            </Box>
                          )}
                        </VStack>
                      </AccordionButton>
                      <AccordionPanel>
                        <div className="flex flex-col py-3">
                          <Text mb={0} fontWeight="medium" fontSize="sm">
                            Frequency
                          </Text>
                          <Text mb={2} fontSize="sm" color="gray.600">
                            How frequently you wish to receive alerts
                          </Text>
                          <Select
                            size="sm"
                            width="md"
                            borderRadius={"lg"}
                            value={frequency?.value}
                            placeholder="Select a frequency"
                            onChange={(e) => {
                              const newValue = Number(
                                e.target.value,
                              ) as IAlertFrequencyEnum;
                              setAlert({
                                ...alert,
                                frequency: newValue,
                              });
                            }}
                          >
                            {FREQUENCIES.map((f, i) => (
                              <option key={i} value={f.value}>
                                {f.label} - {f.description}
                              </option>
                            ))}
                          </Select>
                        </div>
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
                <AccordionItem pb={4}>
                  {({ isExpanded }) => (
                    <>
                      <AccordionButton>
                        <VStack w="full" align={"flex-start"} my={0}>
                          <EventAlertEditorHeading
                            title="Message"
                            stepNumber={4}
                          />
                          {!isExpanded && (
                            <div className="w-full">
                              <div className="w-full rounded-lg bg-purple-100 p-3">
                                <Message
                                  body={
                                    <RenderedMessagePreview
                                      messageBody={messageBody}
                                    />
                                  }
                                />
                              </div>
                            </div>
                          )}
                        </VStack>
                      </AccordionButton>
                      <AccordionPanel>
                        <VStack align={"start"} my={0} py={0}>
                          <div className="flex flex-col py-3">
                            <div className="flex flex-col">
                              <div className="flex">
                                <Text mb={0} fontWeight="medium" fontSize="sm">
                                  Message content
                                </Text>
                                <Tooltip
                                  label={
                                    <TooltipBody
                                      header="How it works"
                                      text="You can customize the Slack message using contact.traits, company.traits, and event.properties. For example, if you collect emails for your contacts, names of your companies, and image URLs for your events, you could specify:

                    {{contact.traits.email}} from {{company.traits.name}} just rendered a photo {{event.properties.imageUrl}}"
                                    />
                                  }
                                  placement="bottom"
                                  borderRadius="lg"
                                >
                                  <Box ml={2}>
                                    <Icon
                                      iconType="icon"
                                      name="question-purple"
                                    />
                                  </Box>
                                </Tooltip>
                              </div>
                              <div className="flex">
                                <Text mb={2} fontSize="sm" color="gray.600">
                                  The content of the alert you wish to receive.{" "}
                                  <Link
                                    href="https://api.slack.com/reference/surfaces/formatting"
                                    isExternal
                                    textDecoration={"underline"}
                                    _hover={{ color: "gray.500" }}
                                  >
                                    See which formats Slack support.
                                  </Link>
                                </Text>
                              </div>
                            </div>
                            <EventAlertEditorTextArea
                              w="100%"
                              rows={3}
                              focusBorderColor="purple.300"
                              onChange={(e) => {
                                const newValue = e.target.value;
                                setAlert({
                                  ...alert,
                                  setup: {
                                    ...alert.setup,
                                    messageBody: newValue,
                                  },
                                });
                              }}
                              defaultValue={messageBody}
                              showGroupContextWarning={false}
                              fontSize="sm"
                            />
                          </div>
                          <div className="flex w-full flex-col">
                            <Text mb={0} fontWeight="medium" fontSize="sm">
                              Preview
                            </Text>
                            <Text mb={2} fontSize="sm" color="gray.600">
                              This is a preview of the event alert you will
                              receive
                            </Text>
                            <div className="rounded-lg bg-purple-100 p-3">
                              <Message
                                body={
                                  <div className="flex">
                                    <RenderedMessagePreview
                                      messageBody={messageBody}
                                    />
                                  </div>
                                }
                              />
                            </div>
                          </div>
                        </VStack>
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
                <AccordionItem pb={4}>
                  {({ isExpanded }) => (
                    <>
                      <AccordionButton>
                        <VStack align={"flex-start"} my={0}>
                          <EventAlertEditorHeading
                            title="Advanced"
                            stepNumber={5}
                          />
                        </VStack>
                      </AccordionButton>
                      <AccordionPanel>
                        <div className="flex flex-col py-3">
                          <Text mb={0} fontWeight="medium" fontSize="sm">
                            Action
                          </Text>
                          <Text mb={2} fontSize="sm" color="gray.600">
                            The action you want to be alerted on
                          </Text>
                          <Select
                            size="sm"
                            width="md"
                            borderRadius={"lg"}
                            value={audienceStatus?.value}
                            placeholder="Select an action"
                            onChange={(e) => {
                              const newValue = Number(
                                e.target.value,
                              ) as AudienceStatus;
                              setAlert({
                                ...alert,
                                setup: {
                                  ...alert?.setup,
                                  audienceStatus: newValue,
                                },
                              });
                            }}
                          >
                            {AUDIENCE_STATUS_OPTIONS.map((f, i) => (
                              <option key={i} value={f.value}>
                                {f.label} - {f.description}
                              </option>
                            ))}
                          </Select>
                        </div>
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
              </Accordion>
              <Box h={24} />
            </Box>
          </div>
        </div>
      </DrawerContent>
    </Drawer>
  );
};
