import { IViewInsight } from "../viewInsights";
import { slice } from "./slice";

export const { _setOpenedInsight, _setFullModeInsight } = slice.actions;

export const openViewInsight =
  ({ viewInsight }: { viewInsight: IViewInsight | null }) =>
  async (dispatch: any) => {
    dispatch(_setOpenedInsight({ viewInsight }));
  };

export const closeViewInsight = () => async (dispatch: any) => {
  dispatch(_setOpenedInsight({ viewInsight: null }));
};

export const updateViewInsight =
  ({ viewInsight }: { viewInsight: IViewInsight }) =>
  async (dispatch: any) => {
    dispatch(_setFullModeInsight({ viewInsight }));
  };

export const openFullModeViewInsight =
  ({ viewInsight }: { viewInsight: IViewInsight | null }) =>
  async (dispatch: any) => {
    dispatch(_setFullModeInsight({ viewInsight }));
  };

export const closeFullModeViewInsight = () => async (dispatch: any) => {
  dispatch(_setFullModeInsight({ viewInsight: null }));
};
