import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useGroup } from "core/hooks/useGroup";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import Icon from "core/design-system/components/Icon";
import {
  Box,
  ComponentDefaultProps,
  Flex,
  Skeleton,
  Tooltip,
} from "@chakra-ui/react";
import { GroupType } from "./GroupListSingleGroup";
import Group from "./Group";

interface ISingleGroup extends ComponentDefaultProps {
  id: string;
  groupType?: number;
  truncateName?: boolean;
  hideHoverBorder?: boolean;
  hideExternalLinkIcon?: boolean;
}

// NOTE: Do not use for drilldowns

export const SingleGroup: React.FC<ISingleGroup> = ({
  id,
  groupType,
  truncateName = false,
  hideHoverBorder = false,
  hideExternalLinkIcon = false,
  ...props
}) => {
  const { id: appId } = useCurrentApp();
  const { fetchGroup, groupLoading, groupError, group } = useGroup({ id });

  useEffect(() => {
    fetchGroup();
  }, [fetchGroup]);

  // Need to check for groupLoading === undefined because of empty initial state
  if (groupLoading || groupLoading === undefined) {
    return (
      <Flex
        p={2}
        align="center"
        justify="space-between"
        border="1px"
        borderRadius="lg"
        borderColor="white"
        _hover={{
          borderColor: hideHoverBorder ? "" : "gray.200",
          cursor: "pointer",
        }}
      >
        <Flex justify="flex-start" align="center">
          <Skeleton borderRadius="full" w={6} h={6} mr={4} />
          <Skeleton w={24} h={4} />
        </Flex>
        {!hideExternalLinkIcon && (
          <Icon
            mr={1}
            h={4}
            w={4}
            iconType="icon"
            cursor="pointer"
            color="gray.600"
            name="external-link"
          />
        )}
      </Flex>
    );
  }

  if (groupError && !groupLoading) {
    return (
      <Tooltip label="This group hasn't been identified" placement="bottom">
        <Flex
          p={2}
          align="center"
          justify="space-between"
          border="1px"
          borderRadius="lg"
          borderColor="white"
          _hover={{ borderColor: hideHoverBorder ? "" : "gray.200" }}
        >
          <Group unidentified groupId={id} size="sm" />
          {!hideExternalLinkIcon && (
            <Box>
              <Icon
                mr={1}
                h={4}
                w={4}
                iconType="icon"
                cursor="pointer"
                color="gray.300"
                name="external-link"
              />
            </Box>
          )}
        </Flex>
      </Tooltip>
    );
  }

  return (
    <Link
      to={`/a/${appId}/${groupType === GroupType.Company ? `company/${id}/` : `group/${encodeURIComponent(id)}`}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Flex
        p={2}
        align="center"
        justify="space-between"
        border="1px"
        borderRadius="lg"
        borderColor="white"
        _hover={{
          borderColor: hideHoverBorder ? "" : "gray.200",
          cursor: "pointer",
        }}
        {...props}
      >
        {group && <Group size="sm" group={group} groupType={groupType} />}
        {!hideExternalLinkIcon && (
          <Icon
            mr={1}
            h={4}
            w={4}
            iconType="icon"
            cursor="pointer"
            color="gray.600"
            name="external-link"
          />
        )}
      </Flex>
    </Link>
  );
};

export default SingleGroup;
