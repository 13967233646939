import { useParams } from "react-router-dom";
import { useAudienceAlert } from "core/hooks/useAudienceAlert";
import { BellAlertIcon as BellAlertIconSolid } from "@heroicons/react/24/solid";
import { BellAlertIcon as BellAlertIconOutline } from "@heroicons/react/24/outline";
import { Button, useDisclosure } from "@chakra-ui/react";
import { EditorDrawer } from "./EditorDrawer";
import { PaywallPopover } from "core/components/Paywall/Popover";
import { Plan } from "core/types/App";

export const AudienceAlertButton: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { audienceId } = useParams<{
    audienceId: string;
  }>() as {
    audienceId: string;
  };
  const { isEnabled, isLoading } = useAudienceAlert({
    audienceId: Number(audienceId),
  });

  return (
    <>
      <PaywallPopover feature="audience alerts" redirect="home" plan={Plan.Pro}>
        <Button
          variant="ghost"
          isLoading={isLoading}
          onClick={onOpen}
          leftIcon={
            isEnabled ? (
              <BellAlertIconSolid className="h-5 w-5 text-purple-500" />
            ) : (
              <BellAlertIconOutline className="h-5 w-5 text-gray-700" />
            )
          }
        >
          Create alert
        </Button>
      </PaywallPopover>
      <EditorDrawer isOpen={isOpen} onClose={onClose} />
    </>
  );
};
