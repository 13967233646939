import { JoinOperatorValue } from "core/types/Filters.d";
import { ComponentDefaultProps } from "@chakra-ui/react";
import {
  ComboboxOption,
  Popover,
  PopoverButton,
  PopoverPanel,
} from "@headlessui/react";
import { Command, CommandList } from "core/design-system/components/Command";
import { CheckIcon } from "@heroicons/react/20/solid";
import { cx } from "helpers/cx";

interface IOperatorMenuProps extends ComponentDefaultProps {
  joinOperator: JoinOperatorValue;
  onChangeJoinOperator: (joinOperator: JoinOperatorValue) => void;
  filterGroup?: boolean;
  highlight?: boolean;
  setHighlight?: (highlight: boolean) => void;
}

export const OperatorMenu: React.FC<IOperatorMenuProps> = ({
  joinOperator,
  onChangeJoinOperator,
  filterGroup = true,
  highlight = false,
  setHighlight,
}) => {
  return (
    <div className="flex">
      <Popover>
        <PopoverButton
          onMouseEnter={() => setHighlight && setHighlight(true)}
          onMouseLeave={() => setHighlight && setHighlight(false)}
          data-testid="audience-filter-trait-comparison-editor"
          className={cx(
            "flex w-full min-w-full items-center px-1.5 py-0.5 text-sm",
            filterGroup && "rounded-lg",
            !filterGroup && "hover:bg-gray-100",
            filterGroup && highlight && "bg-gray-50 hover:bg-gray-100",
            !filterGroup && highlight && "bg-gray-50",
          )}
        >
          <p className={cx("p-1 font-medium")}>
            {joinOperator === JoinOperatorValue.AND ? "and" : "or"}
          </p>
        </PopoverButton>
        <PopoverPanel className="flex w-full flex-col">
          {({ close }) => (
            <Command className="w-full max-w-[100px]">
              <CommandList>
                <div className="w-full text-left">
                  <ComboboxOption
                    className="w-full"
                    value={JoinOperatorValue.AND}
                    onClick={() => {
                      onChangeJoinOperator(JoinOperatorValue.AND);
                      close();
                    }}
                  >
                    <div className="flex w-full cursor-pointer items-center gap-1 rounded-md px-2 py-1 hover:bg-gray-100">
                      <p className="text-sm">and</p>
                      {joinOperator === JoinOperatorValue.AND && (
                        <CheckIcon className="h-4 text-purple-500" />
                      )}
                    </div>
                  </ComboboxOption>
                  <ComboboxOption
                    className="w-full"
                    value={JoinOperatorValue.OR}
                    onClick={() => {
                      onChangeJoinOperator(JoinOperatorValue.OR);
                      close();
                    }}
                  >
                    <div className="flex w-full cursor-pointer items-center gap-1 rounded-md px-2 py-1 hover:bg-gray-100">
                      <p className="text-sm">or</p>
                      {joinOperator === JoinOperatorValue.OR && (
                        <CheckIcon className="h-4 text-purple-500" />
                      )}
                    </div>
                  </ComboboxOption>
                </div>
              </CommandList>
            </Command>
          )}
        </PopoverPanel>
      </Popover>
    </div>
  );
};
