import { Link } from "react-router-dom";
import React from "react";
import moment from "moment-timezone";
import { ITraitConfig } from "core/types/Trait";
import { Plan } from "core/types/App";
import { useTimeZone } from "core/hooks/useCurrentApp";
import Icon from "core/design-system/components/Icon";
import { ComputationType } from "core/constants/traits";
import { LongValueWrapper } from "core/components/Traits/LongValueWrapper";
import { PopoverCopy } from "core/components/ClipboardButton";
import { BoltIcon as BoltIconOutline } from "@heroicons/react/24/outline";
import {
  ArrowLongDownIcon,
  ArrowLongUpIcon,
  BoltIcon,
  CheckCircleIcon,
  ClockIcon,
  LockClosedIcon,
  XCircleIcon,
} from "@heroicons/react/16/solid";
import { Skeleton, Spacer, Text, Tooltip } from "@chakra-ui/react";
import { TraitIcon } from "./TraitIcon";
import { ComputedTraitTooltip } from "./ComputedTraitTooltip";
import { PaywallPopover } from "../Paywall/Popover";
import { AppNavLink } from "../../../Components/AppNavLink";

interface ITraitProps {
  name: string;
  value?: string;
  isTransient?: boolean;
  isPaywalled?: boolean;
  isComputed?: boolean;
  config?: ITraitConfig;
  traitId?: number;
  lastComputedAt?: string;
  isLoading?: boolean;
  description?: string;
  isPinned?: boolean;
  onPinTrait?: (isPinned: boolean) => void;
  editMode?: boolean;
  isLive?: boolean;
}

function isValidUrl(string: string) {
  try {
    new URL(string);
    return true;
  } catch (err) {
    return false;
  }
}

export const ChangeIcon: React.FC<{ value: string }> = ({ value }) => {
  if (Number(value) >= 0) {
    return <ArrowLongUpIcon className="h-4 text-green-500" />;
  }

  return <ArrowLongDownIcon className="h-4 text-red-500" />;
};

export const Trait: React.FC<ITraitProps> = ({
  name,
  value,
  isTransient = false,
  isComputed = false,
  config,
  lastComputedAt,
  traitId,
  isLoading = false,
  isPaywalled = false,
  description,
  isPinned = false,
  onPinTrait,
  editMode = false,
  isLive = false,
}) => {
  const isLink = value && isValidUrl(value);
  const isEventCountChange =
    value && config?.computationType === ComputationType.EventCountChange;

  const isPercentage =
    value &&
    config?.computationType === ComputationType.Ratio &&
    config?.isPercentage;
  const isTimestamp =
    value &&
    config?.computationType &&
    [ComputationType.FirstSeen, ComputationType.LastSeen].includes(
      config?.computationType,
    ) &&
    moment(value).isValid();
  const timezone = useTimeZone();

  let formattedValue = value;
  let tooltipValue;
  if (isEventCountChange || isPercentage) {
    formattedValue = Math.abs(Number(value)).toString();
  } else if (isTimestamp) {
    const formattedDate = moment.utc(value).tz(timezone).format("DD MMM YY");
    const formattedTime = moment.utc(value).tz(timezone).format("hh:mm a");
    formattedValue = `${formattedDate}, ${formattedTime}`;
    tooltipValue = moment
      .utc(value)
      .tz(timezone)
      .format("Do MMMM YYYY, h:mm:ss a z");
  }

  return (
    <div className="flex w-full items-center pb-1">
      <div className="flex w-full flex-row justify-between gap-2 overflow-x-hidden">
        <div className={`${isPaywalled && isComputed ? "w-fit" : "w-auto"}`}>
          {isComputed ? (
            <>
              {isPaywalled ? (
                <PaywallPopover
                  feature="computed traits"
                  redirect="settings/computed-traits"
                  plan={Plan.Pro}
                >
                  <span
                    className={`overflow-hidden overflow-ellipsis whitespace-nowrap text-sm`}
                  >
                    {name}
                  </span>
                </PaywallPopover>
              ) : (
                <AppNavLink
                  to={`/integrations/computed-traits/${traitId}`}
                  w="full"
                >
                  <Tooltip
                    hasArrow
                    bg="white"
                    textColor="gray.800"
                    shadow="sm"
                    label={
                      <ComputedTraitTooltip
                        name={name}
                        description={description}
                        lastComputedAt={lastComputedAt}
                        isLive={isLive}
                      />
                    }
                    placement="top"
                  >
                    <div className="flex items-center gap-1">
                      <TraitIcon
                        traitName={name}
                        computationType={config?.computationType}
                        color="text-gray-600"
                      />
                      <Text
                        _hover={{ borderBottom: "1px dashed" }}
                        noOfLines={1}
                        fontSize="sm"
                        fontWeight="medium"
                        color="gray.600"
                        borderBottom="1px dashed transparent"
                      >
                        {name}
                      </Text>
                      {isLive ? (
                        <BoltIcon className="h-3 text-purple-500" />
                      ) : (
                        <BoltIconOutline className="h-3 text-purple-500" />
                      )}
                    </div>
                  </Tooltip>
                </AppNavLink>
              )}
            </>
          ) : (
            <div className="flex items-center gap-1">
              {isTransient && (
                <Tooltip
                  label="This trait will be applied in one minute."
                  placement="left"
                >
                  <ClockIcon className="h-4 text-gray-500" />
                </Tooltip>
              )}
              <TraitIcon
                traitName={name}
                computationType={config?.computationType}
                color="text-gray-600"
              />
              <div className="line-clamp-1 flex-1 text-sm font-medium text-gray-600">
                {name}
              </div>
            </div>
          )}
        </div>
        {isTransient ? (
          <Text fontSize="sm" color={"gray.500"} noOfLines={1}>
            processing
          </Text>
        ) : (
          <div className="w-auto">
            {value && !isLoading ? (
              <>
                {isLink && value ? (
                  <PopoverCopy text={value} disabled={editMode}>
                    <Link
                      target={"_blank"}
                      data-testid="trait-value-link"
                      to={value}
                      className="overflow-hidden overflow-ellipsis whitespace-nowrap text-sm text-gray-900 underline"
                    >
                      {value === "true" ? (
                        <CheckCircleIcon className="h-4 w-4 text-green-400" />
                      ) : value === "false" ? (
                        <XCircleIcon className="h-4 w-4 text-red-400" />
                      ) : (
                        <LongValueWrapper value={value || ""} />
                      )}
                    </Link>
                  </PopoverCopy>
                ) : isPaywalled ? (
                  <PaywallPopover
                    feature="computed traits"
                    redirect="settings/computed-traits"
                    plan={Plan.Pro}
                  >
                    <span
                      data-testid="paywalled-trait-value"
                      className="overflow-hidden overflow-ellipsis whitespace-nowrap pl-[4px] text-sm text-gray-600 opacity-75 blur-[3px] filter"
                    >
                      {value === "true" ? (
                        <CheckCircleIcon className="h-4 w-4 text-green-400" />
                      ) : value === "false" ? (
                        <XCircleIcon className="h-4 w-4 text-red-400" />
                      ) : (
                        value
                      )}
                    </span>
                  </PaywallPopover>
                ) : (
                  <PopoverCopy text={value} disabled={editMode}>
                    <div className="overflow-hidden overflow-ellipsis whitespace-nowrap text-sm text-gray-900">
                      <div className="flex items-center">
                        {isEventCountChange && <ChangeIcon value={value} />}
                        {isTimestamp && tooltipValue ? (
                          <Tooltip
                            label={tooltipValue}
                            placement="top"
                            fontSize="xs"
                            maxW="250px"
                            px={2}
                            bg="white"
                            color="gray.800"
                            border="1px solid"
                            borderColor="gray.200"
                            size="xs"
                            shadow="none"
                          >
                            <span className="cursor-pointer">
                              {formattedValue}
                            </span>
                          </Tooltip>
                        ) : (
                          <LongValueWrapper value={formattedValue || ""} />
                        )}
                        {(isEventCountChange || isPercentage) && <span>%</span>}
                      </div>
                    </div>
                  </PopoverCopy>
                )}
              </>
            ) : (
              <Skeleton borderRadius="md" h="20px" w="100%" />
            )}
          </div>
        )}
      </div>
      <Spacer />
      {!isTransient && editMode && !isPaywalled && (
        <div className="ml-2">
          {isPinned ? (
            <div className="cursor-pointer" onClick={() => onPinTrait?.(false)}>
              <Icon iconType="icon" name="pin" color="gray.600" />
            </div>
          ) : (
            <div className="cursor-pointer" onClick={() => onPinTrait?.(true)}>
              <Icon
                iconType="icon"
                name="pin-outline"
                color="gray.500"
                fill="none"
              />
            </div>
          )}
        </div>
      )}
      {isPaywalled && (
        <div className="flex" data-testid="trait-paywall-icon">
          <PaywallPopover
            feature="computed traits"
            redirect="settings/computed-traits"
            plan={Plan.Pro}
          >
            <LockClosedIcon className="mr-2 h-4 text-purple-500" />
          </PaywallPopover>
        </div>
      )}
    </div>
  );
};

export default Trait;
