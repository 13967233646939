import { useNavigate } from "react-router-dom";
import { useState } from "react";
import moment from "moment";
import { IReportDateRangeOption } from "core/types/Report";
import {
  getInitialTimerange,
  getReadableTimerange,
} from "core/helpers/timerange";
import {
  ABSOLUTE,
  RELATIVE,
  RELATIVE_TIMERANGE_VALUE_FROM_KEYS,
} from "core/constants/timerange";
import { useReportSetup } from "./useReportSetup";
import { useQueryParam } from "./useQueryParam";
import { ITimerangeOption } from "../types/TimerangeOption";

export interface ICustomTimerange {
  startDate: Date;
  endDate: Date;
  key: string;
}

export const useReportTimerange = ({
  type,
  value,
  startTimestamp,
  endTimestamp,
}: {
  type: typeof RELATIVE | typeof ABSOLUTE;
  value: number | undefined;
  startTimestamp: string | undefined;
  endTimestamp: string | undefined;
}): {
  label: string;
  timerangeType: typeof RELATIVE | typeof ABSOLUTE;
  timerangeValue: number;
  timerangeStartTimestamp: string;
  timerangeEndTimestamp: string;
  relativeTimerange: ITimerangeOption | undefined;
  customTimerange: ICustomTimerange[];
  readableCustomTimerange: string;
  setCustomTimerange: (customTimerange: ICustomTimerange[]) => void;
  saveTimerange: (option: IReportDateRangeOption) => void;
} => {
  const navigate = useNavigate();

  const timerangeType = Number(useQueryParam("timerangeType")) || type;
  const timerangeValue = Number(useQueryParam("timerangeValue")) || value;
  const timerangeStartTimestamp =
    useQueryParam("timerangeStartTimestamp") || startTimestamp;
  const timerangeEndTimestamp =
    useQueryParam("timerangeEndTimestamp") || endTimestamp;

  const relativeTimerange = getInitialTimerange({
    timerangeType,
    timerangeValue,
  });

  let startDate = timerangeStartTimestamp
    ? moment.utc(timerangeStartTimestamp).toDate()
    : new Date(
        Date.UTC(
          new Date().getUTCFullYear(),
          new Date().getUTCMonth(),
          new Date().getUTCDate() - 7,
        ),
      );
  let endDate = timerangeEndTimestamp
    ? moment.utc(timerangeEndTimestamp).toDate()
    : new Date();
  const initialCustomTimerange = [
    {
      startDate,
      endDate,
      key: "selection",
    },
  ];

  const isCustomDate = relativeTimerange?.value === "fixed";
  const [customTimerange, setCustomTimerange] = useState(
    initialCustomTimerange,
  );
  const readableCustomTimerange = getReadableTimerange({
    startTimestamp: customTimerange[0].startDate,
    endTimestamp: customTimerange[0].endDate,
  });
  const label = isCustomDate
    ? readableCustomTimerange
    : relativeTimerange?.label;

  const { updateReportTimerange } = useReportSetup();

  const updateURL = () => {
    const { search } = window.location;
    const params = new URLSearchParams(search);
    params.delete("timerangeType");
    params.delete("timerangeValue");
    params.delete("timerangeStartTimestamp");
    params.delete("timerangeEndTimestamp");
    navigate({ search: params.toString() });
  };

  const saveTimerange = async (newTimerange: IReportDateRangeOption) => {
    const timerangeValue = newTimerange.value
      ? RELATIVE_TIMERANGE_VALUE_FROM_KEYS[
          newTimerange.value as keyof typeof RELATIVE_TIMERANGE_VALUE_FROM_KEYS
        ]
      : 0;
    const timerangeStartTimestamp = moment(customTimerange[0].startDate)
      .startOf("day")
      .format("YYYY-MM-DDTHH:mm:ss");
    const timerangeEndTimestamp = moment(customTimerange[0].endDate)
      .endOf("day")
      .format("YYYY-MM-DDTHH:mm:ss");

    const payload = {
      timerangeType: newTimerange.type,
      timerangeValue,
      timerangeStartTimestamp,
      timerangeEndTimestamp,
    };

    updateReportTimerange(payload);
    updateURL();
  };

  return {
    label: label || "",
    relativeTimerange,
    customTimerange,
    readableCustomTimerange,
    timerangeType,
    timerangeValue,
    timerangeStartTimestamp,
    timerangeEndTimestamp,
    setCustomTimerange,
    saveTimerange,
  };
};
