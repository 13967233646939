import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Select,
  Skeleton,
} from "@chakra-ui/react";
import {
  DataTypeString,
  DataTypeToString,
  ITraitKeyResponse,
  useGetTraitQuery,
  useUpdateTraitMutation,
} from "core/models/traits";
import { useParams } from "react-router-dom";
import { AppObjectType, IAppObject } from "core/models/appObjects";

export enum TraitLevel {
  USER_LEVEL = 0,
  GROUP_LEVEL = 1,
  COMPANY_LEVEL = 2,
}

export const OBJECT_TYPE_TO_TRAIT_LEVEL = {
  [AppObjectType.User]: TraitLevel.USER_LEVEL,
  [AppObjectType.Group]: TraitLevel.GROUP_LEVEL,
  [AppObjectType.Company]: TraitLevel.COMPANY_LEVEL,
};

export const TraitEditModal: React.FC<{
  trait: ITraitKeyResponse;
  isOpen: boolean;
  onClose: () => void;
  appObject: IAppObject;
}> = ({ trait, isOpen, onClose, appObject }) => {
  const { appId } = useParams();

  const level = OBJECT_TYPE_TO_TRAIT_LEVEL[appObject.objectType];
  console.log(level);

  const { data: traitData, isLoading } = useGetTraitQuery({
    appId: Number(appId),
    trait: trait.trait,
    level,
  });

  const [updateTrait] = useUpdateTraitMutation();

  function onChangeDataType(dataType: DataTypeString) {
    if (!traitData) return;
    updateTrait({
      appId: Number(appId),
      id: traitData.id,
      dataType,
    });
  }

  return (
    <Modal size="lg" isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Edit trait {` `} {trait.trait}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <div className="flex flex-col gap-2">
            <p className="text-sm font-medium">Data type</p>
            {isLoading ? (
              <Skeleton w="full" borderRadius="lg" h="35px" />
            ) : (
              <Select
                size="sm"
                value={traitData?.dataType.toLowerCase()}
                onChange={(e) =>
                  onChangeDataType(e.target.value as DataTypeString)
                }
              >
                {Object.values(DataTypeToString).map((dataType) => (
                  <option value={dataType.toLowerCase()}>{dataType}</option>
                ))}
              </Select>
            )}
          </div>
        </ModalBody>

        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
};
