import humps from "humps";
import { transformFilterGroups } from "helpers/params";
import { IPagy } from "core/types/Pagy";
import { IGroup } from "core/types/Group";
import { IAudienceFilters } from "core/types/Audience";
import { baseQuery } from "core/initializers/rtk";
import { createApi } from "@reduxjs/toolkit/query/react";

import type { SnakeCasedPropertiesDeep } from "type-fest";
interface IGetGroupsArgs {
  appId: number;
  page: number;
  audienceFilters: IAudienceFilters;
  searchQuery: string;
  skipPagination?: boolean;
  sortBy?: string;
  sortOrder?: string;
  viewInsightId?: number;
  token?: string;
}

export interface IGetGroupsResponse {
  groups: IGroup[];
  totalCount: number;
  pagy: IPagy;
}

export const groupsApi = createApi({
  baseQuery,
  reducerPath: "groupsApi",
  tagTypes: ["Group"],
  endpoints: (builder) => ({
    getGroups: builder.query<IGetGroupsResponse, IGetGroupsArgs>({
      query: ({
        appId,
        page,
        audienceFilters,
        searchQuery,
        skipPagination,
        sortBy,
        sortOrder,
        viewInsightId,
        token,
      }) => ({
        url: "/groups",
        params: {
          app_id: appId,
          page: page,
          filter_groups: JSON.stringify(
            transformFilterGroups(audienceFilters.filterGroups),
          ),
          join_operator: audienceFilters.joinOperator,
          query: searchQuery,
          skip_pagination: skipPagination,
          sort_by: sortBy,
          sort_order: sortOrder,
          view_insight_id: viewInsightId,
          token,
        },
      }),
      providesTags: ["Group"],
      transformResponse: (
        response: SnakeCasedPropertiesDeep<IGetGroupsResponse>,
      ): IGetGroupsResponse => {
        const camelizedResponse = humps.camelizeKeys(
          response,
        ) as IGetGroupsResponse;

        camelizedResponse.groups = camelizedResponse.groups.map((group) => {
          const { traits, ...rest } = group;
          return {
            ...rest,
            traits:
              response.groups.find((c) => c.id === group.id)?.traits || traits,
          };
        });

        return camelizedResponse;
      },
    }),
  }),
});

export const { useGetGroupsQuery, useLazyGetGroupsQuery } = groupsApi;
