import { Switch } from "@chakra-ui/react";

interface PercentageCalculationToggleProps {
  isChecked: boolean;
  onChange: (isChecked: boolean) => void;
}

export const PercentageCalculationToggle: React.FC<
  PercentageCalculationToggleProps
> = ({ isChecked, onChange }) => {
  return (
    <div className="flex flex-row items-center gap-2">
      <Switch
        colorScheme="purple"
        isChecked={isChecked}
        onChange={(e) => onChange(e.target.checked)}
      />
      <p className="text-sm font-medium text-gray-900">
        Calculate as percentage
      </p>
    </div>
  );
};
