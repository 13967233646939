import { useState } from "react";
import { ITraitKeyResponse } from "core/models/traits";
import { useTraits } from "core/hooks/useTraits";
import { useAppObjects } from "core/hooks/useAppObjects";
import { Lifecycle } from "core/design-system/components/Command/Lifecycle";
import {
  Command,
  CommandInput,
  CommandList,
} from "core/design-system/components/Command";
import { DIVIDER, FilterType } from "core/constants/filters";
import { TraitOptionsList } from "./TraitOptionsList";
import { IFilterMenuProps } from "./AddFilterButton";
import { TraitFilterCta } from "core/components/SmartTrait/TraitFilterCta";
import { cx } from "helpers/cx";

export enum TraitLevel {
  USER_LEVEL = 0,
  GROUP_LEVEL = 1,
  COMPANY_LEVEL = 2,
}

export const TraitMenu: React.FC<
  Pick<
    IFilterMenuProps,
    "onAddTraitFilter" | "onAddEventFilter" | "isEventFilterDisabled"
  > & { onSelectTrait?: (trait: ITraitKeyResponse) => void; height?: number }
> = ({
  onAddTraitFilter,
  onAddEventFilter,
  isEventFilterDisabled,
  onSelectTrait,
  height = 400,
}) => {
  const [hideTraitMenu, setHideTraitMenu] = useState(false);
  const [searchTrait, setSearchTrait] = useState<string>("");
  const { userAppObject, groupAppObject, companyAppObject } = useAppObjects();
  const { userTraits, groupTraits, companyTraits, isUserTraitsLoading } =
    useTraits();

  const filterTypeNames: { [x: number]: string } = {
    [FilterType.UserTrait]: userAppObject?.singularName || "User",
    [FilterType.GroupFilter]: groupAppObject?.singularName || "Group",
    [FilterType.CompanyFilter]: companyAppObject?.singularName || "Company",
  };

  const userTraitDivider = { trait: "User traits", filterType: DIVIDER };
  const companyTraitDivider = { trait: "Company traits", filterType: DIVIDER };
  const groupTraitDivider = {
    trait: `${filterTypeNames[FilterType.GroupFilter]} traits`,
    filterType: DIVIDER,
  };

  const traits = [
    userTraitDivider,
    ...userTraits,
    ...(companyAppObject?.isEnabled
      ? [companyTraitDivider, ...companyTraits]
      : []),
    ...(groupAppObject?.isEnabled ? [groupTraitDivider, ...groupTraits] : []),
  ];

  return (
    <div
      data-testid="audience-trait-menu"
      className={cx(hideTraitMenu ? "hidden" : "block")}
    >
      <Lifecycle onUnmount={() => setSearchTrait("")} />
      <Command
        className={cx(
          Boolean(height) && `min-h-[${height}px]`,
          "min-w-[280px]",
        )}
      >
        <CommandInput
          placeholder="Search filter..."
          value={searchTrait}
          onChange={(e) => {
            setSearchTrait(e.target.value);
          }}
        />
        <CommandList
          className="h-full min-h-full"
          data-testid={`audience-trait-options`}
        >
          <TraitOptionsList
            height={height}
            traits={traits}
            searchTrait={searchTrait}
            onAddTraitFilter={onAddTraitFilter}
            onAddEventFilter={onAddEventFilter}
            isLoading={isUserTraitsLoading}
            filterTypeNames={filterTypeNames}
            isEventFilterDisabled={isEventFilterDisabled}
            onSelectTrait={onSelectTrait}
          />
        </CommandList>
        <TraitFilterCta
          onClick={() => setHideTraitMenu(true)}
          onComplete={(trait) => {
            const filterType =
              trait.level === TraitLevel.USER_LEVEL
                ? FilterType.UserTrait
                : trait.level === TraitLevel.COMPANY_LEVEL
                  ? FilterType.CompanyFilter
                  : FilterType.GroupFilter;
            onAddTraitFilter &&
              onAddTraitFilter(
                filterType as FilterType,
                trait.name,
                trait.dataType,
              );
          }}
        />
      </Command>
    </div>
  );
};
