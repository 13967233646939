import React, { useState } from "react";
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from "@headlessui/react";
import { Input } from "@chakra-ui/react";
import { ChevronDownIcon, CheckIcon } from "@heroicons/react/24/solid";

export interface ITimezone {
  value: string;
  label: string;
  utcOffset: number;
}

interface ISelectTimezoneProps {
  options: ITimezone[];
  defaultOption: ITimezone;
  onChange: (timeZone: string) => void;
}

export const SelectTimezone: React.FC<ISelectTimezoneProps> = ({
  options,
  defaultOption,
  onChange,
}) => {
  const [selectedTimezone, setSelectedTimezone] = useState(defaultOption);
  const [query, setQuery] = useState("");

  const filteredOptions =
    query === ""
      ? options
      : options.filter((timezone) =>
          timezone.label.toLowerCase().includes(query.toLowerCase()),
        );

  const utcOffset =
    selectedTimezone.label !== "UTC"
      ? `(UTC${selectedTimezone.utcOffset >= 0 ? "+" : ""}${
          selectedTimezone.utcOffset
        })`
      : "";

  return (
    <Combobox
      value={selectedTimezone}
      onChange={(timezone: ITimezone) => {
        if (timezone) {
          setSelectedTimezone(timezone);
          onChange(timezone.value);
        }
      }}
    >
      <div className="relative min-w-[300px]">
        <div className="relative">
          <ComboboxInput
            as={Input}
            size="sm"
            displayValue={(timezone: ITimezone) =>
              timezone ? `${timezone.label} ${utcOffset}` : ""
            }
            onChange={(event) => setQuery(event.target.value)}
            focusBorderColor="purple.300"
            borderRadius="lg"
            fontWeight="medium"
            color="gray.800"
          />
          <ComboboxButton className="absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronDownIcon className="h-4 w-4 bg-white pl-1 text-gray-700" />
          </ComboboxButton>
        </div>
        <ComboboxOptions
          anchor="bottom"
          className="h-[350px] min-w-[300px] overflow-auto rounded-lg border bg-white shadow-sm empty:invisible"
        >
          {filteredOptions.length === 0 && query !== "" ? (
            <div className="cursor-default select-none px-4 py-4 text-xs font-medium text-gray-800">
              No results found
            </div>
          ) : (
            filteredOptions.map((option) => (
              <ComboboxOption
                key={option.value}
                value={option}
                className={({ active }) =>
                  `relative cursor-pointer select-none px-4 py-2 text-sm hover:bg-gray-50 ${
                    active ? "bg-gray.100" : ""
                  }`
                }
              >
                <div className="flex items-center justify-between">
                  <div
                    className={
                      option.value === selectedTimezone.value
                        ? "font-semibold text-purple-500"
                        : ""
                    }
                  >
                    {option.label}{" "}
                    <span
                      className={
                        option.value === selectedTimezone.value
                          ? "text-xs font-semibold uppercase text-purple-500"
                          : "text-xs font-semibold uppercase text-gray-600"
                      }
                    >
                      {option.label !== "UTC"
                        ? `(UTC${option.utcOffset >= 0 ? "+" : ""}${
                            option.utcOffset
                          })`
                        : ""}
                    </span>
                  </div>
                  {option.value === selectedTimezone.value && (
                    <CheckIcon className="h-4 w-4 text-purple-600" />
                  )}
                </div>
              </ComboboxOption>
            ))
          )}
        </ComboboxOptions>
      </div>
    </Combobox>
  );
};
