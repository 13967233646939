import React from "react";
import { IViewInsight } from "core/models/viewInsights";
import { useShareViewInsight } from "core/hooks/useShareViewInsight";
import { ClipboardButton } from "core/components/ClipboardButton";
import { Switch, Tooltip } from "@chakra-ui/react";

export const PublicLink: React.FC<{
  viewInsight: IViewInsight;
}> = ({ viewInsight }) => {
  const { publicUrl, isSharing, onRemoveInsightAccess, onShareInsight } =
    useShareViewInsight({ viewInsight });

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center justify-between">
        <div>
          <p className="text-sm font-medium">Public sharing</p>
          <p className="text-sm text-gray-500">
            Publish and share link with anyone
          </p>
        </div>
        <Switch
          colorScheme="purple"
          isChecked={isSharing}
          onChange={() =>
            isSharing ? onRemoveInsightAccess() : onShareInsight()
          }
        />
      </div>
      {isSharing && (
        <div className="mt-4">
          <p className="text-sm font-medium">Copy public link</p>
          <p className="text-sm text-gray-500">
            Anyone with the link can view this insight
          </p>
          <div className="mt-4 rounded-md bg-gray-100 px-4 py-2">
            <div className="flex items-center justify-between">
              <p className="text-sm text-gray-700">{publicUrl}</p>
              <Tooltip
                label="Copy link"
                hasArrow
                placement="top"
                shouldWrapChildren
              >
                <ClipboardButton
                  bg="transparent"
                  value={publicUrl}
                  disabled={!isSharing}
                  showLabel={false}
                />
              </Tooltip>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
