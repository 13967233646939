import { useParams } from "react-router-dom";
import React, { useState } from "react";
import moment from "moment-timezone";
import { CrmHeader } from "modules/Contacts/Contact/CrmHeader";
import { FavouritesMenuItem } from "modules/Company/FavouritesMenuItem";
import { FavouriteObjectType } from "core/models/favourites";
import { AppObjectType } from "core/models/appObjects";
import { useGroup } from "core/hooks/useGroup";
import useFlag from "core/hooks/useFlag";
import { useTimeZone } from "core/hooks/useCurrentApp";
import { useAppObjects } from "core/hooks/useAppObjects";
import { FAVOURITES } from "core/constants/features";
import {
  ClockIcon,
  IdentificationIcon,
  TrashIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  StyleProps,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { ViewAlertButton } from "./ViewAlertButton";
import { DeleteGroupModal } from "./DeleteGroupModal";
import { CompanyLogo } from "./CompanyLogo";

const formatDate = (timestamp: string, timezone: string) => {
  const date = moment.utc(timestamp).tz(timezone);
  const now = moment();
  const daysDiff = now.diff(date, "days");

  return {
    relative: daysDiff < 7 ? date.fromNow() : date.format("DD MMM YYYY"),
    full: date.format("Do MMMM YYYY, hh:mm a z"),
  };
};

export const Header: React.FC<StyleProps> = () => {
  const timezone = useTimeZone();
  const { groupAppObject } = useAppObjects();
  const { appId, groupId: encodedGroupId } = useParams<{
    appId: string;
    groupId: string;
  }>() as {
    appId: string;
    groupId: string;
  };
  const groupId = decodeURIComponent(encodedGroupId);
  const { group } = useGroup({ id: groupId });
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const hasFavouritesEnabled = useFlag(FAVOURITES);

  if (!group || !groupAppObject) {
    return (
      <div className="flex w-full flex-col p-8">
        <div className="mb-6 flex items-center">
          <CompanyLogo size="lg" />
          <div className="ml-4">
            <span className="text-lg font-normal">Unknown</span>
          </div>
        </div>
      </div>
    );
  }

  const groupCount = group.contacts?.length;

  if (group) {
    document.title = `June - ${group.name}`;
  }

  return (
    <div className="flex">
      <div className="z-docked flex w-full flex-col">
        <div className="flex items-start justify-between">
          <div className="mb-6">
            <div className="flex items-center gap-4">
              <CompanyLogo
                border="4px solid"
                borderColor="white"
                boxShadow="sm"
                size="lg"
              />
              <div className="flex flex-col">
                <div>
                  <Text fontSize="lg" fontWeight={"medium"}>
                    {group.name}
                  </Text>
                </div>
                {group.firstSeenAt && (
                  <Tooltip
                    label={formatDate(group.firstSeenAt, timezone).full}
                    placement="bottom"
                    bg="white"
                    border="1px solid"
                    borderColor="gray.300"
                    fontSize="xs"
                    color="gray.800"
                    px={3}
                  >
                    <div className="text-sm text-gray-600">
                      Joined{" "}
                      <span className="cursor-pointer font-medium">
                        {formatDate(group.firstSeenAt, timezone).relative}
                      </span>
                    </div>
                  </Tooltip>
                )}
              </div>
            </div>
          </div>
          <div className="flex items-center justify-end gap-2">
            <ViewAlertButton appObjectType={AppObjectType.Group} />
            <Menu>
              <MenuButton
                as={Button}
                aria-label="Edit user menu"
                variant="ghost"
                size="xs"
                shadow="none"
                boxShadow="none"
                mr={-2}
              >
                <EllipsisVerticalIcon className="h-4 w-4 text-gray-700" />
              </MenuButton>
              <MenuList>
                <MenuItem
                  icon={<TrashIcon className="h-4" />}
                  onClick={() => setShowDeleteModal(!showDeleteModal)}
                >
                  <Text fontSize="sm">
                    Delete {groupAppObject.singularName.toLowerCase()}
                  </Text>
                </MenuItem>
                {hasFavouritesEnabled && (
                  <FavouritesMenuItem
                    objectType={FavouriteObjectType.Group}
                    objectId={groupId}
                    appId={Number(appId)}
                  />
                )}
              </MenuList>
            </Menu>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-2">
            <div className="flex items-center">
              <IdentificationIcon className="h-4 text-gray-600" />
            </div>
            <div className="flex items-center">
              <p className="text-sm text-gray-700">{groupId}</p>
            </div>
          </div>
          <div className="flex items-center gap-2">
            <div className="flex items-center">
              <UsersIcon className="h-4 text-gray-600" />
            </div>
            <div className="flex items-center">
              <p className="text-sm text-gray-700">
                {groupCount} user{groupCount === 1 ? "" : "s"}
              </p>
            </div>
          </div>
          <div className="flex items-center gap-2">
            <div className="flex items-center">
              <ClockIcon className="h-4 text-gray-600" />
            </div>
            {group.lastSeenAt ? (
              <div className="flex gap-1">
                <p className="text-sm text-gray-700">Last seen</p>
                <Tooltip
                  label={formatDate(group.lastSeenAt, timezone).full}
                  placement="top"
                  bg="white"
                  border="1px solid"
                  borderColor="gray.300"
                  fontSize="xs"
                  color="gray.800"
                  px={3}
                >
                  <p className="cursor-pointer text-sm text-gray-700">
                    {formatDate(group.lastSeenAt, timezone).relative}
                  </p>
                </Tooltip>
              </div>
            ) : (
              <div className="flex gap-1">
                <p className="text-sm text-gray-700">Pending...</p>
              </div>
            )}
          </div>
          {group && groupAppObject && (
            <CrmHeader entity={group} appObject={groupAppObject} />
          )}
        </div>
        <DeleteGroupModal
          appId={appId}
          group={group}
          isOpen={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
        />
      </div>
    </div>
  );
};
