import { ITraitWithConfig } from "core/types/Trait";
import { IFilter } from "core/types/Filters.d";
import { EventFilter } from "core/modules/reports/setup/Audience/EventFilter";
import { ComputationType } from "core/constants/traits";
import {
  ANY,
  EventType,
  FIRST,
  PAGE,
  TRACK,
} from "core/constants/report-setup";
import { Tooltip } from "@chakra-ui/react";
import AddEventMenu from "../ComputedTrait/AddEventMenu";

export const Events: React.FC<{
  trait: ITraitWithConfig;
  onAddFilter: (type: EventType) => void;
  onChangeFilter: (filter: IFilter, index: number) => void;
  onRemoveFilter: (index: number) => void;
}> = ({ trait, onAddFilter, onChangeFilter, onRemoveFilter }) => {
  return (
    <div key={trait.config.filters.length} className="flex flex-col gap-2">
      {trait.config.filters.map((filter: IFilter, index: number) => {
        const key = filter.id ? filter.id : index;
        return (
          <div key={key}>
            {index > 0 && <div className="mb-2 text-sm font-medium">or</div>}
            <EventFilter
              key={`event-filter-${key}`}
              filter={filter}
              filterIndex={index}
              onRemoveFilter={() => onRemoveFilter(index)}
              onSaveFilter={(newFilter) => {
                onChangeFilter(newFilter, index);
              }}
              enableTimerange={false}
              enableLevelPicker={false}
              enableEventOccurrence={
                trait.config.computationType === ComputationType.UserCount &&
                !(filter.id === ANY || filter.id === FIRST) &&
                !(filter.body.type === EventType.PAGE)
              }
              canRemove={trait.config.filters.length > 0}
              hasSmartEvents={index === 0 && trait.config.filters.length === 1}
            />
          </div>
        );
      })}
      <div>
        <Tooltip
          shouldWrapChildren
          hasArrow
          label={
            trait.config.filters.length === 1 &&
            [ANY, FIRST].includes(Number(trait.config.filters[0].body.eventId))
              ? 'You cannot add more events if you are using smart events, e.g. "Any event" or "First event"'
              : ""
          }
        >
          <AddEventMenu
            supportedEventTypes={[ANY, FIRST, TRACK, PAGE]}
            onAddEvent={onAddFilter}
            isDisabled={
              trait.config.filters.length === 1 &&
              [ANY, FIRST].includes(
                Number(trait.config.filters[0].body.eventId),
              )
            }
          />
        </Tooltip>
      </div>
    </div>
  );
};
