import { ITemplateConfig } from "core/types/TemplateConfig";
import { WnuContainer } from "core/modules/reports/report-types/NewUsers/WnuContainer";
import { SignupReferrers } from "core/modules/reports/report-types/NewUsers/SignupReferrers";
import { Referrers } from "core/modules/reports/report-types/NewUsers/Referrers";
import { MnuContainer } from "core/modules/reports/report-types/NewUsers/MnuContainer";
import { HeaderInsight } from "core/modules/reports/report-types/NewUsers/HeaderInsight";
import { DnuContainer } from "core/modules/reports/report-types/NewUsers/DnuContainer";
import { WngContainer } from "core/modules/reports/report-types/NewGroups/WngContainer";
import { MngContainer } from "core/modules/reports/report-types/NewGroups/MngContainer";
import { HeaderInsight as GroupsHeaderInsight } from "core/modules/reports/report-types/NewGroups/HeaderInsight";
import { DngContainer } from "core/modules/reports/report-types/NewGroups/DngContainer";
import {
  DAU_GROUPED_OPTIONS,
  MAU_GROUPED_OPTIONS,
  WAU_GROUPED_OPTIONS,
} from "core/constants/timerange";
import { Template } from "core/constants/templates";
import {
  AUDIENCE_TYPE,
  FIRST,
  OR,
  PAGE,
  SINGLE_EVENT_TYPE,
  TRACK,
} from "core/constants/report-setup";
import { SimpleAreaChart } from "core/components/ViewInsight/Graphs/SimpleAreaChart";
import { withInView } from "core/components/InsightCard/withInView";
import defaultTemplateConfig from "./defaultTemplateConfig";
import { SimpleBarChart } from "core/components/ViewInsight/Graphs/SimpleBarChart";
import { ITrendData, Trend } from "core/components/ViewInsight/Graphs/Trend";

function transformData(data: any, dataKey: string) {
  return data?.[dataKey]?.map((d: any) => ({
    x: d.humanizedDate,
    y: d[dataKey],
  }));
}

const LazyWnuContainer = withInView(WnuContainer);
const LazyMnuContainer = withInView(MnuContainer);
const LazyMngContainer = withInView(MngContainer);

const newUsers: ITemplateConfig = {
  ...defaultTemplateConfig,
  route: "new-users",
  reportType: 15,
  subtitle: "Find out who signed up for your product",
  aboutTemplate:
    "Get a complete list of the users that started using your product. Make sure your company is growing over time",
  title: "New signups",
  template: Template.NewSignups,
  sections: {
    user: [
      HeaderInsight,
      DnuContainer,
      Referrers,
      SignupReferrers,
      // Below the fold
      LazyWnuContainer,
      LazyMnuContainer,
    ],
    company: [
      GroupsHeaderInsight,
      DngContainer,
      WngContainer,
      LazyMngContainer,
    ],
  },
  insights: [
    {
      typeId: 0,
      slug: "latest-users",
      title: "Latest {APP_OBJECT_PLURAL_NAME}",
      section: HeaderInsight,
      shareable: true,
      slackAlertAvailable: true,
      view: {
        level: "user",
        Component: Trend,
        transform: (data, interval) => {
          if (!data || !Array.isArray(data) || data?.length === 0 || !interval)
            return {};

          const trendData = data?.find((d: any) => d?.interval === interval);
          if (!trendData) return {};

          return {
            count: trendData.count,
            change: trendData.insightChange,
            interval,
          } as ITrendData;
        },
        hasTimerangePicker: false,
      },
    },
    {
      typeId: 1,
      slug: "daily-new-users",
      title: "Daily new {APP_OBJECT_PLURAL_NAME}",
      section: DnuContainer,
      slackAlertAvailable: true,
      description: {
        title: "Learn more about daily new users",
        content:
          "We consider someone as a new user when they trigger the first event of the report setup on a given day and have been identified.",
      },
      hasInProgress: true,
      hasYAxis: true,
      view: {
        level: "user",
        Component: SimpleAreaChart,
        Components: {
          area: SimpleAreaChart,
          bar: SimpleBarChart,
        },
        transform: (data) => transformData(data, "newUsers"),
        hasTimerangePicker: true,
        dateRangeOptions: DAU_GROUPED_OPTIONS,
      },
    },
    {
      typeId: 2,
      slug: "weekly-new-users",
      title: "Weekly new {APP_OBJECT_PLURAL_NAME}",
      section: WnuContainer,
      slackAlertAvailable: true,
      description: {
        title: "Learn more about weekly new users",
        content:
          "We consider someone as a new user when they trigger the first event of the report setup on a given week and have been identified.",
      },
      hasInProgress: true,
      hasYAxis: true,
      view: {
        level: "user",
        Component: SimpleAreaChart,
        Components: {
          area: SimpleAreaChart,
          bar: SimpleBarChart,
        },
        transform: (data) => transformData(data, "newUsers"),
        hasTimerangePicker: true,
        dateRangeOptions: WAU_GROUPED_OPTIONS,
      },
    },
    {
      typeId: 3,
      slug: "monthly-new-users",
      title: "Monthly new {APP_OBJECT_PLURAL_NAME}",
      section: MnuContainer,
      slackAlertAvailable: true,
      description: {
        title: "Learn more about monthly new users",
        content:
          "We consider someone as a new user when they trigger the first event of the report setup on a given month and have been identified.",
      },
      hasInProgress: true,
      hasYAxis: true,
      view: {
        level: "user",
        Component: SimpleAreaChart,
        Components: {
          area: SimpleAreaChart,
          bar: SimpleBarChart,
        },
        transform: (data) => transformData(data, "newUsers"),
        hasTimerangePicker: true,
        dateRangeOptions: MAU_GROUPED_OPTIONS,
      },
    },
    {
      typeId: 5,
      slug: "referrers",
      title: "Where are visitors coming from?",
      section: Referrers,
      shareable: false,
      copyable: false,
      description: {
        title: "Learn more about referrers",
        content: "Some content",
      },
      downloadable: false,
    },
    {
      typeId: 6,
      slug: "signup-referrers",
      title: "Where are signups coming from?",
      section: SignupReferrers,
      shareable: false,
      copyable: false,
      description: {
        title: "Learn more about referrers",
        content: "Some content",
      },
      downloadable: false,
    },
    {
      typeId: 7,
      slug: "latest-companies",
      title: "Latest {APP_OBJECT_PLURAL_NAME}",
      section: GroupsHeaderInsight,
      shareable: true,
      slackAlertAvailable: true,
      view: {
        level: "group",
        Component: Trend,
        transform: (data, interval) => {
          if (!data || !Array.isArray(data) || data?.length === 0 || !interval)
            return {};

          const trendData = data?.find((d: any) => d?.interval === interval);
          if (!trendData) return {};

          return {
            count: trendData.count,
            change: trendData.insightChange,
            interval,
          } as ITrendData;
        },
        hasTimerangePicker: false,
      },
    },
    {
      typeId: 8,
      slug: "daily-new-companies",
      title: "Daily new {APP_OBJECT_PLURAL_NAME}",
      section: DngContainer,
      slackAlertAvailable: true,
      description: {
        title: "Learn more about daily new companies",
        content:
          "We consider a company as new when they trigger the first event of the report setup on a given day and have been identified using a group call.",
      },
      hasInProgress: true,
      hasYAxis: true,
      view: {
        level: "group",
        Component: SimpleAreaChart,
        Components: {
          area: SimpleAreaChart,
          bar: SimpleBarChart,
        },
        transform: (data) => transformData(data, "newGroups"),
        hasTimerangePicker: true,
        dateRangeOptions: DAU_GROUPED_OPTIONS,
      },
    },
    {
      typeId: 9,
      slug: "weekly-new-companies",
      title: "Weekly new {APP_OBJECT_PLURAL_NAME}",
      section: WngContainer,
      slackAlertAvailable: true,
      description: {
        title: "Learn more about weekly new companies",
        content:
          "We consider a company as new when they trigger the first event of the report setup on a given day and have been identified using a group call.",
      },
      hasInProgress: true,
      hasYAxis: true,
      view: {
        level: "group",
        Component: SimpleAreaChart,
        Components: {
          area: SimpleAreaChart,
          bar: SimpleBarChart,
        },
        transform: (data) => transformData(data, "newGroups"),
        hasTimerangePicker: true,
        dateRangeOptions: WAU_GROUPED_OPTIONS,
      },
    },
    {
      typeId: 10,
      slug: "monthly-new-companies",
      title: "Monthly new {APP_OBJECT_PLURAL_NAME}",
      section: MngContainer,
      slackAlertAvailable: true,
      description: {
        title: "Learn more about monthly new companies",
        content:
          "We consider a company as new when they trigger the first event of the report setup on a given day and have been identified using a group call.",
      },
      hasInProgress: true,
      hasYAxis: true,
      view: {
        level: "group",
        Component: SimpleAreaChart,
        Components: {
          area: SimpleAreaChart,
          bar: SimpleBarChart,
        },
        transform: (data) => transformData(data, "newGroups"),
        hasTimerangePicker: true,
        dateRangeOptions: MAU_GROUPED_OPTIONS,
      },
    },
  ],
  setup: {
    requiredSections: [0],
    eventOperator: OR,
    setupSections: [
      {
        id: "sign_up_event",
        configKey: "startingEvent",
        title: "Sign up events",
        description:
          "Select an event users trigger when they sign up for your product.",
        type: SINGLE_EVENT_TYPE,
        supportedEventTypes: [FIRST, TRACK, PAGE],
        required: true,
        validate: (events) =>
          events &&
          events.length > 0 &&
          events.length ===
            events.map((event) => event?.id).filter((x) => x).length,
      },
      {
        id: "audience",
        configKey: "audience",
        title: "Audience",
        description: "Select an audience for your report",
        type: AUDIENCE_TYPE,
        supportedUserTypes: ["user"],
      },
    ],
  },
  validateSetup: (config) => config && config?.startingEvent,
};

export default newUsers;
