import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useBookACallMutation } from "core/models/apps";
import { useUserAuth } from "core/hooks/useUserAuth";
import { useQueryParam } from "core/hooks/useQueryParam";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import NewLogo from "core/design-system/components/Icon/Logos/NewLogo.svg";
import { Button } from "@june-so/ui";
import { Spinner } from "@chakra-ui/react";
import Cal, { getCalApi } from "@calcom/embed-react";

declare global {
  interface Window {
    gtag: (
      command: "event" | "set" | "config",
      action: string,
      params?: any,
    ) => void;
  }
}

export const BookCall: React.FC = () => {
  const { currentUser, isLoading } = useUserAuth();
  const [bookingSuccess, setBookingSuccess] = useState(false);
  const navigate = useNavigate();
  const { id: appId } = useCurrentApp();
  const [bookACall] = useBookACallMutation();
  const bookACallQueryParam = useQueryParam("book-a-call");

  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      window.gtag &&
      currentUser?.email &&
      currentUser?.id &&
      typeof currentUser.id === "string" &&
      typeof currentUser.email === "string"
    ) {
      // Set user properties
      window.gtag("set", "user_properties", {
        user_id: currentUser.id,
        email: currentUser.email,
      });

      // Send the qualified_signup event
      window.gtag("event", "qualified_signup", {
        send_to: "G-BR6STYQ3NY",
      });
    }
  }, [currentUser?.email, currentUser?.id]);

  useEffect(() => {
    (async function () {
      const cal = await getCalApi({});
      cal("ui", {
        theme: "light",
        styles: { branding: { brandColor: "#151531" } },
        hideEventTypeDetails: false,
        layout: "month_view",
      });

      cal("on", {
        action: "bookingSuccessful",
        callback: () => {
          bookACall({ appId, onboarding: bookACallQueryParam !== "true" })
            .unwrap()
            .then(() => {
              setBookingSuccess(true);
            });
        },
      });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return (
      <div className="flex h-screen w-screen items-center justify-center">
        <Spinner />
      </div>
    );
  }

  if (bookingSuccess) {
    return (
      <div className="bg-radial-gradient(circle at center, #7b7bff1c 0, #ffffff 60%) flex min-h-screen w-screen items-center justify-center p-4">
        <div
          className="w-full max-w-[500px] rounded-xl bg-white p-4"
          style={{
            boxShadow:
              "rgb(0 0 0 / 5%) 0px 0px 0px 0px, rgb(0 0 0 / 5%) 0px 2px 4px",
          }}
        >
          <div className="flex flex-col items-center gap-4 p-4">
            <img alt="june logo" src={NewLogo} className="h-10 w-9" />

            <p className="text-md font-semibold">
              You're all set! We're looking forward to meeting you.
            </p>
            <p className="text-center text-sm text-gray-600">
              We'll make sure to get you set up with the most out of June.{" "}
            </p>
            <p className="text-center text-sm text-gray-600">
              <span className="font-semibold">Can't wait to get started? </span>{" "}
              We have a self-serve onboarding that you can go through at your
              own pace.
            </p>
            <p className="text-center text-sm text-gray-600">
              What we recommend is to get a basic setup in place before your
              onboarding call. This is not required, but it will help you get
              the most out of it.
            </p>
            <p className="text-center text-sm text-gray-600">
              If you have any questions, feel free to reach out to us.
            </p>

            <div className="flex items-center gap-2">
              <Button
                color="purple"
                onClick={() => {
                  navigate(`/a/${appId}/choose-setup`);
                }}
              >
                Get started
              </Button>
              <Button
                onClick={() => {
                  window.Intercom("showNewMessage");
                }}
              >
                Chat with us
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-radial-gradient(circle at center, #7b7bff1c 0, #ffffff 60%) flex min-h-screen w-screen items-center justify-center p-4">
      <div
        className="w-full max-w-[900px] rounded-xl bg-white p-4"
        style={{
          boxShadow:
            "rgb(0 0 0 / 5%) 0px 0px 0px 0px, rgb(0 0 0 / 5%) 0px 2px 4px",
        }}
      >
        <div className="flex flex-col items-center gap-2 p-4">
          <img alt="june logo" src={NewLogo} className="h-10 w-9" />

          <p className="text-md font-semibold">
            {bookACallQueryParam === "true"
              ? "Book a call"
              : "Schedule your onboarding"}
          </p>
          <p className="mb-4 text-center text-sm text-gray-600">
            {bookACallQueryParam === "true"
              ? "Book a call with us to get you setup for success"
              : "Book a call with us to get started. We'll help you set up your workspace."}
          </p>
          <Cal
            calLink={
              bookACallQueryParam === "true"
                ? "alberto-incisa/book-a-call"
                : "alberto-incisa/onboarding"
            }
            className="h-full w-full"
            style={{ width: "100%", height: "100%", overflow: "scroll" }}
            config={{
              layout: "month_view",
              name: currentUser?.firstName || "",
              email: currentUser?.email || "",
              location: {
                value: "zoom",
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};
