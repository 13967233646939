import { simplifyXAxisTicks } from "helpers/xAxis";
import { Size } from "core/types/ViewInsight";
import { uuid } from "core/helpers/uuid";
import colors, {
  IColorPalette,
} from "core/design-system/constants/theme/colors";
import { TickText } from "core/design-system/charts/TickText";
import {
  CommonCustomTooltip,
  CommonGrid,
  CommonTooltip,
  CommonXAxis,
  CommonYAxis,
} from "core/design-system/charts/Common";
import { Area, AreaChart } from "core/design-system/charts/AreaChart";
import { useState } from "react";

interface ISimpleAreaChartProps {
  data: any[];
  tooltipProps?: any;
  TooltipComponent?: React.FC<any>;
  xDataKey?: string;
  yDataKey?: string;
  showTicks?: boolean;
  margin?: { right: number; top: number; bottom: number; left: number };
  color?: IColorPalette;
  padding?: { left: number };
  size?: Size;
  useMockData?: boolean;
}

const MOCK_DATA = [
  {
    x: "2024-01-01",
    y: 100,
  },
  {
    x: "2024-01-02",
    y: 200,
  },
  {
    x: "2024-01-03",
    y: 300,
  },
  {
    x: "2024-01-04",
    y: 600,
  },
  {
    x: "2024-01-05",
    y: 1200,
  },
  {
    x: "2024-01-06",
    y: 2400,
  },
  {
    x: "2024-01-07",
    y: 4800,
  },
];

export const SimpleAreaChart: React.FC<ISimpleAreaChartProps> = ({
  TooltipComponent = CommonCustomTooltip,
  data,
  tooltipProps,
  xDataKey = "x",
  yDataKey = "y",
  showTicks = true,
  margin = { right: 0, top: 30, bottom: 0, left: -90 },
  color = colors.purple,
  padding = { left: 50 },
  size = Size.Small,
  useMockData = false,
}) => {
  const chartData = useMockData ? MOCK_DATA : data;
  const [areaId, setAreaId] = useState<string>("");

  const id = uuid();
  const { firstTick, lastTick } = simplifyXAxisTicks(chartData, "x");
  const allTicks = chartData?.map((item) => item[xDataKey]);

  return (
    <AreaChart
      margin={margin}
      padding={{ left: 150 }}
      data={chartData || []}
      id="loaded"
    >
      <defs>
        <linearGradient id={id} x1="0" y1="0" x2="0" y2="1">
          <stop offset="5%" stopColor={color[200]} stopOpacity={0.8} />
          <stop offset="95%" stopColor={color[50]} stopOpacity={0.4} />
        </linearGradient>
      </defs>
      <CommonGrid horizontal={false} />
      {/* @ts-ignore */}
      <CommonYAxis tick={false} />
      {/* @ts-ignore */}
      <CommonXAxis
        padding={padding}
        width={100}
        tickCount={2}
        interval="preserveStartEnd"
        ticks={
          showTicks
            ? [Size.Medium, Size.Large].includes(size)
              ? allTicks
              : [firstTick, lastTick]
            : []
        }
        dataKey={xDataKey}
        tickMargin={5}
        tick={({ x, y, ...props }: any) => {
          const { index } = props;
          const { value } = props?.payload;
          return (
            <TickText {...props} y={y + 15} x={index === 0 ? x + 10 : x + 5}>
              {value}
            </TickText>
          );
        }}
      />
      {/* @ts-ignore */}
      <CommonTooltip content={<TooltipComponent {...tooltipProps} />} />
      <Area
        id={areaId}
        strokeWidth={2}
        stroke={color[200]}
        fill={`url(#${id})`}
        dot={false}
        activeDot={false}
        dataKey={yDataKey}
        onAnimationEnd={() => setAreaId("loaded")}
      />
    </AreaChart>
  );
};
