import { useState } from "react";
import { cx } from "helpers/cx";
import { FavouriteObjectType } from "core/models/favourites";
import { useNavigation } from "core/hooks/useNavigation";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { Views } from "./Views";
import { ItemText } from "./ItemText";
import { ItemContainer } from "./ItemContainer";
import { CurrentSection } from "./index";
import { ExpandedHeader } from "./Header/ExpandedHeader";
import { Footer } from "./Footer";
import { Favourites } from "./Favourites";
import { getCurrentMenuItem, MenuItemType } from "./BaseSidebar";
import { Audiences } from "./Audiences";

export interface IDynamicExpandedSidebarProps {
  currentSection: CurrentSection;
}

export const DynamicExpandedSidebar: React.FC<IDynamicExpandedSidebarProps> = ({
  currentSection,
}) => {
  const { id: appId } = useCurrentApp();
  const { width } = useNavigation();
  const [showCollapseIcon, setShowCollapseIcon] = useState(false);
  const currentBaseSidebarItem = getCurrentMenuItem();

  return (
    <div
      onMouseEnter={() => setShowCollapseIcon(true)}
      onMouseLeave={() => setShowCollapseIcon(false)}
      className={cx(
        "flex h-full min-h-screen flex-col justify-between  overflow-auto bg-gray-50",
        width && `w-[${width}]`,
      )}
    >
      <div className="flex h-full w-full flex-col gap-1 overflow-auto">
        <ExpandedHeader
          showCollapseIcon={showCollapseIcon}
          showNewReportButton={
            currentBaseSidebarItem?.type === MenuItemType.Analytics
          }
          showSettings={false}
          showDevelopers={false}
          showBilling={false}
          showManageTeam={false}
        />

        {currentBaseSidebarItem?.type === MenuItemType.Analytics && (
          <ItemContainer
            isCurrent={currentSection === "Reports"}
            to={`/a/${appId}/reports`}
          >
            <ItemText>Reports</ItemText>
          </ItemContainer>
        )}

        {currentBaseSidebarItem?.type === MenuItemType.Analytics && (
          <ItemContainer
            isCurrent={currentSection === "Events"}
            to={`/a/${appId}/events`}
          >
            <ItemText>Events</ItemText>
          </ItemContainer>
        )}

        {currentBaseSidebarItem?.type === MenuItemType.Analytics && (
          <>
            <div className="mt-3" />
            <Views />
          </>
        )}

        {currentBaseSidebarItem?.type === MenuItemType.People && (
          <>
            <p className="px-3 text-xs font-medium text-gray-600">Audiences</p>
            <Audiences />
          </>
        )}

        {currentBaseSidebarItem?.type === MenuItemType.Integrations && (
          <ItemContainer
            isCurrent={window.location.href.includes(`/computed-traits`)}
            to={`/a/${appId}/integrations/computed-traits`}
            className="group relative flex justify-between px-2"
          >
            <div className="-ml-1 flex items-center">
              <div className="ml-0.5 line-clamp-1 max-w-[210px]">
                <ItemText className="max-w-[210px] text-ellipsis">
                  Computed Traits
                </ItemText>
              </div>
            </div>
          </ItemContainer>
        )}

        {currentBaseSidebarItem?.type === MenuItemType.Integrations && (
          <>
            <ItemContainer
              isCurrent={window.location.href.includes(`/hubspot`)}
              to={`/a/${appId}/integrations/hubspot`}
              className="group relative flex justify-between px-2"
            >
              <div className="-ml-1 flex items-center">
                <div className="ml-0.5 line-clamp-1 max-w-[210px]">
                  <ItemText className="max-w-[210px] text-ellipsis">
                    HubSpot
                  </ItemText>
                </div>
              </div>
            </ItemContainer>
          </>
        )}
        {currentBaseSidebarItem?.type === MenuItemType.Integrations && (
          <ItemContainer
            isCurrent={window.location.href.includes(`/attio`)}
            to={`/a/${appId}/integrations/attio`}
            className="group relative flex justify-between px-2"
          >
            <div className="-ml-1 flex items-center">
              <div className="ml-0.5 line-clamp-1 max-w-[210px]">
                <ItemText className="max-w-[210px] text-ellipsis">
                  Attio
                </ItemText>
              </div>
            </div>
          </ItemContainer>
        )}
        {currentBaseSidebarItem?.type === MenuItemType.Integrations && (
          <ItemContainer
            isCurrent={window.location.href.includes(`/salesforce`)}
            to={`/a/${appId}/integrations/salesforce`}
            className="group relative flex justify-between px-2"
            isNew={true}
          >
            <div className="-ml-1 flex items-center">
              <div className="ml-0.5 line-clamp-1 max-w-[210px]">
                <ItemText className="max-w-[210px] text-ellipsis">
                  Salesforce
                </ItemText>
              </div>
            </div>
          </ItemContainer>
        )}

        {currentBaseSidebarItem?.type === MenuItemType.Integrations && (
          <ItemContainer
            isCurrent={window.location.href.includes(`/email`)}
            to={`/a/${appId}/integrations/email`}
            className="group relative flex justify-between px-2"
          >
            <div className="-ml-1 flex items-center">
              <div className="ml-0.5 line-clamp-1 max-w-[210px]">
                <ItemText className="max-w-[210px] text-ellipsis">
                  Email
                </ItemText>
              </div>
            </div>
          </ItemContainer>
        )}

        {currentBaseSidebarItem?.type === MenuItemType.Integrations && (
          <ItemContainer
            isCurrent={window.location.href.includes(`/slack`)}
            to={`/a/${appId}/integrations/slack`}
            className="group relative flex justify-between px-2"
          >
            <div className="-ml-1 flex items-center">
              <div className="ml-0.5 line-clamp-1 max-w-[210px]">
                <ItemText className="max-w-[210px] text-ellipsis">
                  Slack
                </ItemText>
              </div>
            </div>
          </ItemContainer>
        )}

        {currentBaseSidebarItem?.type === MenuItemType.Integrations && (
          <ItemContainer
            isCurrent={window.location.href.includes(`/api`)}
            to={`/a/${appId}/integrations/api`}
            className="group relative flex justify-between px-2"
          >
            <div className="-ml-1 flex items-center">
              <div className="ml-0.5 line-clamp-1 max-w-[210px]">
                <ItemText className="max-w-[210px] text-ellipsis">API</ItemText>
              </div>
            </div>
          </ItemContainer>
        )}

        <div className="mt-3" />
        {[MenuItemType.People, MenuItemType.Analytics].includes(
          currentBaseSidebarItem?.type,
        ) && (
          <Favourites
            objectTypes={
              currentBaseSidebarItem?.type === MenuItemType.People
                ? [
                    FavouriteObjectType.Audience,
                    FavouriteObjectType.Group,
                    FavouriteObjectType.Company,
                    FavouriteObjectType.User,
                  ]
                : [FavouriteObjectType.View, FavouriteObjectType.Report]
            }
          />
        )}
      </div>
      <Footer />
    </div>
  );
};
