import { useParams } from "react-router-dom";
import { useState } from "react";
import { GroupContextBanner } from "modules/Events/GroupContextBanner";
import { Properties } from "modules/Event/Properties";
import { Header } from "modules/Event/Header";
import { Activity } from "modules/Event/Activity";
import { useGetEventQuery } from "core/models/events";
import { Breadcrumbs } from "core/design-system/components/Breadcrumbs";
import { PageContainer } from "core/components/PageContainer";
import { Box, Flex, Skeleton } from "@chakra-ui/react";
import { Tab } from "../core/components/Tabs/Tab";

const TABS = [
  { label: "Activity", value: "activity" },
  { label: "Properties", value: "properties" },
];

const Event: React.FC = () => {
  const { appId, eventId } = useParams<{ appId: string; eventId: string }>();
  const [activeTab, setActiveTab] = useState(TABS[0].value);

  const {
    data: event,
    isLoading,
    refetch: mutate,
  } = useGetEventQuery({ appId: Number(appId), eventId: Number(eventId) });

  if (event) {
    document.title = `June - ${event?.name}`;
  }

  if (isLoading || !event)
    return (
      <Flex direction="column" justify="flex-start" align="flex-start">
        <Skeleton />
      </Flex>
    );

  if (!event.name) return <Box>This event does not exist :(</Box>;

  return (
    <PageContainer minW="container.lg" maxW="full">
      <div className="flex flex-col gap-2">
        <Breadcrumbs
          pages={[
            { name: "Events", path: `/a/${appId}/events`, current: false },
            { name: event.name, current: true, path: "" },
          ]}
        />
        <Header event={event} mutate={mutate} />
      </div>
      <Flex mt={8}>
        {TABS.map((tab) => (
          <Tab
            isSelected={tab.value === activeTab}
            onTabChange={() => setActiveTab(tab.value)}
          >
            {tab.label}
          </Tab>
        ))}
      </Flex>
      {event?.id && (
        <Flex w="full" mt={5}>
          {activeTab === "activity" ? (
            <Activity event={event} />
          ) : (
            <Properties event={event} />
          )}
        </Flex>
      )}
      {event && !event.isReceivingGroupContext ? <GroupContextBanner /> : null}
    </PageContainer>
  );
};

export default Event;
