import { useState } from "react";
import { TraitOptionsList } from "core/modules/audience/Filters/TraitOptionsList";
import { TraitLevel } from "core/modules/audience/Filters/TraitMenu";
import { ITraitKeyResponse } from "core/models/traits";
import { useAppObjects } from "core/hooks/useAppObjects";
import {
  Command,
  CommandInput,
  CommandList,
} from "core/design-system/components/Command";
import { DIVIDER, FilterType } from "core/constants/filters";

interface TraitListProps {
  traits: ITraitKeyResponse[];
  onSelectTrait: (trait: ITraitKeyResponse) => void;
  level: TraitLevel;
}

export const TraitList: React.FC<TraitListProps> = ({
  traits,
  onSelectTrait,
  level,
}) => {
  const [searchTrait, setSearchTrait] = useState<string>("");

  const { userAppObject, groupAppObject, companyAppObject } = useAppObjects();
  const filterTypeNames: { [x: number]: string } = {
    [FilterType.UserTrait]: userAppObject?.singularName || "User",
    [FilterType.GroupFilter]: groupAppObject?.singularName || "Group",
    [FilterType.CompanyFilter]: companyAppObject?.singularName || "Company",
  };

  const userTraitDivider = {
    trait: "User traits",
    filterType: DIVIDER,
    valueCount: 0,
  };
  const companyTraitDivider = {
    trait: "Company traits",
    filterType: DIVIDER,
    valueCount: 0,
  };
  const groupTraitDivider = {
    trait: `${filterTypeNames[FilterType.GroupFilter]} traits`,
    filterType: DIVIDER,
    valueCount: 0,
  };
  const divider =
    level === TraitLevel.USER_LEVEL
      ? userTraitDivider
      : level === TraitLevel.COMPANY_LEVEL
        ? companyTraitDivider
        : groupTraitDivider;

  return (
    <Command className="max-h-90 w-full min-w-[200px] max-w-[250px]">
      <CommandInput
        placeholder="Search filter..."
        value={searchTrait}
        onChange={(e) => {
          setSearchTrait(e.target.value);
        }}
      />
      <CommandList data-testid={`audience-trait-options`}>
        <TraitOptionsList
          traits={[divider, ...traits]}
          searchTrait={searchTrait}
          onAddTraitFilter={() => {}}
          onAddEventFilter={() => {}}
          isLoading={false}
          filterTypeNames={[]}
          isEventFilterDisabled={true}
          onSelectTrait={onSelectTrait}
        />
      </CommandList>
    </Command>
  );
};
