import { cx } from "helpers/cx";
import { ComputationType } from "core/constants/traits";
import {
  ArrowTrendingUpIcon,
  AtSymbolIcon,
  BanknotesIcon,
  BuildingOfficeIcon,
  CalendarDaysIcon,
  ClockIcon,
  GlobeAltIcon,
  HashtagIcon,
  IdentificationIcon,
  InformationCircleIcon,
  PercentBadgeIcon,
  UserCircleIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";

export const TraitIcon: React.FC<{
  traitName: string;
  computationType?: ComputationType;
  size?: number;
  color?: string;
}> = ({ traitName, computationType, size, color = "text-black" }) => {
  if (computationType === ComputationType.UserCount) {
    return <UsersIcon className={cx(size ? `h-${size}` : "h-4", color)} />;
  }
  if (computationType === ComputationType.EventCount) {
    return <HashtagIcon className={cx(size ? `h-${size}` : "h-4", color)} />;
  }
  if (computationType === ComputationType.Ratio) {
    return (
      <PercentBadgeIcon className={cx(size ? `h-${size}` : "h-4", color)} />
    );
  }
  if (computationType === ComputationType.DayCount) {
    return (
      <CalendarDaysIcon className={cx(size ? `h-${size}` : "h-4", color)} />
    );
  }
  if (computationType === ComputationType.EventCountChange) {
    return (
      <ArrowTrendingUpIcon className={cx(size ? `h-${size}` : "h-4", color)} />
    );
  }
  if (
    computationType === ComputationType.LastSeen ||
    computationType === ComputationType.FirstSeen ||
    traitName.toLowerCase().includes("seen")
  ) {
    return <ClockIcon className={cx(size ? `h-${size}` : "h-4", color)} />;
  }

  if (traitName.toLowerCase().includes("avatar"))
    return <UserCircleIcon className={cx(size ? `h-${size}` : "h-4", color)} />;
  if (
    traitName.toLowerCase().includes("company") ||
    traitName.toLowerCase().includes("business") ||
    traitName.toLowerCase().includes("industry")
  )
    return (
      <BuildingOfficeIcon className={cx(size ? `h-${size}` : "h-4", color)} />
    );
  if (traitName.toLowerCase().includes("created"))
    return <ClockIcon className={cx(size ? `h-${size}` : "h-4", color)} />;
  if (
    traitName.toLowerCase().includes("name") ||
    traitName.toLowerCase().includes("role") ||
    traitName.toLowerCase().includes("profession") ||
    traitName.toLowerCase().includes("june_profile_url")
  )
    return (
      <IdentificationIcon className={cx(size ? `h-${size}` : "h-4", color)} />
    );
  if (traitName.toLowerCase().includes("email"))
    return <AtSymbolIcon className={cx(size ? `h-${size}` : "h-4", color)} />;
  if (
    traitName.toLowerCase().includes("subscription") ||
    traitName.toLowerCase().includes("paying") ||
    traitName.toLowerCase().includes("plan")
  )
    return <BanknotesIcon className={cx(size ? `h-${size}` : "h-4", color)} />;
  if (traitName.toLowerCase().includes("domain"))
    return <GlobeAltIcon className={cx(size ? `h-${size}` : "h-4", color)} />;
  if (
    traitName.toLowerCase().includes("size") ||
    traitName.toLowerCase().includes("count")
  )
    return <HashtagIcon className={cx(size ? `h-${size}` : "h-4", color)} />;

  return (
    <InformationCircleIcon className={cx(size ? `h-${size}` : "h-4", color)} />
  );
};
