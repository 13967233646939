import { Button, Tooltip } from "@chakra-ui/react";
import { ItemContainer } from "./ItemContainer";
import { CloseIcon } from "@chakra-ui/icons";
import { ItemText } from "./ItemText";
import { EmojiPicker } from "core/components/EmojiPicker";
import { IView } from "core/models/views";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

export function SortableView({
  viewId,
  view,
  onUnpin,
  onEmojiClick,
}: {
  viewId: string;
  view: IView;
  onUnpin: (view: IView) => void;
  onEmojiClick: (emojiObject: { emoji: string }, viewId: number) => void;
}) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: view?.id,
    transition: {
      duration: 300,
      easing: "cubic-bezier(0.51, 0.92, 0.24, 1.15)",
    },
    animateLayoutChanges: () => false,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: isDragging ? "grabbing" : "grab",
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <ItemContainer
        key={view.id}
        isCurrent={viewId === String(view.id)}
        to={`/a/${view.appId}/home/${view.id}`}
        className="group relative flex justify-between"
      >
        <div className="-ml-1 flex items-center">
          <EmojiPicker
            emoji={view.emoji ? view.emoji : "🏠"}
            handleEmojiClick={(e) => onEmojiClick(e, Number(view.id))}
          />
          <div className="ml-0.5 line-clamp-1 max-w-[210px]">
            {view.name ? (
              <ItemText className="max-w-[210px] text-ellipsis">
                {view.name}
              </ItemText>
            ) : (
              <ItemText className="text-opacity-60">Untitled view</ItemText>
            )}
          </div>
        </div>
        <div className="absolute right-0 hidden group-hover:block">
          <Tooltip
            label="Unpin view"
            placement="right"
            fontSize="xs"
            bg="white"
            border="1px solid"
            borderColor="gray.200"
            textColor="gray.700"
            shadow="none"
          >
            <Button
              _hover={{ bg: "gray.300" }}
              size="xs"
              variant="ghost"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                onUnpin(view);
              }}
              className="mr-0.5 hover:bg-gray-100 hover:text-black"
            >
              <CloseIcon w={2.5} h={2.5} />
            </Button>
          </Tooltip>
        </div>
      </ItemContainer>
    </div>
  );
}
