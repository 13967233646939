import { ComputedTraitEditorContainer } from "modules/People/ComputedTraitEditorContainer";
import { ITableTraitConfig } from "core/types/TableTraitConfig";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import {
  Command,
  CommandList,
  CommandOption,
} from "core/design-system/components/Command";
import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
  BarsArrowDownIcon,
  BarsArrowUpIcon,
  BoltIcon as BoltOutlineIcon,
  EyeSlashIcon,
  PencilIcon,
} from "@heroicons/react/24/outline";
import {
  Divider,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { useState } from "react";
import {
  OBJECT_TYPE_TO_TRAIT_LEVEL,
  TraitEditModal,
} from "modules/Settings/Objects/Object/TraitEditModal";
import {
  DataTypeString,
  ITraitKeyResponse,
  useGetTraitQuery,
} from "core/models/traits";
import { useParams } from "react-router-dom";

export const HeaderMenu: React.FC<{
  traitConfig: ITableTraitConfig;
  deleteTraitConfig: ({ id }: { id: string }) => void;
  moveColumnLeft: (columnId: string) => void;
  moveColumnRight: (columnId: string) => void;
  isFirst: boolean;
  isLast: boolean;
  sortBy: string;
  sortOrder: string;
  setSortBy: (sortBy: string) => void;
  setSortOrder: (sortOrder: string) => void;
}> = ({
  traitConfig,
  deleteTraitConfig,
  moveColumnLeft,
  moveColumnRight,
  isFirst,
  isLast,
  sortBy,
  sortOrder,
  setSortBy,
  setSortOrder,
}) => {
  const { appId } = useParams();
  const traitName = traitConfig.trait;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedTrait, setSelectedTrait] = useState<ITableTraitConfig | null>(
    null,
  );
  const currentApp = useCurrentApp();
  const { data: traitData } = useGetTraitQuery({
    appId: Number(appId),
    trait: traitName,
    level: OBJECT_TYPE_TO_TRAIT_LEVEL[traitConfig.appObject.objectType],
  });

  const isEditable = traitConfig.computedTrait?.isEditable;
  const isComputed = traitConfig.computedTrait?.isComputed;

  return (
    <>
      <Command>
        <CommandList>
          <Tooltip
            shouldWrapChildren
            label={
              !currentApp.sortingEnabled
                ? "Sorting is not currently available for your workspace as you have more than 100k contacts"
                : traitData?.dataType === DataTypeString.UNASSIGNED
                  ? "Please set the data type of the trait to enable sorting"
                  : ""
            }
            placement="right"
            hasArrow
          >
            <CommandOption
              value="sort-asc"
              onClick={() => {
                setSortBy(`"${traitName}"`);
                setSortOrder("ASC");
              }}
              disabled={
                traitData?.dataType === DataTypeString.UNASSIGNED ||
                !currentApp.sortingEnabled
              }
            >
              <div className="flex w-full items-center justify-between">
                <div className="flex w-full items-center gap-1">
                  <BarsArrowUpIcon className="h-4 w-4" />
                  <p>Sort ascending</p>
                </div>
                {`"${traitName}"` === sortBy && sortOrder === "ASC" && (
                  <div className="h-2 w-2 rounded-full bg-purple-500" />
                )}
              </div>
            </CommandOption>
          </Tooltip>
          <Tooltip
            shouldWrapChildren
            label={
              !currentApp.sortingEnabled
                ? "Sorting is not currently available for your workspace as you have more than 100k contacts"
                : traitData?.dataType === DataTypeString.UNASSIGNED
                  ? "Please set the data type of the trait to enable sorting"
                  : ""
            }
            placement="right"
            hasArrow
          >
            <CommandOption
              value="sort-desc"
              onClick={() => {
                setSortBy(`"${traitName}"`);
                setSortOrder("DESC");
              }}
              disabled={
                traitData?.dataType === DataTypeString.UNASSIGNED ||
                !currentApp.sortingEnabled
              }
            >
              <div className="flex w-full items-center justify-between">
                <div className="flex w-full items-center gap-1">
                  <BarsArrowDownIcon className="h-4 w-4" />
                  <p>Sort descending</p>
                </div>
                {`"${traitName}"` === sortBy && sortOrder === "DESC" && (
                  <div className="h-2 w-2 rounded-full bg-purple-500" />
                )}
              </div>
            </CommandOption>
          </Tooltip>
          <CommandOption
            value="move-left"
            onClick={() => moveColumnLeft(traitConfig.trait)}
            disabled={isFirst}
          >
            <div className="flex items-center gap-1">
              <ArrowLongLeftIcon className="h-4 w-4" />
              <p>Move left</p>
            </div>
          </CommandOption>
          <CommandOption
            value="move-right"
            onClick={() => moveColumnRight(traitConfig.trait)}
            disabled={isLast}
          >
            <div className="flex items-center gap-1">
              <ArrowLongRightIcon className="h-4 w-4" />
              <p>Move right</p>
            </div>
          </CommandOption>
          <Divider my={1} />
          <CommandOption
            value="hide-column"
            onClick={() => deleteTraitConfig({ id: String(traitConfig.id) })}
          >
            <div className="flex items-center gap-1">
              <EyeSlashIcon className="h-4 w-4" />
              <p>Hide column</p>
            </div>
          </CommandOption>
          <Tooltip
            shouldWrapChildren
            label={
              isComputed && !isEditable
                ? "This computed trait is read only"
                : ""
            }
          >
            <CommandOption
              value="edit-computed-trait"
              disabled={isComputed && !isEditable}
              onClick={() => {
                traitConfig.computedTrait?.isComputed
                  ? onOpen()
                  : setSelectedTrait(traitConfig);
              }}
            >
              <div className="flex items-center gap-1">
                {traitConfig.computedTrait?.isComputed ? (
                  <BoltOutlineIcon className="h-4 w-4" />
                ) : (
                  <PencilIcon className="h-4 w-4" />
                )}
                <p>
                  Edit {traitConfig.computedTrait?.isComputed ? `computed` : ""}{" "}
                  trait
                </p>
              </div>
            </CommandOption>
          </Tooltip>
        </CommandList>
      </Command>
      {isOpen && (
        <Drawer size="md" isOpen={isOpen} placement="right" onClose={onClose}>
          <DrawerOverlay />
          <DrawerContent>
            <ComputedTraitEditorContainer
              traitId={traitConfig?.computedTrait?.id}
              onClose={onClose}
            />
          </DrawerContent>
        </Drawer>
      )}
      {selectedTrait && (
        <TraitEditModal
          trait={{ ...selectedTrait } as unknown as ITraitKeyResponse}
          isOpen={selectedTrait?.trait === traitConfig.trait}
          onClose={() => setSelectedTrait(null)}
          appObject={traitConfig.appObject}
        />
      )}
    </>
  );
};
