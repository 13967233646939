import { LoadMoreButton } from "core/design-system/components/LoadMoreButton";
import { Center, Flex, Spinner, Text, VStack } from "@chakra-ui/react";

interface IDrilldownListProps {
  items: any[]; // array of user,company ids or user or object
  hasMore: boolean;
  isLoading: boolean;
  loadNext: () => void;
  itemRenderer: ({
    item,
    index,
  }: {
    item: any;
    index: number;
  }) => React.ReactNode;
  emptyStateText: string;
}

export const DrilldownList: React.FC<IDrilldownListProps> = ({
  items,
  hasMore,
  isLoading,
  loadNext,
  itemRenderer,
  emptyStateText,
}) => {
  if (isLoading) {
    return (
      <Center h="full">
        <Spinner />
      </Center>
    );
  }

  return (
    <>
      {items?.length === 0 && (
        <VStack h="full" align="center" justify="center">
          <Text
            color="gray.600"
            align="center"
            fontSize="lg"
            fontWeight="semibold"
          >
            {emptyStateText}
          </Text>
        </VStack>
      )}
      {items?.map((item: any, index: number) => itemRenderer({ item, index }))}
      {hasMore && (
        <Flex my={2} justify="center">
          <LoadMoreButton isLoading={isLoading} onClick={loadNext} />
        </Flex>
      )}
    </>
  );
};
