import { useParams } from "react-router-dom";
import { IAudience } from "core/types/Audience";
import { useGetAudienceQuery } from "core/models/audiences";

interface IUseAudience {
  audienceId: number;
}
interface IUseAudienceReturn {
  audience: IAudience | undefined;
  isLoading: boolean;
  isFetching: boolean;
}

export const useAudience = ({
  audienceId,
}: IUseAudience): IUseAudienceReturn => {
  const { appId } = useParams<{
    appId: string;
  }>() as {
    appId: string;
  };
  const {
    data: audience,
    isLoading,
    isFetching,
  } = useGetAudienceQuery({
    appId: Number(appId),
    audienceId: Number(audienceId),
  });

  return { audience, isLoading, isFetching };
};
