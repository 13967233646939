import { IViewInsight, useGetViewInsightQuery } from "core/models/viewInsights";
import { AudienceModal } from "./AudienceModal";

export const AudienceModalContainer: React.FC<{
  viewInsight: IViewInsight;
  onClose: () => void;
  isOpen: boolean;
}> = ({ viewInsight, onClose, isOpen }) => {
  const { data, isLoading } = useGetViewInsightQuery({
    id: viewInsight.id,
    viewId: viewInsight.viewId,
    appId: viewInsight.appId,
  });

  if (!data) return null;
  if (isLoading) return null;
  if (!viewInsight) return null;

  return <AudienceModal viewInsight={data} onClose={onClose} isOpen={isOpen} />;
};
