import { AddMenu } from "modules/Views/AddMenu";
import { IView } from "core/models/views";
import { useEditDashboard } from "core/hooks/useEditDashboard";
import { Shareable } from "core/components/Shareable";
import {
  AdjustmentsVerticalIcon,
  Squares2X2Icon,
} from "@heroicons/react/24/outline";
import { Badge, Button } from "@chakra-ui/react";
import { useIsEditingView } from "core/contexts/EditingViewContext";
import { IAudienceFilters } from "core/types/Audience";
import { ViewMenu } from "./Menu";
import { getFilterCount } from "routes/View";

export const ViewHeader: React.FC<{
  view?: IView;
  showAddModal: boolean;
  setShowAddModal: (show: boolean) => void;
  showSQLModal: boolean;
  setShowSQLModal: (show: boolean) => void;
  audienceFilters: IAudienceFilters;
  showAudienceFilters: boolean;
  setShowAudienceFilters: (show: boolean) => void;
  showAudienceModal: boolean;
  setShowAudienceModal: (show: boolean) => void;
}> = ({
  view,
  showAddModal,
  setShowAddModal,
  showSQLModal,
  setShowSQLModal,
  audienceFilters,
  showAudienceFilters,
  setShowAudienceFilters,
  showAudienceModal,
  setShowAudienceModal,
}) => {
  const isEditing = useIsEditingView();
  const { toggleEditDashboard } = useEditDashboard();
  return (
    <div className="flex items-center gap-1">
      <AddMenu
        onAddInsightClick={() => {
          setShowAddModal(!showAddModal);
          toggleEditDashboard();
        }}
        onAddSQLClick={() => {
          setShowSQLModal(!showSQLModal);
          toggleEditDashboard();
        }}
        onAddAudienceClick={() => {
          setShowAudienceModal(!showAudienceModal);
          toggleEditDashboard();
        }}
      />

      <Button
        key={isEditing ? "edit" : "done"}
        variant={isEditing ? "solid" : "ghost"}
        colorScheme={isEditing ? "purple" : "gray"}
        onClick={toggleEditDashboard}
        leftIcon={<Squares2X2Icon className="h-4 w-4" />}
      >
        {isEditing ? "Done" : "Edit"}
      </Button>
      {view && <Shareable shareable={view} shareableType="View" />}
      <Button
        variant="ghost"
        leftIcon={<AdjustmentsVerticalIcon className="h-[17px] w-[17px]" />}
        onClick={() => setShowAudienceFilters(!showAudienceFilters)}
      >
        {showAudienceFilters
          ? "Hide audience filters"
          : "Show audience filters"}{" "}
        <Badge
          ml={2}
          variant="subtle"
          colorScheme="purple"
          rounded="md"
          fontSize="xs"
        >
          {getFilterCount(audienceFilters)}
        </Badge>
      </Button>
      {view && <ViewMenu view={view} />}
    </div>
  );
};
