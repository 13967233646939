import { ArrowDownIcon } from "@heroicons/react/24/solid";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

export const FeatureTableDemo: React.FC = () => {
  const totalUsers = 10; // Assuming total users is 10 based on context
  const demoFeatures = [
    {
      name: "Opened company page",
      users: 3,
      retention: "95%",
      usage: 156,
    },
    {
      name: "Opened audience page",
      users: 2,
      retention: "89%",
      usage: 87,
    },
    {
      name: "Ran NPS Survey",
      users: 2,
      retention: "82%",
      usage: 79,
    },
    {
      name: "Created a dashboard",
      users: 1,
      retention: "78%",
      usage: 45,
    },
    {
      name: "Created an alert",
      users: 1,
      retention: "71%",
      usage: 24,
    },
  ];

  return (
    <Card shadow="none" border="1px solid" borderColor="gray.200" h="full">
      <CardHeader>
        <Flex justify="space-between" align="center">
          <Flex direction="column">
            <Text fontSize="md" fontWeight="semibold">
              Feature usage
            </Text>
          </Flex>
          <Button variant="ghost" size="xs">
            View all
          </Button>
        </Flex>
      </CardHeader>
      <CardBody>
        <Table variant="simple" size="sm">
          <Thead>
            <Tr>
              <Th fontSize="xs">Name</Th>
              <Th isNumeric fontSize="xs">
                <Flex align="center" justify="flex-end" gap={1}>
                  Users
                  <ArrowDownIcon className="h-2.5 w-2.5" />
                </Flex>
              </Th>
              <Th isNumeric fontSize="xs">
                Retention
              </Th>
              <Th isNumeric fontSize="xs">
                Usage
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {demoFeatures.map((feature) => (
              <Tr key={feature.name}>
                <Td fontWeight="medium" fontSize="xs">
                  {feature.name}
                </Td>
                <Td isNumeric fontWeight="semibold" fontSize="xs">
                  {feature.users}/{totalUsers}
                </Td>
                <Td isNumeric fontSize="xs">
                  {feature.retention}
                </Td>
                <Td isNumeric fontSize="xs">
                  {feature.usage}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </CardBody>
    </Card>
  );
};
