import moment from "moment";
import { Frequency } from "modules/SlackInsightEditor/Frequency";
import { IAlert } from "core/types/Alert";
import { useSlack } from "core/hooks/useSlack";
import CompanyJuneLogoIcon from "core/design-system/components/Icon/Logos/CompanyJuneLogoIcon";
import SlackChannels from "core/components/Slack/SlackChannels";
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
} from "@chakra-ui/react";
import { IViewInsight } from "core/models/viewInsights";
import { useEditSlackViewInsightAlert } from "core/hooks/useEditSlackViewInsightAlert";
import { ViewInsightContainer } from "modules/ViewInsight/ViewInsightContainer";
import { ViewLocation } from "core/hooks/useViews";
import { AIViewInsightContainer } from "modules/ViewInsight/AIViewInsightContainer";
import { cx } from "helpers/cx";
import { GRID_TO_PX } from "modules/ViewInsight/Builder/LayoutContainer";
import { AudienceViewInsightContainer } from "modules/ViewInsight/AudienceViewInsightContainer";

interface ISlackViewInsightEditorProps {
  viewInsight: IViewInsight;
  onClose: () => void;
  isOpen: boolean;
  isLoading: boolean;
  alert?: IAlert;
}

export const SlackViewInsightEditor: React.FC<ISlackViewInsightEditorProps> = ({
  isOpen,
  onClose,
  alert,
  isLoading,
  viewInsight,
}) => {
  const { hasSlackAppInstalled } = useSlack();
  const {
    state,
    alertChanges,
    setAlertChanges,
    hasChanges,
    onCancel,
    onConnectSlack,
    onSave,
    onEnable,
    onUpdate,
    onPause,
  } = useEditSlackViewInsightAlert({ viewInsight, alert });

  return (
    <Drawer trapFocus={false} size="xl" isOpen={isOpen} onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader bg="gray.100" mb={4}>
          <div className="flex items-center justify-between">
            <p className="text-lg">
              Setup Slack alert for{" "}
              <span className="ml-1 text-purple-500">{viewInsight.title}</span>
            </p>
            <div className="flex gap-2">
              <Button
                variant="ghost"
                colorScheme="purple"
                isDisabled={!hasChanges}
                onClick={() => onCancel(onClose)}
              >
                Cancel
              </Button>
              <div>
                {!hasSlackAppInstalled ? (
                  <Button
                    isLoading={isLoading}
                    onClick={onConnectSlack}
                    colorScheme="green"
                  >
                    Connect Slack
                  </Button>
                ) : (
                  <>
                    {state === "SAVE" && (
                      <Button
                        isLoading={isLoading}
                        onClick={onSave}
                        isDisabled={!alertChanges.slackChannelId}
                        colorScheme="green"
                      >
                        Save and set live
                      </Button>
                    )}
                    {state === "ENABLE" && (
                      <Button
                        isLoading={isLoading}
                        onClick={onEnable}
                        isDisabled={!alertChanges.slackChannelId}
                        colorScheme="green"
                      >
                        Set live
                      </Button>
                    )}
                    {state === "UPDATE" && (
                      <Button
                        isLoading={isLoading}
                        onClick={onUpdate}
                        isDisabled={!alertChanges.slackChannelId}
                        colorScheme="green"
                      >
                        Update
                      </Button>
                    )}
                    {state === "PAUSE" && (
                      <Button isLoading={isLoading} onClick={onPause}>
                        Pause
                      </Button>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </DrawerHeader>
        <DrawerBody>
          <div className="flex w-full flex-col gap-5">
            <div className="flex w-full flex-col">
              <p className="mb-0 font-semibold">Preview</p>
              <p className="mb-2 text-sm text-gray-600">
                This is a preview of the graph you will receive in your chosen
                Slack channel
              </p>
              <div className="w-full rounded-lg bg-purple-100 p-3">
                <div className="w-full rounded-lg bg-white">
                  <div className="mb-2 flex flex-col px-6">
                    <div className="mt-1 flex flex-row items-center justify-start">
                      <div className="mr-2 mt-3 flex items-center justify-center rounded-lg border border-gray-200 p-1">
                        <CompanyJuneLogoIcon fontSize={26} />
                      </div>
                      <p className="font-semibold">June</p>
                      <p className="ml-2 rounded-md bg-gray-100 px-1 text-xs">
                        App
                      </p>
                      <p className="ml-2 text-xs text-gray-600">
                        {moment(Date.now()).format("HH:MM")}
                      </p>
                    </div>
                    <div className="ml-10 pl-1">
                      <p className="mt-[-10px] text-sm">
                        Here's your{" "}
                        <span className="text-transform-lowercase font-semibold">
                          {viewInsight.audience
                            ? viewInsight.audience.name
                            : viewInsight.title}
                        </span>{" "}
                        insight from your{" "}
                        <span className="text-transform-lowercase font-semibold">
                          {viewInsight.view.name}
                        </span>{" "}
                        dashboard
                      </p>
                    </div>
                  </div>
                  <div className="flex h-full w-full justify-center bg-gray-50 px-5 py-5">
                    <div
                      className={cx(
                        "max-w-full",
                        GRID_TO_PX[viewInsight.gridHeight]
                          ? `min-h-[${GRID_TO_PX[viewInsight.gridHeight]}] h-[${GRID_TO_PX[viewInsight.gridHeight]}]`
                          : "min-h-[315px]",
                        GRID_TO_PX[viewInsight.gridWidth]
                          ? `min-w-[${GRID_TO_PX[viewInsight.gridWidth]}] w-[${GRID_TO_PX[viewInsight.gridWidth]}]`
                          : "min-w-[315px]",
                      )}
                    >
                      {viewInsight.audience ? (
                        <AudienceViewInsightContainer
                          viewInsight={viewInsight}
                          isLoading={false}
                          isFetching={false}
                          isSlackInsight
                        />
                      ) : viewInsight.queryId ? (
                        <AIViewInsightContainer
                          location={ViewLocation.Home}
                          viewInsight={viewInsight}
                          isAdding={false}
                          isAdded={false}
                          viewId={viewInsight.viewId}
                          isBlurred={false}
                          isAI
                          isSlackInsight
                          execute={true}
                          skip={true}
                        />
                      ) : (
                        <ViewInsightContainer
                          location={ViewLocation.Home}
                          viewInsight={viewInsight}
                          isAdding={false}
                          isAdded={false}
                          viewId={viewInsight.viewId}
                          isBlurred={false}
                          onSetViewInsight={() => {}}
                          view={viewInsight.view}
                          isSlackInsight
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col">
              <p className="mb-0 font-semibold">Slack channel</p>
              <p className="mb-2 text-sm text-gray-600">
                The channel you wish to receive the graph in
              </p>
              {!hasSlackAppInstalled ? (
                <div>
                  <Button
                    onClick={onConnectSlack}
                    variant="ghost"
                    colorScheme="purple"
                  >
                    Connect Slack to select a channel
                  </Button>
                </div>
              ) : (
                <SlackChannels
                  showLabel={false}
                  defaultValue={
                    alertChanges && alertChanges.slackChannelId
                      ? alertChanges.slackChannelId
                      : ""
                  }
                  onChange={(ch) =>
                    setAlertChanges({ ...alertChanges, slackChannelId: ch })
                  }
                />
              )}
            </div>
            <Frequency
              alertChanges={alertChanges}
              setAlertChanges={setAlertChanges}
            />
          </div>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
