import { useParams } from "react-router-dom";
import { AppObjectType } from "core/models/appObjects";
import { useViewAlert } from "core/hooks/useViewAlert";
import { BellAlertIcon as BellAlertIconSolid } from "@heroicons/react/24/solid";
import { BellAlertIcon as BellAlertIconOutline } from "@heroicons/react/24/outline";
import { Button, useDisclosure } from "@chakra-ui/react";
import { ViewAlertEditorDrawer } from "./ViewAlertEditorDrawer";
import { PaywallPopover } from "core/components/Paywall/Popover";
import { Plan } from "core/types/App";

export const ViewAlertButton: React.FC<{ appObjectType: AppObjectType }> = ({
  appObjectType,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { groupId: encodedGroupId } = useParams<{
    groupId: string;
  }>() as {
    groupId: string;
  };
  const groupId = decodeURIComponent(encodedGroupId);
  const { isEnabled } = useViewAlert({ groupId, appObjectType });

  return (
    <>
      <PaywallPopover feature="company digests" redirect="home" plan={Plan.Pro}>
        <Button mr={-2} variant="ghost" size="xs" onClick={onOpen}>
          {isEnabled ? (
            <BellAlertIconSolid className="h-5 w-5 text-purple-500" />
          ) : (
            <BellAlertIconOutline className="h-5 w-5 text-gray-700" />
          )}
        </Button>
      </PaywallPopover>
      <ViewAlertEditorDrawer
        isOpen={isOpen}
        onClose={onClose}
        groupId={groupId}
        appObjectType={appObjectType}
      />
    </>
  );
};
