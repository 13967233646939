import { IAudienceFilters } from "core/types/Audience";
import { JoinOperatorValue } from "core/types/Filters.d";

export enum AddFilterType {
  FilterGroup = "filter_group",
  Filter = "filter",
}

const DEFAULT_EMAIL_FILTER = {
  type: 0,
  default: true,
  body: {
    trait: "email",
    value: "",
    comparisonType: 7,
  },
};

export const DEFAULT_FILTER_GROUP = {
  filters: [DEFAULT_EMAIL_FILTER],
  joinOperator: JoinOperatorValue.AND,
};

export const DEFAULT_FILTER_GROUPS = {
  filterGroups: [DEFAULT_FILTER_GROUP],
  joinOperator: JoinOperatorValue.AND,
};

export const EMPTY_FILTER_GROUPS = {
  filterGroups: [],
  joinOperator: JoinOperatorValue.AND,
};

interface IUpdateURLProps {
  appId: number;
  level?: string;
  audienceFilters?: IAudienceFilters;
  searchQuery?: string;
  skipUrlHashing?: boolean;
}

export const updateLevel = (level: string) => {
  const url = new URL(window.location.href);
  url.searchParams.set("level", level);
  window.history.replaceState(null, "", url.toString());
};

const isBase64Json = (str: string): boolean => {
  try {
    const decoded = atob(str);
    JSON.parse(decoded);
    return true;
  } catch (e) {
    return false;
  }
};

export const updateURL = ({
  appId,
  level,
  audienceFilters,
  searchQuery,
  skipUrlHashing = false,
}: IUpdateURLProps) => {
  const pathname = window.location.pathname;
  const regex = new RegExp(`^/a/${appId}/`);
  let route = pathname.replace(regex, "");

  if (!skipUrlHashing && audienceFilters?.filterGroups?.length > 0) {
    const newHash = btoa(JSON.stringify(audienceFilters));
    const segments = route.split("/");

    // Check if the last segment is an existing hash
    const lastSegment = segments[segments.length - 1];
    if (isBase64Json(lastSegment)) {
      // Replace the existing hash with the new hash
      segments[segments.length - 1] = newHash;
    } else {
      // Append the new hash
      segments.push(newHash);
    }

    route = segments.join("/");
  }

  const basePath = `/a/${appId}/${route}`;

  const qs = new URLSearchParams(window.location.search);
  if (searchQuery && searchQuery?.length > 0) qs.set("query", searchQuery);
  if (level) qs.set("level", level);

  const queryString = qs.toString();
  const finalUrl = queryString ? `${basePath}?${queryString}` : basePath;

  window.history.replaceState(null, "", finalUrl);
};

export const parseFilterGroupsFromUrl = (): IAudienceFilters | {} => {
  let filterGroups = undefined;

  const pathParts = window.location.pathname.split("/");
  const peopleIndex = pathParts.indexOf("people");
  const feedIndex = pathParts.indexOf("feed");
  const index = peopleIndex !== -1 ? peopleIndex : feedIndex;
  const hash = index < pathParts.length - 1 ? pathParts[index + 1] : "";

  if (hash.length > 0) {
    filterGroups = JSON.parse(atob(hash));
  }

  if (typeof filterGroups !== "undefined") return filterGroups;

  return DEFAULT_FILTER_GROUPS;
};
