import { cx } from "helpers/cx";
import { ITraitKeyResponse } from "core/models/traits";
import { TraitIcon } from "core/components/Traits/TraitIcon";
import { BoltIcon } from "@heroicons/react/24/solid";
import { Tooltip } from "@chakra-ui/react";

interface TraitOptionProps {
  trait: ITraitKeyResponse;
  isDisabled?: boolean;
}

export const TraitOption: React.FC<TraitOptionProps> = ({
  trait,
  isDisabled,
}) => {
  return (
    <div className="flex w-full">
      <div
        className={cx(
          `flex w-full items-center justify-between gap-1`,
          isDisabled ? "text-gray-500" : "text-gray-800",
        )}
      >
        <div className="flex min-w-0 items-center gap-1.5">
          <TraitIcon
            traitName={trait.trait}
            computationType={trait.computationType}
            className="flex-shrink-0"
          />
          <p className="line-clamp-1 truncate text-sm font-medium normal-case tracking-normal">
            {trait.trait}
          </p>
        </div>
        <div className="flex flex-shrink-0">
          {trait.isComputed && (
            <Tooltip label="Computed trait" hasArrow placement="right">
              <BoltIcon className="h-3 w-3 text-purple-500" />
            </Tooltip>
          )}
          {trait.trait.startsWith("june_") && (
            <Tooltip label="Trait provided by June" hasArrow placement="right">
              <BoltIcon className="h-3 w-3 text-gray-500" />
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};
