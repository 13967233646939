import humps from "humps";
import { transformFilterGroups } from "helpers/params";
import { IPagy } from "core/types/Pagy";
import { IContact } from "core/types/Contact";
import { IAudience, IAudienceFilters } from "core/types/Audience";
import { baseQuery } from "core/initializers/rtk";
import { createApi } from "@reduxjs/toolkit/query/react";

import type { SnakeCasedPropertiesDeep } from "type-fest";

interface IGetContactsArgs {
  appId: number;
  page: number;
  audienceFilters: IAudienceFilters;
  groupId?: string;
  searchQuery: string;
  skipPagination?: boolean;
  skipOptimization?: boolean;
  sortBy?: string;
  sortOrder?: string;
  viewInsightId?: number;
  token?: string;
}

export interface IGetContactsResponse {
  contacts: IContact[];
  totalCount: number;
  pagy: IPagy;
}

interface IGetContactSummaryResponse {
  data: string;
}

export const contactsApi = createApi({
  baseQuery,
  reducerPath: "contactsApi",
  tagTypes: ["Contact"],
  endpoints: (builder) => ({
    getContacts: builder.query<IGetContactsResponse, IGetContactsArgs>({
      query: ({
        appId,
        page,
        audienceFilters,
        groupId,
        searchQuery,
        skipPagination,
        skipOptimization,
        sortBy,
        sortOrder,
        viewInsightId,
        token,
      }) => ({
        url: "/contacts",
        params: humps.decamelizeKeys({
          appId,
          page,
          filterGroups: JSON.stringify(
            transformFilterGroups(audienceFilters.filterGroups),
          ),
          joinOperator: audienceFilters.joinOperator,
          groupId,
          query: searchQuery,
          skipPagination,
          skipOptimization,
          sortBy,
          sortOrder,
          viewInsightId,
          token,
        }),
      }),
      providesTags: ["Contact"],
      transformResponse: (
        response: SnakeCasedPropertiesDeep<IGetContactsResponse>,
      ): IGetContactsResponse => {
        const camelizedResponse = humps.camelizeKeys(
          response,
        ) as IGetContactsResponse;

        camelizedResponse.contacts = camelizedResponse.contacts.map(
          (contact) => {
            const { traits, ...rest } = contact;
            return {
              ...rest,
              traits:
                response.contacts.find((c) => c.id === contact.id)?.traits ||
                traits,
            };
          },
        );

        return camelizedResponse;
      },
    }),
    getSummary: builder.query<
      IGetContactSummaryResponse,
      { appId: number; contactId: string; refresh: boolean }
    >({
      query: ({ appId, contactId, refresh }) => ({
        url: `/contacts/${contactId}/summary`,
        params: humps.decamelizeKeys({ appId, contactId, refresh }),
      }),
      providesTags: ["Contact"],
      transformResponse: (
        response: SnakeCasedPropertiesDeep<IGetContactSummaryResponse>,
      ): IGetContactSummaryResponse =>
        humps.camelizeKeys(response) as IGetContactSummaryResponse,
    }),
    getAudiences: builder.query<
      IAudience[],
      { appId: number; contactId: string }
    >({
      query: ({ appId, contactId }) => ({
        url: `/contacts/${contactId}/audiences`,
        params: humps.decamelizeKeys({ appId, contactId }),
      }),
      providesTags: ["Contact"],
      transformResponse: (
        response: SnakeCasedPropertiesDeep<IAudience[]>,
      ): IAudience[] => humps.camelizeKeys(response) as IAudience[],
    }),
  }),
});

export const {
  useGetContactsQuery,
  useLazyGetContactsQuery,
  useLazyGetSummaryQuery,
  useGetAudiencesQuery,
} = contactsApi;
