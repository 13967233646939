import { useEffect, useRef } from "react";
import { Input } from "@chakra-ui/react";
import { DataTypeString } from "core/models/traits";
import { ComparisonType } from "core/types/Filters.d.ts";

function getInputType(
  dataType: DataTypeString,
  comparisonType?: ComparisonType,
) {
  if (
    comparisonType !== undefined &&
    [
      ComparisonType.LESS_THAN_DAYS_AGO,
      ComparisonType.MORE_THAN_DAYS_AGO,
    ].includes(comparisonType)
  ) {
    return "number";
  }
  if (dataType === DataTypeString.DATE) {
    return "date";
  }
  if (dataType === DataTypeString.NUMBER) {
    return "number";
  }
  return "text";
}

export const ValueInput: React.FC<{
  traitValue: string;
  placeholder?: string;
  onValueChange: (val: string) => void;
  dataType?: DataTypeString;
  comparisonType?: ComparisonType;
}> = ({ traitValue, onValueChange, placeholder, dataType, comparisonType }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <Input
      focusBorderColor="purple.500"
      size="sm"
      type={getInputType(dataType || DataTypeString.UNASSIGNED, comparisonType)}
      ref={inputRef}
      placeholder={placeholder || "Enter a value"}
      value={traitValue}
      onChange={(e) => {
        onValueChange(e.currentTarget.value);
      }}
      onKeyPress={(e) => {
        if (e.key === "Enter") {
          onValueChange(e.currentTarget.value);
        }
      }}
      borderRadius={5}
      data-testid="audience-trait-value-input"
    />
  );
};
