import {
  CheckCircleIcon,
  MinusCircleIcon,
  XCircleIcon,
} from "@heroicons/react/20/solid";
import { Card, CardBody, CardHeader } from "@chakra-ui/react";

export const AccountScoreCard: React.FC = () => {
  return (
    <Card
      shadow="none"
      border="1px solid"
      borderColor="gray.200"
      h="full"
      p="0"
    >
      <CardHeader pt={4} pb={2} fontWeight="semibold">
        <div className="flex w-full items-center justify-between">
          <p>Product Activity</p>
        </div>
      </CardHeader>
      <CardBody pt={2} pb={4}>
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-2">
            <CheckCircleIcon className="h-5 w-5 text-green-500" />
            <span className="text-sm">Sent events in the past 24 hours</span>
          </div>
          <div className="flex items-center gap-2">
            <CheckCircleIcon className="h-5 w-5 text-green-500" />
            <span className="text-sm">Using company pages</span>
          </div>
          <div className="flex items-center gap-2">
            <MinusCircleIcon className="h-5 w-5 text-yellow-500" />
            <span className="text-sm">3/10 active seats</span>
          </div>
          <div className="flex items-center gap-2">
            <XCircleIcon className="h-5 w-5 text-red-500" />
            <span className="text-sm">Has Attio Sync enabled</span>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};
