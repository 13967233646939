import { cx } from "helpers/cx";
import { IAudience } from "core/types/Audience";
import { AudienceStatus } from "core/models/audienceLogs";
import { AppObjectType } from "core/models/appObjects";
import usePagination from "core/hooks/usePagination";
import { useAudienceLogs } from "core/hooks/useAudienceLogs";
import SingleGroup from "core/components/Group/SingleGroup";
import { GroupType } from "core/components/Group/GroupListSingleGroup";
import { Drilldown } from "core/components/Drilldown";
import SingleContact from "core/components/Contact/SingleContact";
import { Button, useDisclosure } from "@chakra-ui/react";

interface IChangesPill {
  changes?: {
    entered: number;
    exited: number;
  };
}

const ChangesPill: React.FC<IChangesPill> = ({ changes }) => {
  const noChanges = changes?.entered === 0 && changes?.exited === 0;

  return (
    <div
      className={cx(
        "flex items-center gap-2 py-2 text-xs",
        noChanges && "text-gray-600",
      )}
    >
      {noChanges ? (
        "No changes this week"
      ) : (
        <>
          {changes?.entered === 0 ? (
            <span className="text-gray-400">+{changes?.entered}</span>
          ) : (
            <span className="text-green-500">+{changes?.entered}</span>
          )}
          {changes?.exited === 0 ? (
            <span className="text-gray-400">-{changes?.exited}</span>
          ) : (
            <span className="text-orange-500">-{changes?.exited}</span>
          )}
          <span className="text-gray-600"> this week</span>
        </>
      )}
    </div>
  );
};

export const AudienceLogs: React.FC<{ audience: IAudience }> = ({
  audience,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { currentPage, nextPage } = usePagination({
    withURL: false,
    persist: false,
  });
  const {
    audienceLogs,
    changes,
    pagy,
    isLoadingAudienceLogs,
    isLoadingChanges,
  } = useAudienceLogs({
    audience,
    page: currentPage,
  });
  const noChanges = changes?.entered === 0 && changes?.exited === 0;

  return (
    <>
      <Button
        size="xs"
        isLoading={isLoadingChanges}
        onClick={noChanges ? undefined : onOpen}
      >
        <ChangesPill changes={changes} />
      </Button>
      <Drilldown isOpen={isOpen} onClose={onClose}>
        {(Drilldown) => (
          <>
            <Drilldown.Title
              title="Audience changes this week"
              isLoading={isLoadingAudienceLogs}
            />
            <Drilldown.Body pt={4}>
              <Drilldown.List
                key={`${audienceLogs.length}`}
                items={audienceLogs}
                hasMore={audienceLogs.length < Number(pagy?.count)}
                loadNext={() => nextPage()}
                isLoading={isLoadingAudienceLogs}
                emptyStateText={`No audience changes this week`}
                itemRenderer={({ item }) => (
                  <div className="flex items-center justify-between gap-2">
                    {audience.appObject.objectType === AppObjectType.User ? (
                      <SingleContact
                        id={item.entityId}
                        hideHoverBorder={true}
                        hideExternalLinkIcon={true}
                      />
                    ) : (
                      <SingleGroup
                        id={item.entityId}
                        hideHoverBorder={true}
                        hideExternalLinkIcon={true}
                        groupType={
                          audience.appObject.objectType === AppObjectType.Group
                            ? GroupType.Group
                            : GroupType.Company
                        }
                      />
                    )}
                    {item.status === AudienceStatus.Entered && (
                      <span className="rounded bg-green-100 px-2 py-0.5 text-xs text-green-600">
                        Entered{" "}
                        {new Date(item.createdAt).toLocaleDateString("en-US", {
                          day: "numeric",
                          month: "short",
                          year: "numeric",
                        })}
                      </span>
                    )}
                    {item.status === AudienceStatus.Exited && (
                      <span className="rounded bg-orange-100 px-2 py-0.5 text-xs text-orange-500">
                        Exited{" "}
                        {new Date(item.createdAt).toLocaleDateString("en-US", {
                          day: "numeric",
                          month: "short",
                          year: "numeric",
                        })}
                      </span>
                    )}
                  </div>
                )}
              />
            </Drilldown.Body>
          </>
        )}
      </Drilldown>
    </>
  );
};
