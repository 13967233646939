import { useEffect, useState } from "react";
import { useArchiveTraitMutation, useGetTraitsQuery } from "core/models/traits";
import { APP_OBJECT_TO_FILTER_TYPE } from "core/constants/filters";
import {
  APP_OBJECT_TO_TRAIT_LEVEL,
  READ_ONLY_TRAITS,
} from "core/constants/traits";
import { useDataMappings } from "core/hooks/useDataMappings";
import { Integration } from "core/models/dataMappings";
import { IAppObject } from "../models/appObjects";

export const useAppObjectTraits = ({
  appObject,
  isActive,
  limit,
}: {
  appObject: IAppObject;
  isActive: boolean;
  limit?: number;
}) => {
  const appId = appObject.appId;
  const filterType = APP_OBJECT_TO_FILTER_TYPE[appObject.objectType];
  const traitLevel = APP_OBJECT_TO_TRAIT_LEVEL[appObject.objectType];
  const [archiveTrait] = useArchiveTraitMutation();
  const [showArchiveTraitModal, setShowArchiveTraitModal] = useState(false);
  const [archivingTrait, setArchivingTrait] = useState<string | null>(null);

  const { dataMappings: hubspotDataMappings } = useDataMappings({
    appId,
    appObject,
    integration: Integration.Hubspot,
  });
  const { dataMappings: attioDataMappings } = useDataMappings({
    appId,
    appObject,
    integration: Integration.Attio,
  });
  const {
    data: traits,
    isLoading,
    refetch,
  } = useGetTraitsQuery({
    appId,
    filterType,
    limit,
    isActive,
  });

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [traits]);

  function isSyncedWithHubspot(trait: string | null) {
    if (!trait) return false;
    return hubspotDataMappings.map((mapping) => mapping.source).includes(trait);
  }

  function isSyncedWithAttio(trait: string | null) {
    if (!trait) return false;
    return attioDataMappings.map((mapping) => mapping.source).includes(trait);
  }

  function isReadOnly(trait: string) {
    return READ_ONLY_TRAITS.includes(trait);
  }

  function handleCancelArchiveTrait() {
    setShowArchiveTraitModal(false);
    setArchivingTrait(null);
  }

  async function handleArchiveTrait(trait: string | null) {
    if (!trait) return;

    try {
      await archiveTrait({
        appId,
        trait,
        level: traitLevel,
      }).unwrap();
      setShowArchiveTraitModal(false);
      setArchivingTrait(null);
    } catch (error) {
      console.error(error);
    }
  }

  return {
    isSyncedWithHubspot,
    isSyncedWithAttio,
    isReadOnly,
    handleCancelArchiveTrait,
    handleArchiveTrait,
    isLoading,
    traits,
    showArchiveTraitModal,
    setShowArchiveTraitModal,
    archivingTrait,
    setArchivingTrait,
  };
};
