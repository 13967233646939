import { AddFilterType } from "helpers/filters";
import { IFilterGroup } from "core/types/Filters.d";
import { SavedAudienceTooltipPreview } from "core/modules/reports/setup/Audience/SavedAudienceTooltipPreview";
import { useReportSetup } from "core/hooks/useReportSetup";
import Icon from "core/design-system/components/Icon";
import { INTERVAL_TYPE_NUMBERS } from "core/constants/timerange";
import {
  FILTER_TYPE_TO_TRAIT_TYPE,
  FilterType,
  MORE_THAN,
  TimerangeType,
} from "core/constants/report-setup";
import { FIRST_EVENT } from "core/constants/events";
import { Box, Flex, Text, Tooltip } from "@chakra-ui/react";
import { SuggestedAudiences } from "./SuggestedAudiences";
import { Filters } from "../Audience/Filters";
import AddAudienceFilterButton from "../Audience/AddFilterButton";
import { getLevelParam } from "../../utils";
import { JoinOperatorValue } from "core/types/Filters.d";
import { DataTypeString } from "core/models/traits";
import {
  COMPARISON_TYPE_BY_DATA_TYPE,
  DEFAULT_VALUES_FOR_DATA_TYPE,
} from "core/modules/audience/Filters/Filters";
interface IAudiencePickerProps {
  section: {
    type: FilterType;
  };
  showRecommendedAudiences?: boolean;
  isDisabled?: boolean;
  disabledTooltip?: string;
  onUpdateReport?: () => void;
}

const AudiencePicker: React.FC<IAudiencePickerProps> = ({
  section,
  showRecommendedAudiences = true,
  isDisabled = false,
  disabledTooltip,
  onUpdateReport,
}) => {
  const {
    currentReport,
    isCurrentlyAddingFilter,
    addAudienceFilterGroup,
    addAudienceFilter,
    removeSavedAudience,
  } = useReportSetup();
  const reportLevel = getLevelParam();

  if (!currentReport || !currentReport.audience?.filterGroups) return null;

  const filterGroups = currentReport?.audience?.filterGroups;

  function onAddEventFilter(
    addFilterType: AddFilterType,
    filterGroupIndex: number,
    filterType: string,
  ) {
    const filter = {
      id: 0,
      adding: true,
      _type: section.type,
      filterType: filterType,
      type: FILTER_TYPE_TO_TRAIT_TYPE[filterType],
      body: {
        ...FIRST_EVENT,
        times: 0,
        interval: 7,
        intervalType: INTERVAL_TYPE_NUMBERS.day,
        comparisonType: MORE_THAN,
        timerangeType: TimerangeType.RELATIVE,
        trait: "",
        value: "",
      },
    };

    if (addFilterType === AddFilterType.FilterGroup) {
      const filterGroup = {
        filters: [filter],
        joinOperator: JoinOperatorValue.AND,
      };
      addAudienceFilterGroup({ filterGroup });
    } else {
      addAudienceFilter({ filterGroupIndex, filter });
    }
    onUpdateReport && onUpdateReport();
  }

  function onAddTraitFilter(
    addFilterType: AddFilterType,
    filterGroupIndex: number,
    filterType: string,
    trait: string,
    filterIndex: number,
    dataType: DataTypeString,
  ) {
    const filter = {
      id: 0,
      adding: true,
      _type: section.type,
      filterType: filterType,
      type: FILTER_TYPE_TO_TRAIT_TYPE[filterType],
      body: {
        comparisonType: COMPARISON_TYPE_BY_DATA_TYPE[dataType],
        trait,
        value: DEFAULT_VALUES_FOR_DATA_TYPE[dataType].toString(),
      },
    };
    if (addFilterType === AddFilterType.FilterGroup) {
      const filterGroup = {
        filters: [filter],
        joinOperator: JoinOperatorValue.AND,
      };
      addAudienceFilterGroup({ filterGroup });
    } else {
      addAudienceFilter({ filterGroupIndex, filter });
    }
    onUpdateReport && onUpdateReport();
  }

  return (
    <Flex direction="column" data-testid="audience-picker">
      {!currentReport?.audience?.isSavedAudience ? (
        <div className="relative flex flex-wrap items-center gap-y-2">
          {filterGroups.map(
            (filterGroup: IFilterGroup, filterGroupIndex: number) => {
              return (
                <>
                  <Filters
                    section={section}
                    filterGroup={filterGroup}
                    filterGroupIndex={filterGroupIndex}
                    view={reportLevel}
                    truncate
                    onUpdateReport={onUpdateReport}
                  />
                  {filterGroups.length - 1 === filterGroupIndex && (
                    <div className="flex">
                      <AddAudienceFilterButton
                        isCurrentlyAddingFilter={isCurrentlyAddingFilter}
                        onAddTraitFilter={onAddTraitFilter}
                        onAddEventFilter={onAddEventFilter}
                        isDisabled={
                          isDisabled ||
                          currentReport?.audience?.isSavedAudience ||
                          isCurrentlyAddingFilter
                        }
                        addFilterType={AddFilterType.FilterGroup}
                        filterGroupIndex={filterGroupIndex}
                        disabledTooltip={disabledTooltip}
                      />
                    </div>
                  )}
                </>
              );
            },
          )}
          {filterGroups.length === 0 && (
            <div className="flex">
              <AddAudienceFilterButton
                isCurrentlyAddingFilter={isCurrentlyAddingFilter}
                onAddTraitFilter={onAddTraitFilter}
                onAddEventFilter={onAddEventFilter}
                isDisabled={
                  isDisabled ||
                  currentReport?.audience?.isSavedAudience ||
                  isCurrentlyAddingFilter
                }
                addFilterType={AddFilterType.FilterGroup}
                disabledTooltip={disabledTooltip}
              />
            </div>
          )}
        </div>
      ) : (
        currentReport?.audience?.isSavedAudience && (
          <Box w="fit-content">
            <Flex gridGap={0.5}>
              <Tooltip
                w="full"
                maxW="900px"
                bg="gray.900"
                placement="bottom"
                hasArrow
                shouldWrapChildren
                label={
                  <SavedAudienceTooltipPreview
                    audience={currentReport?.audience}
                  />
                }
              >
                <Flex
                  align="center"
                  bg="gray.100"
                  borderRadius="lg"
                  borderRightRadius="none"
                  px={3}
                  py={1.5}
                  cursor="default"
                >
                  <Icon iconType="icon" name="audiences" h={4} w={4} mr={1.5} />
                  <Text fontWeight="medium" fontSize="sm">
                    {currentReport?.audience?.name}
                  </Text>
                </Flex>
              </Tooltip>
              <Tooltip label="Remove saved audience" hasArrow placement="top">
                <Flex
                  bg="gray.100"
                  borderRadius="lg"
                  borderLeftRadius="none"
                  px={3}
                  py={1.5}
                  cursor="pointer"
                  _hover={{ bg: "gray.200" }}
                  onClick={() => removeSavedAudience()}
                >
                  <Text fontSize="sm">
                    <Icon iconType="icon" name="close" h={2} w={2} />
                  </Text>
                </Flex>
              </Tooltip>
            </Flex>
          </Box>
        )
      )}
      {showRecommendedAudiences && <SuggestedAudiences />}
    </Flex>
  );
};

export default AudiencePicker;
