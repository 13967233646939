import { IAppObject } from "core/models/appObjects";
import {
  ExclamationTriangleIcon,
  BoltIcon as SolidBoltIcon,
} from "@heroicons/react/24/solid";
import { XMarkIcon } from "@heroicons/react/20/solid";
import HubspotIcon from "core/design-system/components/Icon/Interface/HubspotIcon";
import AttioIcon from "core/design-system/components/Icon/Interface/AttioIcon";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Skeleton,
  Tooltip,
  Button,
} from "@chakra-ui/react";
import { CheckIcon, QuestionOutlineIcon } from "@chakra-ui/icons";
import { Link } from "react-router-dom";
import { useRef, useState } from "react";
import { useAppObjectTraits } from "core/hooks/useAppObjectTraits";
import { ITraitKeyResponse } from "core/models/traits";
import { TraitEditModal } from "./TraitEditModal";

interface ITraits {
  appObject: IAppObject;
  limit?: number;
  isActive?: boolean;
}

const AppObjectTraits: React.FC<ITraits> = ({
  appObject,
  limit,
  isActive = true,
}) => {
  const cancelRef = useRef<HTMLButtonElement>(null);
  const [highlightedTrait, setHighlightedTrait] = useState<string | null>(null);
  const [selectedTrait, setSelectedTrait] = useState<ITraitKeyResponse | null>(
    null,
  );
  const {
    isLoading,
    traits,
    isSyncedWithHubspot,
    isSyncedWithAttio,
    isReadOnly,
    handleCancelArchiveTrait,
    handleArchiveTrait,
    showArchiveTraitModal,
    setShowArchiveTraitModal,
    archivingTrait,
    setArchivingTrait,
  } = useAppObjectTraits({ appObject, isActive, limit });

  return (
    <div>
      <table className="min-w-full divide-y divide-gray-300">
        <thead>
          <tr>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
            >
              Name
            </th>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
            >
              <div className="flex items-center gap-1">
                Type
                <Tooltip
                  label="Computed traits are computed based on events. Non-computed traits mostly have static values."
                  placement="top"
                  textAlign="center"
                  hasArrow
                >
                  <QuestionOutlineIcon w={3} h={3} color="gray" />
                </Tooltip>
              </div>
            </th>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
            >
              <div className="flex items-center gap-1">Data type</div>
            </th>
            {isActive && (
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
              >
                <div className="flex items-center gap-1">
                  Audience
                  <Tooltip
                    label="This trait is being used in a saved audience or a report"
                    placement="top"
                    textAlign="center"
                    hasArrow
                  >
                    <QuestionOutlineIcon w={3} h={3} color="gray" />
                  </Tooltip>
                </div>
              </th>
            )}
            {isActive && (
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
              >
                <div className="flex items-center gap-1">
                  CRM
                  <Tooltip
                    label="This trait is synced with a CRM"
                    placement="top"
                    textAlign="center"
                    hasArrow
                  >
                    <QuestionOutlineIcon w={3} h={3} color="gray" />
                  </Tooltip>
                </div>
              </th>
            )}
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
            ></th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {isLoading && (
            <tr>
              {[...Array(3)].map((_, index) => (
                <td key={index} className="py-4">
                  <Skeleton h="16px" w="120px" borderRadius="xl" />
                </td>
              ))}
            </tr>
          )}

          {traits?.length === 0 && (
            <tr>
              <td colSpan={4} className="py-4 text-sm text-gray-600">
                No traits found
              </td>
            </tr>
          )}

          {traits?.map((trait) => (
            <tr
              key={trait.trait}
              onMouseEnter={() => setHighlightedTrait(trait.trait)}
              onMouseLeave={() => setHighlightedTrait(null)}
            >
              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                <div className="flex items-baseline gap-2">
                  <span>{trait.trait}</span>
                  {isReadOnly(trait.trait) ? (
                    <span className="rounded-md border bg-gray-50 px-1 text-xs text-gray-600">
                      Read only
                    </span>
                  ) : null}
                </div>
              </td>
              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                {trait.isComputed ? (
                  <span className="inline-flex items-center gap-1 rounded-md border border-purple-200 bg-purple-50 px-1.5 text-[10px] font-semibold uppercase text-purple-500">
                    <SolidBoltIcon className="h-3 w-3 text-purple-500" />
                    Computed
                  </span>
                ) : (
                  <span className="rounded-md border border-gray-200 bg-gray-50 px-1.5 py-1 text-[10px] font-semibold uppercase text-gray-700">
                    Non-computed
                  </span>
                )}
              </td>
              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm capitalize text-gray-900 sm:pl-0">
                {trait.dataType}
              </td>

              {isActive && (
                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                  {trait.partOfAudience ? (
                    <CheckIcon h={4} color="green.400" />
                  ) : (
                    <XMarkIcon className="h-4 w-4 text-gray-600" />
                  )}
                </td>
              )}
              {isActive && (
                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                  <div className="flex items-center">
                    {isSyncedWithHubspot(trait.trait) && (
                      <Link
                        to={`/a/${appObject.appId}/integrations/hubspot/${appObject.objectType}`}
                        className="rounded-md px-2 py-1 hover:bg-gray-100"
                      >
                        <HubspotIcon w={4} h={4} color="#ff7a59" />
                      </Link>
                    )}
                    {isSyncedWithAttio(trait.trait) && (
                      <Link
                        to={`/a/${appObject.appId}/integrations/attio/${appObject.objectType}`}
                        className="rounded-md px-2 py-1 hover:bg-gray-100"
                      >
                        <AttioIcon w={4} h={4} />
                      </Link>
                    )}
                  </div>
                </td>
              )}
              {isActive && (
                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                  <div className="flex  justify-end gap-2">
                    <div className="w-[130px]">
                      <Button
                        key={`${trait.trait}-archive`}
                        w="60px"
                        colorScheme="red"
                        variant="ghost"
                        onClick={() => {
                          if (isReadOnly(trait.trait)) return;
                          setArchivingTrait(trait.trait);
                          setShowArchiveTraitModal(true);
                        }}
                        disabled={
                          isReadOnly(trait.trait) ||
                          archivingTrait === trait.trait
                        }
                      >
                        {highlightedTrait === trait.trait && "Delete"}
                      </Button>
                      <Button
                        variant="ghost"
                        colorScheme="purple"
                        isDisabled={isReadOnly(trait.trait) || trait.isComputed}
                        onClick={() => {
                          if (isReadOnly(trait.trait) || trait.isComputed)
                            return;

                          setSelectedTrait(trait);
                        }}
                      >
                        Edit
                      </Button>
                    </div>
                  </div>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>

      {showArchiveTraitModal && (
        <AlertDialog
          leastDestructiveRef={cancelRef}
          isOpen={showArchiveTraitModal}
          onClose={() => handleCancelArchiveTrait()}
        >
          <AlertDialogOverlay />
          <AlertDialogContent>
            <AlertDialogHeader>Delete trait</AlertDialogHeader>
            <AlertDialogBody className="text-sm">
              <p>
                Are you sure you want to delete{" "}
                <span className="font-medium">{archivingTrait}</span>?
              </p>
              <br />
              <p>
                Deleting a trait will permanently remove it for all{" "}
                {appObject.pluralName} in June. Make sure to stop tracking it
                through your SDK.
              </p>
              <br />
              {(isSyncedWithHubspot(archivingTrait) ||
                isSyncedWithAttio(archivingTrait)) && (
                <p className="flex gap-2 rounded-lg bg-orange-100 p-2 text-sm">
                  <ExclamationTriangleIcon className="h-8 w-8 text-yellow-600" />
                  <div>
                    This trait is being synced with{" "}
                    <span className="font-medium">
                      {isSyncedWithHubspot(archivingTrait) && "Hubspot"}
                      {isSyncedWithHubspot(archivingTrait) &&
                        isSyncedWithAttio(archivingTrait) &&
                        " and "}
                      {isSyncedWithAttio(archivingTrait) && "Attio"}
                    </span>
                    . Deleting it will stop syncing it with your CRM.
                  </div>
                </p>
              )}
            </AlertDialogBody>
            <AlertDialogFooter className="flex justify-end gap-2">
              <Button
                className="rounded-md border px-2 py-1 text-sm font-medium disabled:opacity-50 data-[hover]:bg-gray-100"
                onClick={() => handleCancelArchiveTrait()}
              >
                Cancel
              </Button>
              <Button
                className="rounded-md bg-red-500 px-2 py-1 text-sm font-medium text-white disabled:opacity-50 data-[hover]:bg-red-600"
                onClick={() => handleArchiveTrait(archivingTrait)}
              >
                Delete trait
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      )}
      {selectedTrait && (
        <TraitEditModal
          trait={selectedTrait}
          isOpen={!!selectedTrait}
          onClose={() => setSelectedTrait(null)}
          appObject={appObject}
        />
      )}
    </div>
  );
};

export default AppObjectTraits;
