import { useParams } from "react-router-dom";
import { useCreateAudienceViewInsightQuery } from "core/models/viewInsights";
import { AudienceModalContainer } from "./AudienceModalContainer";

export const AddAudienceModalContainer: React.FC<{ onClose: () => void }> = ({
  onClose,
}) => {
  const { appId, viewId } = useParams();
  const { data: viewInsight, isLoading } = useCreateAudienceViewInsightQuery({
    appId: Number(appId),
    viewId: Number(viewId),
  });

  if (isLoading) return null;
  if (!viewInsight) return null;

  return (
    <AudienceModalContainer
      viewInsight={viewInsight}
      onClose={onClose}
      isOpen
    />
  );
};
