import { useState } from "react";
import moment from "moment";
import { ITimerangeOption } from "core/types/TimerangeOption";
import {
  IViewInsight,
  useUpdateViewInsightMutation,
} from "core/models/viewInsights";
import {
  getInitialTimerange,
  getReadableTimerange,
} from "core/helpers/timerange";
import {
  ABSOLUTE,
  RELATIVE,
  RELATIVE_TIMERANGE_VALUE_FROM_KEYS,
} from "core/constants/timerange";

export interface ICustomTimerange {
  startDate: Date;
  endDate: Date;
  key: string;
}

export const useViewInsightTimerange = (
  viewInsight: IViewInsight,
): {
  label: string;
  timerangeType: typeof RELATIVE | typeof ABSOLUTE;
  timerangeValue: number;
  timerangeStartTimestamp?: string;
  timerangeEndTimestamp?: string;
  relativeTimerange: ITimerangeOption;
  customTimerange: ICustomTimerange[];
  readableCustomTimerange: string;
  setCustomTimerange: (customTimerange: ICustomTimerange[]) => void;
  saveTimerange: (option: ITimerangeOption) => void;
} => {
  const [timerangeValue, setTimerangeValue] = useState(
    viewInsight.timerangeValue,
  );
  const [timerangeType, setTimerangeType] = useState<number>(
    viewInsight.timerangeType,
  );

  const [timerangeStartTimestamp, setTimerangeStartTimestamp] = useState(
    viewInsight.timerangeStartTimestamp,
  );

  const [timerangeEndTimestamp, setTimerangeEndTimestamp] = useState(
    viewInsight.timerangeEndTimestamp,
  );

  const relativeTimerange = getInitialTimerange({
    timerangeType,
    timerangeValue,
  }) as ITimerangeOption;

  let startDate = viewInsight.timerangeStartTimestamp
    ? moment.utc(viewInsight.timerangeStartTimestamp).toDate()
    : new Date(
        Date.UTC(
          new Date().getUTCFullYear(),
          new Date().getUTCMonth(),
          new Date().getUTCDate() - 7,
        ),
      );
  let endDate = viewInsight.timerangeEndTimestamp
    ? moment.utc(viewInsight.timerangeEndTimestamp).toDate()
    : new Date();
  const initialCustomTimerange = [
    {
      startDate,
      endDate,
      key: "selection",
    },
  ];

  const isCustomDate = relativeTimerange?.value === "fixed";
  const [customTimerange, setCustomTimerange] = useState(
    initialCustomTimerange,
  );
  const readableCustomTimerange = getReadableTimerange({
    startTimestamp: customTimerange[0].startDate,
    endTimestamp: customTimerange[0].endDate,
  });
  const label = isCustomDate ? "Custom" : relativeTimerange?.short;

  const [updateViewInsight] = useUpdateViewInsightMutation();

  const saveTimerange = async (newTimerange: ITimerangeOption) => {
    const timerangeValue = newTimerange.value
      ? RELATIVE_TIMERANGE_VALUE_FROM_KEYS[
          newTimerange.value as keyof typeof RELATIVE_TIMERANGE_VALUE_FROM_KEYS
        ]
      : 0;
    const timerangeStartTimestamp = moment(customTimerange[0].startDate)
      .startOf("day")
      .format("YYYY-MM-DDTHH:mm:ss");
    const timerangeEndTimestamp = moment(customTimerange[0].endDate)
      .endOf("day")
      .format("YYYY-MM-DDTHH:mm:ss");

    const timerange = {
      timerangeType: newTimerange.type,
      timerangeValue,
      timerangeStartTimestamp,
      timerangeEndTimestamp,
    };

    setTimerangeValue(timerangeValue);
    setTimerangeType(newTimerange.type);
    setTimerangeStartTimestamp(timerangeStartTimestamp);
    setTimerangeEndTimestamp(timerangeEndTimestamp);

    updateViewInsight({
      ...timerange,
      id: Number(viewInsight.id),
      appId: Number(viewInsight.appId),
      order: Number(viewInsight.order),
    });
  };

  return {
    label: label || "",
    relativeTimerange,
    customTimerange,
    readableCustomTimerange,
    timerangeType,
    timerangeValue,
    timerangeStartTimestamp,
    timerangeEndTimestamp,
    setCustomTimerange,
    saveTimerange,
  };
};
