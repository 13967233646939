import { useParams } from "react-router-dom";
import { AppObjectType, IAppObject } from "../models/appObjects";
import { useGetAudienceCountQuery } from "../models/audiences";
import { Level } from "../models/people";
import { IAudience } from "core/types/Audience";
import { useQueryParam } from "./useQueryParam";

export const useAudienceViewInsightDescription = ({
  audience,
  appObject,
  viewInsightId,
}: {
  audience: IAudience;
  appObject: IAppObject;
  viewInsightId: number;
}) => {
  const { appId } = useParams();
  const token = useQueryParam("token") || undefined;
  const levels = {
    [AppObjectType.User]: Level.User,
    [AppObjectType.Group]: Level.Group,
    [AppObjectType.Company]: Level.Company,
  } as { [key in AppObjectType]: Level };

  const { data } = useGetAudienceCountQuery({
    appId: Number(appId),
    audienceFilters: {
      filterGroups: audience?.filterGroups || [],
      joinOperator: audience?.joinOperator || 1,
    },
    level: levels[appObject.objectType] as Level,
    token,
    viewInsightId,
  });

  return data?.count;
};
