import { useParams } from "react-router-dom";
import { EventAlertEditorHeading } from "modules/Events/List/EventAlertEditorHeading";
import Header from "core/modules/reports/setup/Setup/Header";
import { AppObjectType } from "core/models/appObjects";
import { AlertButtonStateEnum, useViewAlert } from "core/hooks/useViewAlert";
import { useSlack } from "core/hooks/useSlack";
import { useGroup } from "core/hooks/useGroup";
import { useCompany } from "core/hooks/useCompany";
import { SLACK_REDIRECT_URL_KEY } from "core/constants/integrations";
import {
  DELIVERY_CHANNELS,
  IAlertDeliveryChannelEnum,
  IAlertFrequencyEnum,
  IDeliveryChannelOption,
  IFrequencyOption,
} from "core/constants/alert";
import SlackChannels from "core/components/Slack/SlackChannels";
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Input,
  Select,
  Text,
  VStack,
} from "@chakra-ui/react";

export const FREQUENCIES: IFrequencyOption[] = [
  {
    value: IAlertFrequencyEnum.Daily,
    label: "Daily",
    description: "Once a day, first thing in the morning.",
  },
  {
    value: IAlertFrequencyEnum.Weekly,
    label: "Weekly",
    description: "Every Monday morning",
  },
  {
    value: IAlertFrequencyEnum.Monthly,
    label: "Monthly",
    description: "The first day of every month",
  },
];

interface IViewAlertEditorProps {
  isOpen: boolean;
  onClose: () => void;
  groupId: string;
  appObjectType: AppObjectType;
}

export const ViewAlertEditorDrawer: React.FC<IViewAlertEditorProps> = ({
  isOpen,
  onClose,
  groupId,
  appObjectType,
}) => {
  const { appId } = useParams<{
    appId: string;
  }>() as {
    appId: string;
  };
  const { group } = useGroup({ id: groupId });
  const { company } = useCompany({
    id: groupId,
  });
  const { hasSlackAppInstalled } = useSlack();

  const {
    alert,
    setAlert,
    state,
    isValidAlert,
    onCreate,
    onEnable,
    onUpdate,
    onPause,
    isCreateLoading,
    isUpdateLoading,
  } = useViewAlert({ groupId, appObjectType });

  const deliveryChannel = DELIVERY_CHANNELS.find(
    (c) => c.value === alert.deliveryChannel,
  );
  const frequency = FREQUENCIES.find((f) => f.value === alert.frequency);

  return (
    <Drawer
      trapFocus={false}
      size="xl"
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
    >
      <DrawerOverlay />
      <DrawerContent>
        <Flex
          direction="column"
          justify="space-between"
          h="100%"
          overflowY={"scroll"}
        >
          <Flex direction="column" justify="flex-start">
            <Header
              title={
                <Text>
                  Set up alert for{" "}
                  {appObjectType === AppObjectType.Group
                    ? group?.name
                    : company?.name}
                </Text>
              }
            />
            <Box mx={8} bg="white">
              <Flex w="full" mb={4} justify="flex-end">
                {state === AlertButtonStateEnum.Save && (
                  <Button
                    onClick={onCreate}
                    isLoading={isCreateLoading}
                    isDisabled={!isValidAlert}
                    colorScheme="green"
                  >
                    Save and set live
                  </Button>
                )}
                {state === AlertButtonStateEnum.Enable && (
                  <Button
                    onClick={onEnable}
                    isLoading={isUpdateLoading}
                    isDisabled={!isValidAlert}
                    colorScheme="green"
                  >
                    Set live
                  </Button>
                )}
                {state === AlertButtonStateEnum.Update && (
                  <Button
                    onClick={onUpdate}
                    isLoading={isUpdateLoading}
                    isDisabled={!isValidAlert}
                    colorScheme="green"
                  >
                    Update
                  </Button>
                )}
                {state === AlertButtonStateEnum.Pause && (
                  <Button onClick={onPause} isLoading={isUpdateLoading}>
                    Pause
                  </Button>
                )}
              </Flex>
              <Accordion allowToggle allowMultiple>
                <AccordionItem pb={4}>
                  {({ isExpanded }) => (
                    <>
                      <AccordionButton>
                        <VStack align={"flex-start"} my={0}>
                          <EventAlertEditorHeading
                            title="How to receive the alert"
                            stepNumber={1}
                          />
                          {!isExpanded && (
                            <Box
                              bg={
                                deliveryChannel?.label ? "gray.100" : "red.50"
                              }
                              color={
                                deliveryChannel?.label ? "gray.900" : "red.500"
                              }
                              fontSize="sm"
                              fontWeight="medium"
                              px={3}
                              py={1}
                              borderRadius={4}
                            >
                              {deliveryChannel?.label ||
                                "Select a delivery channel"}
                            </Box>
                          )}
                        </VStack>
                      </AccordionButton>
                      <AccordionPanel>
                        <Flex direction="column" pb={3}>
                          <Text mb={0} fontWeight="medium" fontSize="sm">
                            Delivery channel
                          </Text>
                          <Text mb={2} fontSize="sm" color="gray.600">
                            The method you wish to receive the event alert with
                          </Text>
                          <Select
                            size="sm"
                            width="200px"
                            value={deliveryChannel?.value}
                            placeholder="Select a delivery channel"
                            onChange={(e) => {
                              const newValue = Number(
                                e.target.value,
                              ) as IAlertDeliveryChannelEnum;
                              setAlert({
                                ...alert,
                                deliveryChannel: newValue,
                              });
                            }}
                          >
                            {DELIVERY_CHANNELS.map(
                              (
                                option: IDeliveryChannelOption,
                                index: number,
                              ) => (
                                <option
                                  key={index}
                                  value={option.value}
                                  disabled={
                                    option.value ===
                                    IAlertDeliveryChannelEnum.Slack
                                  }
                                >
                                  {option.label}
                                </option>
                              ),
                            )}
                          </Select>
                        </Flex>
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
                <AccordionItem pb={4}>
                  {({ isExpanded }) => (
                    <>
                      <AccordionButton>
                        <VStack align={"flex-start"} my={0}>
                          <EventAlertEditorHeading
                            title="Where to receive the alert"
                            stepNumber={2}
                          />
                          {!isExpanded && (
                            <>
                              {typeof deliveryChannel?.value === "undefined" ? (
                                <Box
                                  bg="red.50"
                                  color="red.500"
                                  fontSize="sm"
                                  fontWeight="medium"
                                  px={3}
                                  py={1}
                                  borderRadius={4}
                                >
                                  Select a delivery channel
                                </Box>
                              ) : (
                                <>
                                  {deliveryChannel?.value ===
                                    IAlertDeliveryChannelEnum.Email && (
                                    <Box
                                      bg={
                                        alert?.setup?.emails
                                          ? "gray.100"
                                          : "red.50"
                                      }
                                      color={
                                        alert?.setup?.emails
                                          ? "gray.900"
                                          : "red.500"
                                      }
                                      fontSize="sm"
                                      fontWeight="medium"
                                      px={3}
                                      py={1}
                                      borderRadius={4}
                                    >
                                      {alert?.setup?.emails ||
                                        "Enter email addresses"}
                                    </Box>
                                  )}
                                  {deliveryChannel?.value ===
                                    IAlertDeliveryChannelEnum.Slack && (
                                    <Box
                                      bg={
                                        alert?.slackChannelId &&
                                        hasSlackAppInstalled
                                          ? "gray.100"
                                          : "red.50"
                                      }
                                      color={
                                        alert?.slackChannelId &&
                                        hasSlackAppInstalled
                                          ? "gray.900"
                                          : "red.500"
                                      }
                                      fontSize="sm"
                                      fontWeight="medium"
                                      px={3}
                                      py={1}
                                      borderRadius={4}
                                    >
                                      {!hasSlackAppInstalled &&
                                        "Connect your Slack account"}
                                      {hasSlackAppInstalled &&
                                        alert?.slackChannelId &&
                                        "Channel selected"}
                                      {hasSlackAppInstalled &&
                                        !alert?.slackChannelId &&
                                        "Select a channel"}
                                    </Box>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </VStack>
                      </AccordionButton>
                      <AccordionPanel>
                        <Flex direction="column" pb={3}>
                          {typeof deliveryChannel?.value === "undefined" ? (
                            <Box
                              px={3}
                              py={1}
                              w="192px"
                              bg="red.50"
                              color="red.500"
                              fontSize="sm"
                              fontWeight="medium"
                              borderRadius={4}
                            >
                              Select a delivery channel
                            </Box>
                          ) : (
                            <>
                              {deliveryChannel?.value ===
                                IAlertDeliveryChannelEnum.Email && (
                                <>
                                  <Text
                                    mb={0}
                                    fontWeight="medium"
                                    fontSize="sm"
                                  >
                                    Email addresses
                                  </Text>
                                  <Text mb={2} fontSize="sm" color="gray.600">
                                    Enter comma-separated email addresses to
                                    receive the alerts
                                  </Text>
                                  <Input
                                    size="sm"
                                    width="400px"
                                    placeholder="e.g. user@example.com, another@example.com"
                                    value={alert?.setup?.emails}
                                    onChange={(e) =>
                                      setAlert({
                                        ...alert,
                                        setup: {
                                          ...alert?.setup,
                                          emails: e.target.value,
                                        },
                                      })
                                    }
                                  />
                                </>
                              )}
                              {deliveryChannel?.value ===
                                IAlertDeliveryChannelEnum.Slack && (
                                <>
                                  <Text
                                    mb={0}
                                    fontWeight="medium"
                                    fontSize="sm"
                                  >
                                    Slack channel
                                  </Text>
                                  <Text mb={2} fontSize="sm" color="gray.600">
                                    The channel you wish to receive the event
                                    alert in
                                  </Text>
                                  {!hasSlackAppInstalled ? (
                                    <Box>
                                      <Button
                                        colorScheme="purple"
                                        variant="ghost"
                                        onClick={() => {
                                          localStorage.setItem(
                                            SLACK_REDIRECT_URL_KEY,
                                            `/a/${appId}/group/${groupId}?showSlack=true`,
                                          );
                                          window.location.replace(
                                            `${import.meta.env.VITE_API_HOST}/auth/slack`,
                                          );
                                        }}
                                      >
                                        Connect Slack to select a channel
                                      </Button>
                                    </Box>
                                  ) : (
                                    <SlackChannels
                                      onChange={(channelId) =>
                                        setAlert({
                                          ...alert,
                                          slackChannelId: channelId,
                                        })
                                      }
                                      defaultValue={alert?.slackChannelId || ""}
                                    />
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </Flex>
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
                <AccordionItem pb={4}>
                  {({ isExpanded }) => (
                    <>
                      <AccordionButton>
                        <VStack align={"flex-start"} my={0}>
                          <EventAlertEditorHeading
                            title="When to receive the alert"
                            stepNumber={3}
                          />
                          {!isExpanded && (
                            <Box
                              bg={frequency?.label ? "gray.100" : "red.50"}
                              color={frequency?.label ? "gray.900" : "red.500"}
                              fontSize="sm"
                              fontWeight="medium"
                              px={3}
                              py={1}
                              borderRadius={4}
                            >
                              {frequency?.label || "Select a frequency"}
                            </Box>
                          )}
                        </VStack>
                      </AccordionButton>
                      <AccordionPanel>
                        <Flex direction="column" py={3}>
                          <Text mb={0} fontWeight="medium" fontSize="sm">
                            Frequency
                          </Text>
                          <Text mb={2} fontSize="sm" color="gray.600">
                            How frequently you wish to receive the event
                          </Text>
                          <Select
                            size="sm"
                            width="md"
                            borderRadius={"lg"}
                            value={frequency?.value}
                            placeholder="Select a frequency"
                            onChange={(e) => {
                              const newValue = Number(
                                e.target.value,
                              ) as IAlertFrequencyEnum;
                              setAlert({
                                ...alert,
                                frequency: newValue,
                              });
                            }}
                          >
                            {FREQUENCIES.map((f, i) => (
                              <option key={i} value={f.value}>
                                {f.label} - {f.description}
                              </option>
                            ))}
                          </Select>
                        </Flex>
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
              </Accordion>
              <Box h={24} />
            </Box>
          </Flex>
        </Flex>
      </DrawerContent>
    </Drawer>
  );
};
