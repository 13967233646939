import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { ITemplateConfig } from "core/types/TemplateConfig";
import {
  IViewInsight,
  useUpdateViewInsightMutation,
} from "core/models/viewInsights";
import { getReportConfig } from "core/helpers/getReportConfig";
import colors, {
  IColorPalette,
} from "core/design-system/constants/theme/colors";
import {
  ArrowTrendingUpIcon,
  ChartBarIcon,
  CursorArrowRaysIcon,
  PresentationChartLineIcon,
  ShareIcon,
  Squares2X2Icon,
  StarIcon,
  TableCellsIcon,
  UserGroupIcon,
  UsersIcon,
} from "@heroicons/react/16/solid";
import { useReportSetup } from "./useReportSetup";
import {
  GraphType,
  Measure,
  Size,
  Summary,
  Interval,
  State,
  Aggregation,
} from "core/types/ViewInsight";
import { useAppObjects } from "./useAppObjects";
import { AppObjectType } from "../models/appObjects";

export const GraphTypeToIcon = {
  [GraphType.Bar]: <ChartBarIcon className="h-4 w-4" />,
  [GraphType.Area]: <PresentationChartLineIcon className="h-4 w-4" />,
  [GraphType.Line]: <ArrowTrendingUpIcon className="h-4 w-4" />,
  [GraphType.Table]: <TableCellsIcon className="h-4 w-4" />,
  [GraphType.Scatter]: <ShareIcon className="h-4 w-4" />,
  [GraphType.StackedLine]: <ArrowTrendingUpIcon className="h-4 w-4" />,
  [GraphType.StackedBar]: <ChartBarIcon className="h-4 w-4" />,
  [GraphType.CohortGraph]: <Squares2X2Icon className="h-4 w-4" />,
  [GraphType.ContributionGraph]: <UserGroupIcon className="h-4 w-4" />,
  [GraphType.Trend]: <ArrowTrendingUpIcon className="h-4 w-4" />,
  [GraphType.Ratio]: <p className="ml-0.5 mr-0.5 font-bold">%</p>,
};

export const MeasureToIcon = {
  [Measure.ActiveUsers]: <UsersIcon className="h-4 w-4" />,
  [Measure.NewUsers]: <UsersIcon className="h-4 w-4" />,
  [Measure.Features]: <CursorArrowRaysIcon className="h-4 w-4" />,
  [Measure.Retention]: <ArrowTrendingUpIcon className="h-4 w-4" />,
  [Measure.Funnel]: <ChartBarIcon className="h-4 w-4 scale-x-[-1]" />,
  [Measure.Power]: <StarIcon className="h-4 w-4" />,
};

export const InsightTypeConfigs = {
  [Measure.ActiveUsers]: {
    sizes: [Size.Small, Size.Medium, Size.Large],
    graphTypes: {
      [GraphType.Area]: {
        intervals: [Interval.Daily, Interval.Weekly, Interval.Monthly],
      },
      [GraphType.Bar]: {
        intervals: [Interval.Daily, Interval.Weekly, Interval.Monthly],
      },
      [GraphType.Table]: {
        intervals: [],
      },
      [GraphType.ContributionGraph]: {
        intervals: [],
      },
      [GraphType.Trend]: {
        intervals: [Interval.Daily, Interval.Weekly, Interval.Monthly],
      },
      [GraphType.Ratio]: {
        intervals: [Interval.Daily, Interval.Weekly, Interval.Monthly],
      },
    },
    summaries: [Summary.Average],
  },
  [Measure.NewUsers]: {
    sizes: [Size.Small, Size.Medium, Size.Large],
    graphTypes: {
      [GraphType.Area]: {
        intervals: [Interval.Daily, Interval.Weekly, Interval.Monthly],
      },
      [GraphType.Bar]: {
        intervals: [Interval.Daily, Interval.Weekly, Interval.Monthly],
      },
      [GraphType.Trend]: {
        intervals: [Interval.Daily, Interval.Weekly, Interval.Monthly],
      },
    },
    summaries: [Summary.Total],
  },
  [Measure.Features]: {
    sizes: [Size.Small, Size.Medium, Size.Large],
    graphTypes: {
      [GraphType.Scatter]: {
        intervals: [],
      },
      [GraphType.StackedLine]: {
        intervals: [Interval.Daily, Interval.Weekly, Interval.Monthly],
      },
      [GraphType.StackedBar]: {
        intervals: [Interval.Daily, Interval.Weekly, Interval.Monthly],
      },
      [GraphType.Table]: {
        intervals: [],
      },
    },
    summaries: [Summary.Total, Summary.Average],
  },
  [Measure.Retention]: {
    sizes: [Size.Small, Size.Medium, Size.Large],
    graphTypes: {
      [GraphType.CohortGraph]: {
        intervals: [],
      },
      [GraphType.Area]: {
        intervals: [],
      },
    },
    summaries: [],
  },
  [Measure.Funnel]: {
    sizes: [Size.Small, Size.Medium, Size.Large],
    graphTypes: {
      [GraphType.Bar]: {
        intervals: [],
      },
      [GraphType.Area]: {
        intervals: [],
      },
    },
    summaries: [Summary.Total],
  },
  [Measure.Power]: {
    sizes: [Size.Small, Size.Medium, Size.Large],
    graphTypes: {
      [GraphType.Bar]: {
        intervals: [Interval.Weekly, Interval.Monthly],
      },
      [GraphType.Table]: {
        intervals: [],
      },
    },
    summaries: [],
  },
} as {
  [key in Measure]: {
    sizes: Size[];
    graphTypes: Partial<{
      [key in GraphType]: {
        intervals: Interval[];
      };
    }>;
    summaries: Summary[];
  };
};

export const COLOR_PALETTES = [
  colors.purple,
  colors.red,
  colors.green,
  colors.blue,
  colors.yellow,
  colors.orange,
  colors.teal,
  colors.pink,
];

export const COLOR_NAME_TO_PALETTE = {
  purple: colors.purple,
  red: colors.red,
  green: colors.green,
  blue: colors.blue,
  yellow: colors.yellow,
  orange: colors.orange,
  teal: colors.teal,
  pink: colors.pink,
} as { [key in string]: IColorPalette };

export const GRID_WIDTH = {
  [Size.Small]: 1,
  [Size.Medium]: 2,
  [Size.Large]: 3,
};

export const GRID_HEIGHT = {
  [Size.Small]: 1,
  [Size.Medium]: 2,
  [Size.Large]: 3,
};

export const GRID_WIDTH_TO_SIZE = [null, Size.Small, Size.Medium, Size.Large];

export const COLOR_NAMES = [
  "purple",
  "red",
  "green",
  "blue",
  "yellow",
  "orange",
  "teal",
  "pink",
];

function getGraphType(viewInsight: IViewInsight) {
  if (
    !Object.keys(InsightTypeConfigs[viewInsight.measure].graphTypes).includes(
      viewInsight.graphType,
    )
  ) {
    return Object.keys(
      InsightTypeConfigs[viewInsight.measure].graphTypes,
    )[0] as GraphType;
  }
  if (viewInsight.graphType) return viewInsight.graphType;
  if (viewInsight.measure)
    return Object.keys(
      InsightTypeConfigs[viewInsight.measure].graphTypes,
    )[0] as GraphType;

  return GraphType.Area;
}

export interface IUpdateInsightPayload {
  title?: string;
  graphType?: GraphType;
  colorPalette?: IColorPalette;
  interval?: Interval;
  measure?: Measure;
  size?: Size;
  gridHeight?: number;
  useViewAudience?: boolean;
  objectType?: AppObjectType;
  audienceId?: number;
  state?: State;
  aggregation?: Aggregation;
}

export const useEditViewInsight = ({
  viewInsight,
  loadReport,
}: {
  viewInsight: IViewInsight;
  loadReport: boolean;
}) => {
  const { appId } = useParams();
  const config = getReportConfig(
    viewInsight?.insight?.reportType,
  ) as ITemplateConfig;

  const initialMeasure = viewInsight.measure;
  const initialGraphType = getGraphType(viewInsight);
  const initialSize = GRID_WIDTH_TO_SIZE[viewInsight.gridWidth] || Size.Small;
  const initialTitle =
    viewInsight.title || viewInsight?.report?.name || "Untitled";
  const initialInterval = viewInsight.interval || Interval.Daily;
  const initialColorPalette =
    COLOR_NAME_TO_PALETTE[viewInsight.color] || colors.purple;
  const initialUseViewAudience = viewInsight.useViewAudience;
  const initialAppObject = viewInsight.appObject;
  const { appObject } = useAppObjects(initialAppObject?.objectType);

  const [updateViewInsight, { isLoading: isUpdatingViewInsight }] =
    useUpdateViewInsightMutation();
  const { currentReport, loadReportSetup, updateReportSetup } =
    useReportSetup();

  useEffect(() => {
    if (loadReport) {
      loadReportSetup({
        report: viewInsight?.report,
        setup: config?.setup,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewInsight, loadReport]);

  async function onUpdateInsight({
    title,
    graphType,
    colorPalette,
    interval,
    measure,
    size,
    useViewAudience,
    objectType,
    audienceId,
    gridHeight,
    state,
    aggregation,
  }: IUpdateInsightPayload) {
    await updateViewInsight({
      id: viewInsight.id,
      appId: Number(appId),
      title: title || initialTitle,
      graphType: graphType || initialGraphType,
      color: colorPalette
        ? COLOR_NAMES[COLOR_PALETTES.indexOf(colorPalette)]
        : undefined,
      interval: interval || initialInterval,
      measure: measure || initialMeasure,
      gridWidth: size ? GRID_WIDTH[size] : GRID_WIDTH[initialSize],
      gridHeight: !viewInsight.audience
        ? GRID_HEIGHT[Size.Small]
        : gridHeight !== undefined
          ? gridHeight
          : size
            ? GRID_HEIGHT[size]
            : GRID_HEIGHT[initialSize],
      objectType: objectType || appObject?.objectType,
      useViewAudience:
        useViewAudience !== undefined
          ? useViewAudience
          : initialUseViewAudience,
      audienceId:
        audienceId !== undefined ? audienceId : viewInsight.audience?.id,
      state:
        state || viewInsight.persisted ? State.Persisted : State.Unpersisted,
      aggregation: aggregation ?? viewInsight.aggregation,
    });
  }

  async function onCreate() {
    await updateViewInsight({
      id: viewInsight.id,
      appId: Number(appId),
      title: initialTitle,
      graphType: initialGraphType,
      color: COLOR_NAMES[COLOR_PALETTES.indexOf(initialColorPalette)],
      interval: initialInterval,
      measure: initialMeasure,
      gridWidth: GRID_WIDTH[initialSize || Size.Small],
      gridHeight: GRID_HEIGHT[Size.Small],
      objectType: initialAppObject?.objectType,
      state: State.Persisted,
      useViewAudience: initialUseViewAudience,
    });
  }

  function onUpdateReport() {
    updateReportSetup({ setup: config.setup });
  }

  return {
    report: currentReport,
    templateConfig: config,
    measure: initialMeasure,
    graphType: initialGraphType,
    size: initialSize,
    title: initialTitle,
    interval: initialInterval,
    colorPalette: initialColorPalette,
    colors: COLOR_PALETTES,
    appObject,
    useViewAudience: initialUseViewAudience,
    onCreate,
    onUpdateReport,
    onUpdateInsight,
    isUpdatingViewInsight,
  };
};
