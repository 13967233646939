import { CheckIcon } from "@heroicons/react/20/solid";
import { Card, CardBody, CardHeader } from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import ghLogo from "./github-logo.svg";

export const OpenTickets = () => {
  return (
    <Card
      shadow="none"
      border="1px solid"
      borderColor="gray.200"
      h="full"
      p="0"
    >
      <CardHeader pt={4} pb={2} fontWeight="semibold">
        <div className="flex w-full items-center justify-between">
          <p>Open tickets</p>
        </div>
      </CardHeader>
      <CardBody pt={2} pb={4}>
        <div className="flex items-center">
          <div className="flex h-8 w-8 items-center justify-center rounded-md border border-gray-200">
            <img src={ghLogo} alt="avatar" className="size-6" />
          </div>
          <div className="flex flex-col gap-1 py-2 pl-2">
            <div className="flex flex-col items-start gap-0.5">
              <p className="text-xs text-gray-500">Opened Today</p>
              <p className="line-clamp-1 text-sm font-medium">
                Gateway timeouts on ingestion
              </p>
            </div>
          </div>
          <div className="flex items-center justify-center rounded-full bg-gray-100 p-1">
            <ExternalLinkIcon color="gray.600" h={3} w={3} />
          </div>
        </div>
        <div className="flex items-center">
          <div className="flex h-8 w-8 items-center justify-center rounded-md border border-gray-200">
            <img src={ghLogo} alt="avatar" className="size-6" />
          </div>
          <div className="flex w-full flex-col gap-1 py-2 pl-2">
            <div className="flex flex-col items-start gap-0.5">
              <p className="text-xs text-gray-500">Opened 3 days ago</p>
              <p className="line-clamp-1 text-sm font-medium">
                Dashboard is not loading
              </p>
            </div>
          </div>
          <div className="flex items-center justify-center rounded-full bg-gray-100 p-1">
            <ExternalLinkIcon color="gray.600" h={3} w={3} />
          </div>
        </div>
        <div className="flex items-center">
          <div className="relative flex h-8 w-8 items-center justify-center rounded-md border border-gray-200">
            <img src={ghLogo} alt="avatar" className="size-6" />
            <div className="absolute -bottom-1 -right-1 size-3 rounded-full bg-green-500">
              <CheckIcon className="size-3 text-white" />
            </div>
          </div>
          <div className="flex flex-col gap-1 py-2 pl-2">
            <div className="flex flex-col items-start gap-0.5">
              <p className="text-xs text-gray-500">Closed 2 days ago</p>
              <p className="line-clamp-1 text-sm font-medium">
                Embed API does not return insight URL
              </p>
            </div>
          </div>
          <div className="flex items-center justify-center rounded-full bg-gray-100 p-1">
            <ExternalLinkIcon color="gray.600" h={3} w={3} />
          </div>
        </div>
      </CardBody>
    </Card>
  );
};
