import humps from "humps";
import { IPagy } from "core/types/Pagy";
import { baseQuery } from "core/initializers/rtk";
import { createApi } from "@reduxjs/toolkit/query/react";

import type { SnakeCasedPropertiesDeep } from "type-fest";

export enum AudienceStatus {
  Entered,
  Exited,
  Both,
}

export interface IAudienceLog {
  entityId: string;
  status: AudienceStatus;
  createdAt: string;
}

interface IGetAudienceLogsArgs {
  appId: number;
  audienceId: number;
  appObjectId: number;
  page: number;
}

export type IGetAudienceLogsResponse = {
  audienceLogs: IAudienceLog[];
  pagy: IPagy;
};

interface IGetAudienceChangesArgs {
  appId: number;
  audienceId: number;
  appObjectId: number;
}

export type IAudienceChanges = {
  entered: number;
  exited: number;
};

export const audienceLogsApi = createApi({
  baseQuery,
  reducerPath: "audienceLogsApi",
  tagTypes: ["AudienceLog", "AudienceChanges"],
  endpoints: (builder) => ({
    getAudienceLogs: builder.query<
      IGetAudienceLogsResponse,
      IGetAudienceLogsArgs
    >({
      query: ({ appId, audienceId, appObjectId, page }) => ({
        url: `/audiences/${audienceId}/audience_logs`,
        params: {
          app_id: appId,
          audience_id: audienceId,
          app_object_id: appObjectId,
          page,
        },
      }),
      providesTags: ["AudienceLog"],
      transformResponse: (
        response: SnakeCasedPropertiesDeep<IAudienceLog>,
      ): IGetAudienceLogsResponse =>
        humps.camelizeKeys(response) as IGetAudienceLogsResponse,
    }),
    getAudienceChanges: builder.query<
      IAudienceChanges,
      IGetAudienceChangesArgs
    >({
      query: ({ appId, audienceId, appObjectId }) => ({
        url: `/audiences/${audienceId}/audience_logs/changes`,
        params: {
          app_id: appId,
          audience_id: audienceId,
          app_object_id: appObjectId,
        },
      }),
      providesTags: ["AudienceChanges"],
      transformResponse: (
        response: SnakeCasedPropertiesDeep<IAudienceChanges>,
      ): IAudienceChanges => humps.camelizeKeys(response) as IAudienceChanges,
    }),
  }),
});

export const { useGetAudienceLogsQuery, useGetAudienceChangesQuery } =
  audienceLogsApi;
